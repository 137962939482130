<template>
  <simple-modal :verDialog="mostrar" @ocultarDialog="mostrar = false" time="1">
    <p class="mb-0 text-sm-center">
      <v-icon color="info" size="20">mdi-information-outline</v-icon>
      Sesión finalizada
    </p>
  </simple-modal>
</template>

<script>
import SimpleModal from "@/components/SimpleModal";
import {EventBus} from "@/main";

export default {
  name: "DialogSesionFinalizada",
  components: {SimpleModal},
  created() {
    EventBus.$on("dialog-sesion-finalizada", (mostrar) => {
      this.mostrar = mostrar;
    })
  },
  data() {
    return {
      mostrar: false,
    }
  },
}
</script>

<style scoped>

</style>