import {getCaratulaDevolucionFromApi} from "../caratula.repository";

const getCaratulaDevolucion = (numero_caratula) =>{

    return new Promise((resolve, reject)=>{
        getCaratulaDevolucionFromApi(numero_caratula).then(response=>{
            if(response.data.status){
                resolve(response.data.obj)
            }else{
                reject(response.data)
            }
        }).catch(error=>{
            reject(error)
        })
    })


}

export default getCaratulaDevolucion;