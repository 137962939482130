<template>
  <simple-modal-static :verDialog="mostrar" @ocultarDialog="mostrar = false">
    <p class="mb-0 text-sm-center">
      <v-icon color="info" size="20">mdi-information-outline</v-icon>
      Bienvenido/a {{ nombreUsuario }}
    </p>
    <v-row>
      <v-col cols="12" class="text-sm-center">
        Se encontró documentos agregados sin haber iniciado sesión, ¿desea conservarlos o usar los guardados en su
        cuenta?
      </v-col>
      <v-col cols="12" class="text-sm-center">
        <v-btn
            color="red lighten-1"
            text
            @click="conservarCarro(true)"
        >
          Conservar actual
        </v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="conservarCarro(false)"
        >
          Utilizar mi carro guardado
        </v-btn>

      </v-col>
    </v-row>

  </simple-modal-static>
</template>

<script>
import SimpleModalStatic from "@/components/SimpleModalStatic";
import carro from "@/model/carro/carro.entity";
import {EventBus} from "@/main";

export default {
  name: "ModalSeleccionarCarro",
  components: {SimpleModalStatic},
  data() {
    return {
      mostrar: false,
      nombreUsuario: ""
    }
  },
  created() {
    EventBus.$on("modal-seleccionar-carro", (abrir) => {
      this.mostrar = abrir;
    })
  },
  methods: {
    conservarCarro(opt) {
      if (!opt) {
        carro.obtenerCarro()
      } else {
        carro.reemplazarCarro();
      }

      this.mostrar = false;
    },
  }
}
</script>

<style scoped>

</style>
