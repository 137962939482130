<template>
  <v-dialog max-width="550" v-model="ver" v-if="documento != null">
    <v-card class="px-6 pt-5">

      <p class="text-center font-weight-medium info--text">¿Qué desea hacer con este documento?</p>
      <p class="font-weight-medium mb-0">
        {{ documento.tipo_certificado }}{{
          documento.indice.toLowerCase() != "no aplica" ? " | " + documento.indice : ""
        }}</p>
      <p class="mb-0" style="font-size: 11px">{{ documento.tipo_registro }}</p>


      <div>
        <v-radio-group v-model="opcion" hide-details>
          <v-radio

              :label="`Quiero la devolución del dinero`"
              value="0"
          ></v-radio>
          <v-radio

              :label="`Quiero corregir los datos citados`"
              value="1"
          ></v-radio>
        </v-radio-group>

      </div>

      <div v-if="opcion === '1'">
        <hr class="mt-2">
        <p class="text-center font-weight-medium pt-5">Porfavor, ingrese los datos correctos para el documento</p>

        <v-select
            :items="categorias_registros"
            label="Categoría"
            return-object
            outlined
            dense
            item-text="categoria_registro"
            v-model="categoriaSeleccionada"
        ></v-select>
        <v-select
            :items="tipos_registros"
            label="Tipo de registro"
            return-object
            outlined
            dense
            item-text="tipo_registro"
            v-model="tipoRegistroSeleccionado"
            :disabled="!categoriaSeleccionada"
            :error-messages="errorTipoRegistro"
            required
        ></v-select>
        <v-select
            :items="tipos_certificados"
            label="Tipo de certificado"
            return-object
            outlined
            dense
            item-text="tipo_certificado"
            v-model="tipoCertificadoSeleccionado"
            :disabled="!tipoRegistroSeleccionado"
            required
            :error-messages="errortipoCertificado"
        ></v-select>
        <hr>
        <p class="text-center font-weight-medium pt-5">Índice del documento</p>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-text-field
                label="Foja"
                placeholder="Ej: 20"
                outlined
                dense
                :autofocus="!$vuetify.breakpoint.mobile"
                v-model="foja"
                :error-messages="errorFoja"
                @blur="$v.foja.$touch = true"
                @input="delayTouch($v.foja)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-text-field
                label="Número"
                placeholder="Ej: 214"
                outlined
                dense
                v-model="numero"
                :error-messages="errorNumero"
                @blur="$v.numero.$touch = true"
                @input="delayTouch($v.numero)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-text-field
                label="Año"
                placeholder="Ej: 2020"
                outlined
                dense
                v-model="anho"
                :error-messages="errors.errorAnho($v.anho)"
                @blur="$v.anho.$touch = true"
                @input="delayTouch($v.anho)"
            ></v-text-field>
          </v-col>
        </v-row>
        <hr>

        <v-checkbox
            v-model="confirmar"
            class="mt-3 mb-1"
            :label="`Confirmo que los datos ingresados son los correctos`"
            hide-details
        ></v-checkbox>


      </div>
      <v-fade-transition>
        <div v-if="opcion === '0'">
          <p style="font-size: 11px" class="text-center my-2">Esto permitirá que la carátula siga su curso, se generará un saldo a favor y en unos días podrá indicar una cuenta bancaria para recibirlo.</p>
        </div>
      </v-fade-transition>

      <v-row>
        <v-col cols="12" md="4">
          <v-btn block outlined color="info" @click="hideDialog">
            <v-icon left size="20px">mdi-close</v-icon>
            Volver
          </v-btn>
        </v-col>
        <v-col cols="12" md="8">
          <v-btn v-if="opcion !== '1'" :disabled="opcion == null" block color="success" @click="corregirDocumento()">
            <v-icon left size="20px">mdi-check</v-icon>
            Confirmar
          </v-btn>

          <v-btn v-if="opcion === '1'"
                 :disabled="!confirmar || !tipoCertificadoSeleccionado || !tipoRegistroSeleccionado || !categoriaSeleccionada || $v.$invalid"
                 block color="success" @click="corregirDocumento()">
            <v-icon left size="20px">mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>
</template>

<script>
import registros, {ID_AREA_AGRUPACION, ID_TIPO_REGISTRO,} from "@/model/registros/registros.entity";
import caratulaEntity, {ID_ESTADO_CORRECCION} from "@/model/caratula/caratula.entity";
import {between, helpers, required} from "vuelidate/lib/validators";
import validEntity from "@/model/validaciones/valid.entity";

const touchMap = new WeakMap();

const validadorFoja = helpers.regex('validadorFoja', /^([0-9])+(BIS|V|BISV|VBIS)?$/);
const validadorNumero = helpers.regex('validadorNumero', /^([0-9])+(BIS)?$/);

export default {
  name: "ModalDocumento",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    caratula: {default: null},
    documento: {default: null},

  },
  data() {
    return {
      ver: false,
      opcion: null,
      hasIndice: null,
      load: {
        cargandoRegistros: false,
        cargandoCategorias: false
      },
      categorias_registros: [],
      tipos_registros: [],
      tipos_certificados: [],
      categoriaSeleccionada: null,
      tipoRegistroSeleccionado: null,
      tipoCertificadoSeleccionado: null,
      confirmar: false,
      foja: null,
      numero: null,
      anho: null,
      primeraCarga: true,
      errors: validEntity,
    }

  },
  validations: {
    anho: {
      required,
      between: between(1942, new Date().getFullYear())
    },
    foja: {
      required,
      validadorFoja
    },
    numero: {
      required,
      validadorNumero
    }
  },
  created() {
    this.load.cargandoRegistros = true;
    registros.getAllDataRegistros().then((response) => {
      this.categorias_registros = response.filter((reg) => {
        if (reg.id_categoria_registro <= 4) {
          return reg;
        }
      })

      this.categorias_registros.map(cat => {
        cat.tipos_registros = cat.tipos_registros.filter(f => {
          return f.id_tipo_registro !== ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION
        })
        cat.tipos_registros.map(cer => {
          cer.tipos_certificados = cer.tipos_certificados.filter(f => {
            return f.id_area_agrupacion !== ID_AREA_AGRUPACION.PLANOS
          })
        })
      })

    }).finally(() => {
      this.load.cargandoRegistros = false;
    });


  },
  watch: {

    categoriaSeleccionada() {
      if (this.categoriaSeleccionada) {
        this.tipos_registros = this.categoriaSeleccionada.tipos_registros;
        console.log("primera carga cat_reg: ", this.primeraCarga)
        if (!this.primeraCarga) {
          this.tipoRegistroSeleccionado = null;
        }
      }
    },
    tipoRegistroSeleccionado() {
      if (this.tipoRegistroSeleccionado) {
        this.tipos_certificados = this.tipoRegistroSeleccionado.tipos_certificados;
        console.log("primera carga tipo_reg: ", this.primeraCarga)
        if (!this.primeraCarga) {
          this.tipoCertificadoSeleccionado = null;
        }

      }
    },

    verDialog() {
      this.ver = this.verDialog;
      if (this.ver) {
        this.primeraCarga = true;
        if (this.documento) {
          this.precargarDatos().then(() => {
            this.primeraCarga = false;
          })
        }

      }
      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },
  computed: {
    errorTipoRegistro() {
      return !this.tipoRegistroSeleccionado ? ["Debe seleccionar un tipo de registro"] : []
    },
    errortipoCertificado() {
      return !this.tipoCertificadoSeleccionado ? ["Debe seleccionar un tipo de certificado"] : []
    },
    errorFoja() {
      const errors = [];
      if (!this.$v.foja.$dirty) return errors;
      !this.$v.foja.required && errors.push("Ingrese una foja");
      !this.$v.foja.validadorFoja && errors.push("Ingrese una foja válida (Sólo números o números seguido de BIS, V, VBIS o BISV en mayúsculas, sin espacios)");
      return errors;
    },
    errorNumero() {
      const errors = [];
      if (!this.$v.numero.$dirty) return errors;
      !this.$v.numero.required && errors.push("Ingrese un numero");
      !this.$v.numero.validadorNumero && errors.push("Ingrese un número válido (Sólo números o números seguido de BIS en mayúsculas, sin espacios)");
      return errors;
    },

  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 800))
    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    precargarDatos() {
      return new Promise((resolve) => {

        let splitIndice = this.documento.indice.split("-");
        if (splitIndice.length == 3) {
          this.foja = splitIndice[0];
          this.numero = splitIndice[1];
          this.anho = splitIndice[2];
        }
        this.categoriaSeleccionada = this.categorias_registros.find(f => {
          return f.tipos_registros.filter(d => {
            return d.id_tipo_registro === this.documento.id_tipo_registro_FK
          }).length > 0;
        })

        if (this.categoriaSeleccionada) {
          this.tipoRegistroSeleccionado = this.categoriaSeleccionada.tipos_registros.find(f => {
            return f.id_tipo_registro === this.documento.id_tipo_registro_FK
          })

          if (this.tipoRegistroSeleccionado) {
            this.tipoCertificadoSeleccionado = this.tipoRegistroSeleccionado.tipos_certificados.find(f => {
              return f.id_tipo_certificado === this.documento.id_tipo_certificado_FK
            })


          }
        }

        resolve(null)
      })
    },
    corregirDocumento() {
      let nuevoIndice;
      console.log("OPCION: ", this.opcion)
      switch (this.opcion) {
        case "0": // No corregir
          console.log("No corregir el documento")
          caratulaEntity.updateCorreccionCertificado(this.documento, 0, 0, 0, ID_ESTADO_CORRECCION.NO_CORREGIR).then(() => {
            this.$emit("documentoCorregido", ID_ESTADO_CORRECCION.NO_CORREGIR)
          })
          break;
        case "1":
          nuevoIndice = "";
          caratulaEntity.updateCorreccionCertificado(this.documento, this.tipoRegistroSeleccionado.id_tipo_registro, this.tipoCertificadoSeleccionado.id_tipo_certificado, nuevoIndice, ID_ESTADO_CORRECCION.CORREGIDO).then(() => {
            this.$emit("documentoCorregido", ID_ESTADO_CORRECCION.CORREGIDO)
          })
          break;

        default:
          return;
      }
    }
  }
}
</script>

<style scoped>

</style>
