<template>
  <v-fade-transition>
    <div>
      <v-data-table
          :headers="headers"
          :items="datas"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer

      >
        <template v-slot:item.info="{item}">
          <div class="py-1">
            <p class="mb-0 ">{{ item.titular }}</p>
            <p v-if="item.direccion.length > 0" class="mb-0 font-md pl-1 d-flex pb-2" style="font-size: 12px">
              <v-icon class="pr-2" small>mdi-map-marker</v-icon>
              <span>{{ item.direccion }}</span>

              <span class="info--text pointer" v-if="getRutUsuario == null" @click="verMas()">Ver más </span>
            </p>
          </div>

        </template>
        <template v-slot:item.ver="{item}">
          <v-btn
              v-if="item.documento"
              x-small
              class="px-1 py-4"
              color="primary"
              @click="verDocumento(item.foja, item.numero, item.anho, item.documento)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

        </template>
        <template v-slot:item.agregar="{item}">

          <v-btn color="primary" class="px-1 py-4" x-small @click="seleccionarItem(item)">
            <v-icon small>mdi-cart</v-icon>
          </v-btn>
        </template>

      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="20"
        ></v-pagination>
      </div>
    </div>
  </v-fade-transition>

</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  name: "IndicesTable",
  props: {
    datas: {default: []},
    itemsTotales: {default: 0}
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {text: 'Titular', value: 'info', sortable: false},
        {text: 'Foja', value: 'foja', sortable: false},
        {text: 'Numero', value: 'numero', sortable: false},
        {text: 'Año', value: 'anho', sortable: false},
        {text: 'Ver', value: 'ver', sortable: false},
        {text: 'Agregar', value: 'agregar', sortable: false}
      ],

    }
  },
  created() {
    this.pageCount = Math.ceil(this.itemsTotales / this.itemsPerPage)
  },
  watch: {
    itemsTotales() {
      this.pageCount = Math.ceil(this.itemsTotales / this.itemsPerPage)

    },
    page() {
      this.$emit("cambiarPagina", this.page)
    },
    getRutUsuario(){
      this.$emit("cambiarPagina", this.page);
    }

  },
  methods: {

    verMas(){
      EventBus.$emit("modal-login", true);
    },
    verDocumento(foja, numero, anho, idDocumento) {
      usuarioEntity.isSessionActive().then(res => {

        if (res) {
          const serie = `${foja}-${numero}-${anho}`
          window.open(`/visor-documentos/${serie}/${idDocumento}/d`, '_blank');
        } else {
          EventBus.$emit("modal-login", true);
        }
      })

    },
    seleccionarItem(item) {
      this.$emit("seleccionarItem", item)
    }
  },
  computed:{
    getRutUsuario(){
     return usuarioEntity.getRutUsuario()
    }
  }
}
</script>

<style scoped>

</style>