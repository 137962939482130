<template>
  <v-fade-transition mode="out-in">
    <div
        v-if="!cargando"
        key="cargado"
    >
      <div v-if="!error" id="visor">
        <vue-friendly-iframe v-if="!isHidden" :src="path"></vue-friendly-iframe>
      </div>
      <div v-else>
        <v-alert
            class="fill-height mb-0"
            outlined
            type="warning"
            border="left"
        >
          <span v-html="error"></span>
        </v-alert>
      </div>
    </div>
    <div
        class="d-flex fill-height justify-center align-center"
        v-else
        key="cargando"
    >
      <v-progress-circular indeterminate color="primary"/>
    </div>
  </v-fade-transition>
</template>
<script>
//import axios from "axios";
import indiceEntity from "@/model/indices/indice.entity";

export default {
  name: "VisualizadorDocumentos",
  props: {
    idDocumento: {type: String, default: null},
    tipoDocumento: {type: String, default: 'documento'}
  },
  data: function () {
    return {
      publicPath: `${process.env.BASE_URL}`,
      path: `${this.publicPath}lib/web/viewer.html`,
      isHidden: true,
      token: '',
      cargando: false,
      error: null,
    }
  },
  methods: {
    async computeUrl() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      var t = await this.$recaptcha('login')
      return t;
    },
    buscarPdf(idDocumento, tipoDocumento, token) {

      //TGA
      this.$gtag.event('pdf-view', {method: 'search-pdf'});

      this.error = null;
      this.cargando = true;
      indiceEntity.getPDF(idDocumento, tipoDocumento, token).then(resp => {
        const blob = new Blob([resp.data], {
          type: "application/pdf"
        });
        let blobUrl = URL.createObjectURL(blob);

        let viewer = (tipoDocumento === 'p') ? `${this.publicPath}lib/web/viewer_plano.html` : `${this.publicPath}lib/web/viewer.html`;
        let viewerUrl;
        viewerUrl = viewer + "?file=" + encodeURIComponent(blobUrl + "#" + "PRELIMINAR");
        console.log("url:", viewerUrl);


        this.path = viewerUrl;
        this.isHidden = false;
        //setTimeout(function () {
        //  URL.revokeObjectURL(blobUrl)
        //}, 10000);
        
      }).catch((error) => {
        this.error = error.error ? error.error : error
      }).finally(() => {
        this.cargando = false;
      });
    }


  },
  mounted: function () {
    //Calcular url y mostrar iframe
    this.computeUrl().then((token) => {
      this.buscarPdf(this.idDocumento, this.tipoDocumento, token);
    }).catch((error) => {
      console.error(error)
    });


  }

}


</script>

<style scoped>
#visor {
  width: 100%;
  height: 79vh;
  min-width: 400px;
}

</style>
