<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <p class="font-weight-bold text-h6 info--text text-center pt-3">Información Importante</p>

        <p class="text-justify mb-2" style="font-size: 15px"> Las copias de Escrituras Públicas varían su precio según las carillas. Una vez que el trámite esté listo deberá pagar el saldo según corresponda.</p>

        <v-checkbox
            class="text-center pb-3"
            v-model="checkbox"
            small
            style="font-size: 5px"
            label="Entiendo que el precio a pagar es la base del documento"
            hide-details
        ></v-checkbox>
        <v-row class="py-0">

          <v-col cols="12" md="6" class="pb-md-0">
            <v-btn @click="hideDialog" block small outlined color="gray">
              <v-icon small left>mdi-close</v-icon>
              Volver
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" class="pb-md-0">
            <v-btn @click="confirmar" :disabled="!checkbox" block small outlined color="success">
              <v-icon small left>mdi-check</v-icon>
              Agregar al carro
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmaEscrituraPublica",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false,
      checkbox: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.checkbox = false;
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    confirmar(){
      this.$emit("confirmarEscrituraPublica", null);
    }
  }
}
</script>

<style scoped>

</style>