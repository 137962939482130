import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import Vuelidate from 'vuelidate';
import axios from "axios";
import {clpFilter} from "@/filters/divisasFilter";
import {notUrlFilter} from "@/filters/textFilter";

import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import {fullDateFilter} from "@/filters/fullDateFilter";
import {toDateFilter} from "@/filters/toDateFilter";

import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueGtag from "vue-gtag";


//uso de plugins
//plugin de validacion vuelidate
Vue.use(Vuelidate)
Vue.use(VueFriendlyIframe);

//plugin axios
axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/`;
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.withCredentials = true;

//plugin moment
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
    moment
});

Vue.config.productionTip = false

Vue.filter('clp', clpFilter)
Vue.filter('notUrl', notUrlFilter);
Vue.filter('fullDate', fullDateFilter);
Vue.filter("toDate", toDateFilter);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBdVdgS8lwwE7x-TMuvxvTiPfcK4jO5zxg",
    libraries: "places",
  },
})

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
});

Vue.use(VueGtag, {
  config: { id: "G-G4N5JJCNRV" },
}, router);

Vue.use(VueReCaptcha, { siteKey: '6LcNOBgdAAAAAMJ38eK4C0BvitLR1pjoXlL3P5O4' })

export const EventBus = new Vue();

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
   
}).$mount('#app')

//polyfills
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, newStr){

        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }

        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);

    };
}

//eliminar los console log en producción
if(process.env.NODE_ENV == "production")
{
    const methods = ["log", "debug", "warn", "info", "error"];
    for(let i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}