<template>
  <v-row>
    <v-col cols="12" class="pb-0"><h3 class="text-center font-weight-medium">Medios de pago disponibles</h3></v-col>
    <v-col cols="12" class="pt-3" v-if="precio_total_carro == 0">
      <v-alert
          text
          type="info"
          outlined
      >
        <p class="mb-0 text-justify">
          Se generará su cotizacion con un monto total sujeto a evaluación. El precio final de su carátula será
          entregado dentro de los próximos días y podrá ser verificado en la sección "Mis Compras".
        </p>

      </v-alert>
    </v-col>
    <v-col v-if="precio_total_carro > 0" cols="12" class="pt-0" :class="{'mb-3': tipo_pago == null}">
      <v-radio-group
          v-model="tipo_pago"
          row
          class="mt-2"
          style="height: 39px"
      >
        <v-row>
          <v-col cols="6" class="d-flex align-center pb-3 justify-center" @click="tipo_pago = ID_TIPO_PAGO.KHIPU_WEB">
            <div class="d-flex align-center justify-center">
              <v-radio
                  class="mr-1"
                  label=""
                  :value="ID_TIPO_PAGO.KHIPU_WEB"
              >
              </v-radio>
              <img style="height: 32px " :src="require('@/assets/img/logo_khipu.svg')"/>
            </div>

          </v-col>
          <v-col cols="6" class="d-flex align-center justify-center pb-3" @click="tipo_pago = ID_TIPO_PAGO.KHIPU">
            <div class="d-flex align-center justify-center">
              <v-radio
                  class="mr-0"
                  label=""
                  :value="ID_TIPO_PAGO.KHIPU"
              >
              </v-radio>
              <img style="height: 26px " :src="require('@/assets/img/logo_bancoestado.svg')"/>
            </div>

          </v-col>
        </v-row>
      </v-radio-group>
      <v-fade-transition>
        <v-row v-if="tipo_pago != null">
          <v-col cols="12" class=" pb-0 mt-2">
            <v-fade-transition>
              <p class="text-center mb-0" style="font-size: 12px" v-if="tipo_pago === ID_TIPO_PAGO.KHIPU_WEB">
                Realice un pago <b>simplificado</b>, Khipu otorga una conexión segura con su banco desde el
                navegador para concretar su compra.
              </p>
              <p class="text-center mb-0" style="font-size: 12px" v-if="tipo_pago === ID_TIPO_PAGO.KHIPU">
                Realice una transferencia <b>manual</b> desde BancoEstado u otros bancos hacia nuestro recaudador Khipu.
              </p>
            </v-fade-transition>

          </v-col>
        </v-row>
      </v-fade-transition>


    </v-col>
  </v-row>
</template>

<script>
import {ID_TIPO_PAGO} from "@/model/carro/carro.entity";

export default {
  name: "ModalPagoMediosPago",
  props: {
    ayuda: {default: false},
    precio_total_carro: {default: -1}
  },
  data() {
    return {
      tipo_pago: null,
      ID_TIPO_PAGO: ID_TIPO_PAGO,
      verAyuda: false,

    }
  },
  created() {
    this.verAyuda = this.ayuda
    if (this.precio_total_carro == 0) {
      this.tipo_pago = ID_TIPO_PAGO.POR_COTIZAR;
      console.log("intenta setear el tipo pago en por cotizar: ", this.tipo_pago)

      this.$emit("updateTipoPago", this.tipo_pago)
    }
    // this.tipo_pago = ID_TIPO_PAGO.KHIPU_WEB
  },
  watch: {
    precio_total_carro() {

      if (this.precio_total_carro == 0) {
        this.tipo_pago = ID_TIPO_PAGO.POR_COTIZAR;
        this.$emit("updateTipoPago", this.tipo_pago)
      }
    },

    tipo_pago() {
      this.$emit("updateTipoPago", this.tipo_pago)
    }
  }
}
</script>

<style scoped>

</style>
