import {getEstadoPagoCarroApi} from "@/model/carro/carro.repository";

const getEstadoPagoCarro = (id_carro) =>{
    return new Promise((resolve, reject) => {
        getEstadoPagoCarroApi(id_carro).then(resp=>{
            if(resp.status){
                resolve(resp)
            }else{
                reject(resp)
            }

        }).catch(error=>{
            reject(error)
        })
    })
}

export default getEstadoPagoCarro;