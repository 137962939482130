import qs from "qs";
import axios from "axios";


export const getEstadoCaratulaFromApi = (numeroCaratula) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/caratulas?action=consulta-caratula-generada",
            qs.stringify({numero_caratula: numeroCaratula})
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export const getCaratulaByRutAPI = (numeroCaratula, rutCliente) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/caratulas?action=read-caratula-entrega-en-linea",
            qs.stringify({
                rut_cliente: rutCliente,
                numero_caratula: numeroCaratula
            })
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export const getDocumentosByCaratulaAPI = (numeroCaratula) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/caratulas?action=read-documentos-caratula",
            qs.stringify({
                numero_caratula: numeroCaratula
            })
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });


}

export const getMisCaratulasApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/caratulas?action=read-caratulas",
            qs.stringify({})
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getDocumentosAnexosCaratulaApi = (numero_caratula) => {
    return new Promise((resolve, reject) => {
        axios.post("/caratulas?action=read-documentos-caratula",
            qs.stringify({numero_caratula: numero_caratula})).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getLinkPagoSaldoFromApi = (numero_caratula, id_tipo_pago) => {
    return new Promise((resolve, reject) => {
        axios.post("/caratulas/v2?action=obtener-link-pago-saldo",
            qs.stringify({numero_caratula: numero_caratula, id_tipo_pago: id_tipo_pago})).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getCaratulaDevolucionFromApi = (numero_caratula) => {
    return new Promise((resolve, reject) => {
        axios.post("caratulas?action=read-caratula-solicitud",
            qs.stringify({numero_caratula: numero_caratula})).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const createSolicitudDevolucionApi = (dataSolicitud) => {
    return new Promise((resolve, reject) => {
        axios.post("caratulas?action=create-solicitud-devolucion",
            qs.stringify({solicitud_devolucion: JSON.stringify(dataSolicitud)})).then(resp => {
            resolve(resp);
        }).catch(error => {
            reject(error)
        })
    })
}

export const getEstadoPagoSaldoCaratulaFromApi = (numero_caratula) => {
    return new Promise((resolve, reject) => {
        axios.post("solicitudesclientes?action=estado-pago-saldo",
            qs.stringify({
                caratula: numero_caratula
            })).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error)
        })
    })
}

export const getCaratulasConDocumentosPorCorregirApi = (count) => {
    return new Promise((resolve, reject) => {
        axios.post("caratulas/v2?action=obtener-caratulas-documentos-por-corregir",
            qs.stringify({count: count})).then(resp => {
            resolve(resp.data.obj);
        }).catch(error => {
            reject(error)
        })
    })
}

export const updateCorreccionCertificadoApi = ( documento, nuevo_tipo_registro, nuevo_tipo_certificado, nuevo_indice, id_accion) => {
    return new Promise((resolve, reject) => {
        axios.post("solicitudesclientes/v2?action=update-correccion-certificado",
            qs.stringify({
                documento: JSON.stringify(documento),
                id_tipo_registro: nuevo_tipo_registro,
                id_tipo_certificado: nuevo_tipo_certificado,
                indice: nuevo_indice,
                id_accion: id_accion
            })).then(resp => {
            resolve(resp.data.obj);
        }).catch(error => {
            reject(error)
        })
    })
}
