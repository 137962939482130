<template>
  <v-card height="100%">
    <v-card-text class="d-flex flex-column justify-space-between" style="height: 100%">
      <div style="height: 100%"
           class="d-flex flex-column flex-sm-row  align-sm-start align-items">
        <div class="flex-grow-1 mb-4 mr-0 mr-sm-4" style="height: 100%">
          <div class="d-flex  flex-wrap" style="height: 100%">
            <div class="align-self-start">
              <v-card-title
                  class="font-weight-regular pt-0 px-0 px-sm-4 pb-0"
              >
                {{ certificado.tipo_certificado }}
              </v-card-title>
            </div>
            <div class="align-self-start">
              <p
                  class="px-0 px-sm-4 pb-0"
                  style="text-align: justify"
              >
                {{ certificado.informacionCertificado.resumen }}
              </p>
            </div>


            <div class="px-0 px-sm-4 mb-2 mt-auto flex-fill">
              <div>
                <v-icon size="24" color="info" left>mdi-clock-fast</v-icon>
                <span>Entrega aprox:</span>
                <span class="blue--text">
              {{ certificado.dias_entrega }}
              <span>día</span>{{
                    certificado.dias_entrega > 1 ? "s" : ""
                  }}<span> hábil</span>{{ certificado.dias_entrega > 1 ? "es" : "" }}
              </span>
              </div>

              <div class="d-flex align-center">
                <v-icon size="24" color="success" left>mdi-cash</v-icon>
                <span class="pr-1">Valor:</span>
                <span class="blue--text">

                  <span v-if="!registro.isPrecioVariable(certificado.id_area_agrupacion)">{{ certificado.precio | clp }}</span>
                  <span v-if="registro.isPrecioVariable(certificado.id_area_agrupacion)">Variable</span>
              </span>
              </div>
              <div>
                <v-btn
                    class="mt-4"
                    small
                    :to="{ name: 'TipoCertificado', params: {categoriaRegistro:url_categoria, tipoRegistro: url_tipo_registro, tipoCertificado: certificado.url_tipo_certificado} }"
                    :block="!$vuetify.breakpoint.smAndUp"
                    color="primary"
                >
                  <v-icon size="16" class="mr-1">mdi-cart</v-icon>
                  Ir al trámite
                </v-btn>
              </div>
            </div>
          </div>

        </div>
        <div class="d-sm-flex flex-column align-center d-none d-sm-flex">

          <v-img
              contain
              max-width="160"
              :lazy-src="require('@/assets/img/certificados/cert-sample_thumbnail.jpg')"
              :src="getImagenCertificado(certificado.id_tipo_registro_FK,certificado.id_tipo_certificado,true)"
              class="imagen-registro"
          />
          <span class="caption referencial">Imagen referencial</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import registro, {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";
import {ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";

export default {
  name: "CardTipoRegistro",
  props: ["CertificadoProp", "TipoRegistro"],
  data() {
    return {
      url_categoria: this.$route.params.categoriaRegistro,
      url_tipo_registro: this.TipoRegistro.url_tipo_registro,
      nombre_tipo_registro: this.TipoRegistro.tipo_registro,
      registro: registro,
      certificado: {tipo_certificado: "Sin certificado"},
      ID_TIPO_CERTIFICADO: ID_TIPO_CERTIFICADO,
      ID_AREA_AGRUPACION: ID_AREA_AGRUPACION,

    };
  },
  methods: {
    getImagenCertificado: registro.getImagenCertificado
  },

  created() {
    if (this.CertificadoProp != null) {
      this.certificado = this.CertificadoProp;
    }
  },
};
</script>

<style scoped>
.v-card__title, .v-card__subtitle {
  color: rgb(54 54 54);
}

.v-card__title {
  word-break: break-word;
}

.imagen-registro {
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 1px;
}

.referencial {
  font-size: 0.55rem !important;
}

</style>
