<template>
  <v-card
      elevation="0"
      tile
      color="#9ba2fb1f"
  >
    <v-card-text>
      <v-row class="pa-0">
        <v-col cols="12" class="pt-0 pb-1">
          <h3 class="mb-2 font-weight-regular text-center" style="color:rgb(33, 150, 243)" align="">
            {{ certificado.tipo_certificado }}
          </h3>

        </v-col>
        <v-col cols="12" align="center" class="pt-0 pb-1" >
          <v-img
              :lazy-src="require('@/assets/img/certificados/cert-sample_full.jpg')"
              :src="getImagenCertificado(certificado.id_tipo_registro_FK,certificado.id_tipo_certificado,false)"
              class="imagen-registro"

          ></v-img>
          <p style="font-size: 11px" class="text-center my-1">Imágen referencial</p>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-divider></v-divider>
          <p class="text-center pt-3 mb-1 font-weight-medium">Descripción del documento</p>
          <p class="detalle-text my-2">
            {{ certificado.informacionCertificado.descripcion }}
          </p>

        </v-col>
        <v-col cols="12" class="pt-0" style="font-size: 14px">
          <v-divider></v-divider>
          <p class="mb-2 mt-3 font-weight-medium text-center">Tiempo de realización aproximado</p>
          <p class="text-center mb-1 py-1 font-weight-light" style="background-color: #20749c94;font-size: 17px; color:white; ">{{ certificado.dias_entrega }}
            <span v-if="certificado.dias_entrega > 1">días hábiles</span>
            <span v-if="certificado.dias_entrega == 1">día hábil</span>
          </p>
          <p class="text-center mb-1" style="font-size: 12px">
            Se puede extender en caso de que sea necesario realizar algún estudio o búsqueda
              adicional.

          </p>

        </v-col>

        <v-col cols="12" class="pt-0">

          <v-expansion-panels accordion flat>
            <v-expansion-panel
                v-for="(dato, index) in certificado.informacionCertificado.otras_descripciones"
                :key="dato.clave"
                style="background-color:transparent; z-index: 0"
            >
              <v-expansion-panel-header class="detalle-collapse-header px-1">{{ dato.clave }}</v-expansion-panel-header>
              <v-expansion-panel-content style="font-size: 12px">
                <p class="mb-0" style="text-align: justify;">{{ dato.valor }}</p>
              </v-expansion-panel-content>
              <v-divider v-if="index != certificado.informacionCertificado.otras_descripciones.length -1"></v-divider>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script>
import registro from "@/model/registros/registros.entity";

export default {
  name: "informacionDocumento",
  props: {
    certificado: {
      default: {}
    }
  },
  methods: {
    getImagenCertificado: registro.getImagenCertificado
  }
}
</script>

<style scoped>
.detalle-text {
  font-size: 14px;
  line-height: 23px;
  text-align: justify;
}

.detalle-collapse-header {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 0;
  font-size: 14px;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 0;
}

.imagen-registro {
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 1px;
}
</style>