<template>
  <v-autocomplete
      v-model="certificadoSeleccionado"
      :items="listaCertificados"
      :loading="cargandoCertificados ? 'secondary' : false"
      :search-input.sync="busquedaCertificado"
      hide-no-data
      placeholder="Busque un certificado rápidamente"
      :autofocus="!$vuetify.breakpoint.mobile"
  >
    <template v-slot:item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title>{{data.item.text}}</v-list-item-title>
          <v-list-item-subtitle><v-icon size="14" class="mr-1">
            {{idToIcon(parseInt(data.item.value.id_categoria_registro))}}
          </v-icon>
            {{data.item.value.tipo_registro}}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import registrosEntity, {idToIcon} from "@/model/registros/registros.entity";

export default {
  data() {
    return {
      certificadoSeleccionado: null,
      cargandoCertificados: false,
      listaCertificados: [],
      busquedaCertificado: null,
    }
  },
  watch: {
    certificadoSeleccionado(val, oldVal){
      if(val !== oldVal) {
        this.$router.push("/tramites/" + val.url_categoria + "/" + val.url_tipo_registro + "/" + val.url_tipo_certificado);
        //alert(val.url_categoria + "/" + val.url_tipo_registro + "/" + val.url_tipo_certificado);
      }
    },
    busquedaCertificado() {
      //los get-certificados ya se cargaron
      if (this.listaCertificados.length > 0) {
        return;
      }

      //los notarios ya se están pidiendo
      if (this.cargandoCertificados) {
        return;
      }

      this.cargandoCertificados = true;

      registrosEntity.getAllCertificadosBuscador()
      .then((response)=>{
        this.listaCertificados = response;
      })
      .catch()
      .finally(()=>{
        this.cargandoCertificados = false;
      })
    },
  },
  methods: {
    idToIcon: idToIcon
  }
};
</script>