import {
    obtenerCarroActual,
    obtenerCarroFromApi,
    updateCarroActual
} from "@/model/carro/carro.repository";
import usuarioEntity from "@/model/usuario/usuario.entity";

const getCarro = () => {

    return new Promise((resolve, reject) => {
        let carro = [];
        usuarioEntity.isSessionActive().then(resp => {
            if (resp) {
                obtenerCarroFromApi().then(response => {
                    if (response.data.status) {
                        carro = response.data.obj;
                        updateCarroActual(carro);
                        resolve(carro);
                    } else {
                        reject({response: response, mensaje: "Hubo un error al obtener el carro"})
                    }
                })
            } else {

                resolve(obtenerCarroActual());

            }
        })

    })
}

export default getCarro
