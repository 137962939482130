<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-alert
          border="left"
          colored-border
          type="info"
          class="mb-0"
          :icon="false"
      >
        <v-row v-if="!usuarioValidado">

          <v-col cols="12" class="px-md-6">
            <h3 class="font-weight-medium text-center">Usuario no verificado</h3>
            <p class="my-2 text-justify">Al parecer no ha activado su cuenta ingresando a su correo, por favor indique
              si el siguiente correo es correspondiente a su cuenta:</p>
            <p class="text-center font-weight-medium">{{ correo }}</p>
          </v-col>

          <v-col class="pl-md-6" cols="12" md="6" v-if="!validandoUsuario">
            <v-btn block outlined color="secondary" @click="hideDialog">Volver</v-btn>
          </v-col>
          <v-col class="pr-md-6" cols="12" md="6" v-if="!validandoUsuario">
            <v-btn block outlined color="primary" @click="verificarCliente">Sí, es mi correo</v-btn>
          </v-col>

          <v-col cols="12" v-if="validandoUsuario">
            <v-progress-linear
                color="blue lighten-2"
                buffer-value="0"
                stream
                reverse
            ></v-progress-linear>
          </v-col>


        </v-row>
        <v-fade-transition>
          <v-row v-if="usuarioValidado">

            <v-col cols="12">

              <h3 class="font-weight-medium text-center">Usuario validado</h3>
              <div class="text-center" py-2>
                <v-icon color="success" x-large>mdi-check-bold</v-icon>
              </div>
              <p class="text-center">Ya podrá continuar con su compra</p>
            </v-col>
          </v-row>
        </v-fade-transition>

      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";

export default {
  name: "modalUsuarioNoVerificado",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },

  data() {
    return {
      ver: false,
      correo: usuarioEntity.getCorreoUsuario(),
      validandoUsuario: false,
      usuarioValidado: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    verificarCliente() {
      this.validandoUsuario = true;
      usuarioEntity.updateVerificacionClienteSesion().then(r => {
        if (r) {
          this.usuarioValidado = true;
          this.validandoUsuario = false;

          setTimeout(() => {
            this.hideDialog();
          }, 2000)
        } else {
          console.log("No se pudo valdidar el usuario");
        }
      })

    }
  }

}
</script>

<style scoped>

</style>
