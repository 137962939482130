import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.localStorage,
    })],
    state: {
        usuario: {
            nombreUsuario: null,
            rutUsuario: null,
            direccion: null,
            correo: null,
            id_comuna: null,
            activado: null,
            terminos_y_condiciones: null
        },
        carro: [],
        terminos_y_condiciones: null
    },
    mutations: {
        sesionIniciada(state, usuario) {
            state.usuario = {
                nombreUsuario: usuario.nombreUsuario,
                rutUsuario: usuario.rutUsuario,
                direccion: usuario.direccion,
                correo: usuario.correo,
                id_comuna: usuario.id_comuna,
                activado: usuario.activado,
                terminos_y_condiciones: usuario.terminos_y_condiciones
            }
        },
        actualizarTerminos(state, terminos){
            state.terminos_y_condiciones = terminos;
        },
        sesionEliminada(state) {
            state.usuario = {
                nombreUsuario: null,
                rutUsuario: null,
                direccion: null,
                correo: null,
                id_comuna: null,
                activado: null,
                terminos_y_condiciones: null
            }
        },
        updateCarro(state, carro_compras) {
            state.carro = carro_compras
        },
        deleteCarro(state) {
            state.carro = [];
        },
        updateNombreUsuario(state, nombreUsuario) {
            state.usuario.nombreUsuario = nombreUsuario;
        },
        updateActivacion(state, estado) {
            state.usuario.activado = estado;
        },
        actualizarTerminosUsuario(state, terms){
            state.usuario.terminos_y_condiciones = terms;
        }
    },
    actions: {},
    modules: {}
})
