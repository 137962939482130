export const ID_TIPO_FORMULARIO = {
    FNA: 1,
    ALZAMIENTO_Y_CANCELACION: 2,
    PLANOS: 11,
    ESCRITURAS_PUBLICAS: 4,
    DOCUMENTO_AGREGADO: 16
}

export const bancos = [
    "Banco Estado",
    "Banco de Chile",
    "Banco Internacional",
    "Scotiabank",
    "Scotiabank Azul",
    "Banco de Crédito e Inversiones",
    "Corpbanca",
    "Banco Bice",
    "Banco Coopeuch",
    "Banco los Heroes",
    "Banco Santander",
    "Banco Itaú Chile",
    "Banco Security",
    "Banco Falabella",
    "Banco Ripley",
    "Rabobank Chile",
    "Banco Consorcio",
    "Banco Paris",
    "Banco HSBC BANK CHILE",
    "Banco del Desarrollo",
    "Banco Brasil"
];

export const cuentas = ["Cuenta Corriente", "Cuenta Vista", "Cuenta Ahorro"];
