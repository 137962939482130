import {eliminarSesionLocal, isLoggedApi, isLoggedLocal} from "@/model/usuario/usuario.repository";

const isSessionActive = () => {
    //verificar si hay una sesión local
    return new Promise((resolve) => {
        if(isLoggedLocal())
        {
            //verificar si la sesión sigue en backend
            isLoggedApi().then((response)=>{

                if(response.data.status)
                {
                    if(response.data.obj)
                    {
                        //si la respuesta en obj es verdadera, entonces está validado
                        resolve(true);
                        return
                    }
                    else
                    {
                        eliminarSesionLocal();
                        resolve(false);
                        return
                    }
                }
                else
                {
                    //si hubo un problema, cerramos la sesión al no poder conectarnos
                    eliminarSesionLocal();
                    resolve(false);
                    return
                }
            }).catch((error)=>{
                //ante cualquier error lo mejor es obviar que la sesión se finalizó desde backend
                console.error(error);
                eliminarSesionLocal();
                resolve(false);
                return
            });
        }
        else
        {
            resolve(false);
        }
    })


}

export default isSessionActive;