import { registrarUsuarioFromApi } from "../usuario.repository";

const registrarUsuario = (nuevo_usuario, token) => {
    return new Promise((resolve, reject) => {
        registrarUsuarioFromApi(nuevo_usuario, token).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            } else {
                reject({ response: response, mensaje: `No se pudo registrar el usuario` })
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

export default registrarUsuario;