<template>
  <div>
    <v-form autocomplete="off" @submit.prevent="handleEntregaEnLinea(numeroCaratula, rut)">
      <v-row>
        <v-col>
          <v-card class="pa-5 mt-3">
            <div
                class="d-flex flex-column flex-sm-row align-stretch align-sm-baseline"
            >
              <v-text-field
                  v-model.number="numeroCaratula"
                  label="N° Carátula"
                  type="number"
                  required
                  :autofocus="!$vuetify.breakpoint.mobile"
                  :disabled="cargandoInfo"
                  :error-messages="errorsNumeroCaratula"
              />
              <v-text-field
                  class="ml-0 ml-sm-6"
                  v-model="rut"
                  label="Rut cliente"
                  required
                  :error-messages="errorsRut"
                  @input="$v.rut.$touch()"
                  @blur="$v.rut.$touch()"
                  :change="formatRut()"
                  :disabled="cargandoInfo"
              />
              <v-btn
                  class="ml-0 ml-sm-3 mt-3 mt-sm-0"
                  color="primary"
                  :loading="cargandoInfo"
                  type="submit"
                  small
              >
                <v-icon size="16" left>mdi-magnify</v-icon>
                Ver documentos
              </v-btn
              >
            </div>

            <v-fade-transition mode="out-in" leave-abosolute group>
              <v-row class="mt-0" key="primera-pantalla" v-if="primeraPantalla">
                <v-col cols="12">
                  <v-card outlined>
                    <v-card-title>
                      <v-icon left>mdi-information</v-icon>
                      Entrega en línea
                    </v-card-title>
                    <v-card-text>
                      Ingrese el número de la carátula y el rut del requirente de la misma para obtener la
                      documentación solicitada.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-0 flex-row-reverse" key="informacion" v-else-if="info">
                <v-col cols="12" md="6">
                  <v-card class="mb-7" color="primary" dark>
                    <v-card-title class="headline mb-2">
                      Carátula {{ info.infoCaratula[1].numero_caratula }}
                    </v-card-title>
                    <v-card-subtitle>
                      <div>
                        <div class="font-weight-normal white--text">
                          <strong>Solicitud {{ info.infoCaratula[1].id_solicitud_cliente_FK }}</strong>
                        </div>
                        <div class="font-weight-normal white--text">
                          {{ info.infoCaratula[2].cliente }}
                        </div>
                      </div>
                    </v-card-subtitle>
                  </v-card>
                  <v-card>
                    <v-card-title class="headline pb-2">
                      Documentos
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <lista-documentos-anexos :documentos_anexos="info.infoDocumentos"></lista-documentos-anexos>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card>
                    <v-card-title class="headline pb-2">
                      Historial de movimientos
                    </v-card-title>
                    <v-card-text>
                      <HistorialEstadosCaratula :estados="info.infoCaratula[1].estados"/>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>
              <v-row class="mt-0" key="error" v-else-if="error">
                <v-col cols="12">
                  <v-alert
                      class="fill-height mb-0"
                      outlined
                      type="warning"
                      border="left"
                  >
                    <span v-html="error"></span>
                  </v-alert>
                </v-col>
              </v-row>
            </v-fade-transition>

          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <modal-aviso-caratula-antigua :ver-dialog="modalCaratulaAntigua" @ocultarDialog="modalCaratulaAntigua = false"></modal-aviso-caratula-antigua>

  </div>

</template>

<script>
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import {required} from "vuelidate/lib/validators";
import caratulaEntity, {LIMITE_CARATULAS_ANTIGUAS} from "@/model/caratula/caratula.entity";
import HistorialEstadosCaratula from "@/components/HistorialEstados/HistorialEstadosCaratula";
import ListaDocumentosAnexos from "../../../components/DocumentosAnexos/ListaDocumentosAnexos";
import ModalAvisoCaratulaAntigua from "@/views/index/consultas-en-linea/ModalAvisoCaratulaAntigua";

const rutValidator = (rut) => rutValidate(rut);

export default {
  name: "ConsultasEnLineaViewEntregaEnLinea",
  components: {ModalAvisoCaratulaAntigua, ListaDocumentosAnexos, HistorialEstadosCaratula},
  data() {
    return {
      numeroCaratula: null,
      rut: null,
      primeraPantalla: true,
      cargandoInfo: false,
      info: null,
      error: null,
      modalCaratulaAntigua: false
    }
  },
  validations: {
    rut: {required, rutValidator},
    numeroCaratula: {required},
  },
  created(){
    if(this.$route.params.ncaratula != null && !isNaN(this.$route.params.ncaratula)){
      this.numeroCaratula = this.$route.params.ncaratula
    }
  },
  methods: {
    handleEntregaEnLinea(numeroCaratula, rutCliente) {

      if(numeroCaratula < LIMITE_CARATULAS_ANTIGUAS){
        this.modalCaratulaAntigua = true;
        return;
      }

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.primeraPantalla = false;
      this.cargandoInfo = true;
      this.info = null;
      this.error = null;

      caratulaEntity.getInformacionByRut(numeroCaratula, this.clearRut(rutCliente))
          .then((response) => {
            this.info = {};
            this.info.infoCaratula = response[0];
            this.info.infoDocumentos = response[1];
          })
          .catch((error) => {
            this.error = error.mensaje ? error.mensaje : error;
            console.error(error);
          })
          .finally(() => {
            this.cargandoInfo = false;
          })
    },
    clearRut(rutString) {
      return rutClean(rutString).slice(0, -1);
    },
    formatRut() {
      this.rut = rutFormat(this.rut);
    },
  },
  computed: {
    errorsRut: function () {
      const errors = [];
      if (!this.$v.rut.$dirty) return errors;
      !this.$v.rut.required && errors.push("Ingrese su rut.");
      !this.$v.rut.rutValidator && errors.push("Ingrese un rut válido.");
      return errors;
    },
    errorsNumeroCaratula: function () {
      const errors = [];
      if (!this.$v.numeroCaratula.$dirty) return errors;
      !this.$v.numeroCaratula.required && errors.push("Ingrese un número de carátula.");
      return errors;
    },
  }
}
</script>

<style scoped>

</style>