<template>
  <div>
    <v-form autocomplete="off" @submit.prevent="handleIniciarSesion()">
      <v-text-field
          v-model="rut"
          label="Rut"
          required
          :error-messages="errorsRut"
          @input="$v.rut.$touch()"
          @blur="$v.rut.$touch()"
          :change="formatRut()"
          clearable
          clear-icon="mdi-delete"
          :disabled="peticionLogeo"
          v-disabled-icon-focus
          :autofocus="!$vuetify.breakpoint.mobile"
      ></v-text-field>
      <v-text-field
          v-model="password"
          label="Contraseña"
          required
          :error-messages="errorsPassword"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"

          :type="mostrarPassword ? 'text' : 'password'"
          :append-icon="mostrarPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="mostrarPassword = !mostrarPassword"
          :disabled="peticionLogeo"
          v-disabled-icon-focus
      ></v-text-field>
      <div class="d-flex justify-center">
        <v-btn
            class="text-right"
            color="primary"
            type="submit"
            :loading="peticionLogeo"
            small
        >
          <v-icon
              right
              dark
              class="ml-0 mr-2"
          >
            mdi-key
          </v-icon>
          Iniciar Sesión
        </v-btn>
      </div>
      <div v-if="recaptchaV2"  class="d-flex justify-center mt-3">
        <br>
        <vue-recaptcha ref="recaptcha" sitekey="6Lfxv0gjAAAAAMnqpbd-QwVrAa0O8GjqrPsDKgys" @expired="expiredMethod" @verify="verifyMethod" />
      </div>
    </v-form>
    <v-fade-transition>
      <v-alert
          class="mt-3"
          v-if="errorPeticionLogeo != null"
          border="left"
          colored-border
          type="error"
          elevation="2"
          dismissible
      >
        {{ errorPeticionLogeo }}
      </v-alert>
    </v-fade-transition>
    <v-divider class="mt-6"></v-divider>
    <v-list dense class="text-center">
      <v-list-item-group
          color="primary"
      >
        <v-list-item @click="handleClickOpciones('/recuperar-cuenta')">
          <v-list-item-content>
            <v-list-item-title>¿Olvidó su contraseña?</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleClickOpciones({name: 'Registro'})">
          <v-list-item-content>
            <v-list-item-title>Crear una cuenta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import {required} from "vuelidate/lib/validators";

import { VueRecaptcha } from 'vue-recaptcha';
import usuario from "@/model/usuario/usuario.entity";
import DisabledIconFocusDirective from "@/directives/DisabledIconFocusDirective";
const rutValidator = (rut) => rutValidate(rut);

export default {
  name: "Login",
  directives: {DisabledIconFocusDirective},
  components: {VueRecaptcha},
  data() {
    return {
      rut: "",
      password: "",
      mostrarPassword: false,
      peticionLogeo: false,
      errorPeticionLogeo: null,
      recaptchaV2: false,
      recaptchaV2Token: null
    };
  },
  validations: {
    rut: {required, rutValidator},
    password: {required},
  },
  methods: {
    expiredMethod(){
      this.recaptchaV2Token = null;
      this.$refs.recaptcha.reset();
    },
    verifyMethod(response){
      console.log(response);
      this.recaptchaV2Token = response;
    },
    clearRut(rutString) {
      return rutClean(rutString).slice(0, -1);
    },
    formatRut() {
      this.rut = rutFormat(this.rut);
    },
    async handleIniciarSesion() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.peticionLogeo = true;
      this.errorPeticionLogeo = null;

      const vm = this;

      let t = null;
      if(this.recaptchaV2){
        if(this.recaptchaV2Token == null){
          this.errorPeticionLogeo = 'Favor haga click en la validación "No soy un Robot"';
          this.peticionLogeo = false;
          return;
        }
        t = this.recaptchaV2Token;
      }else{
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        t = await this.$recaptcha('login');
      }

      usuario
          .iniciarSesionUsuario(this.clearRut(this.rut), this.password, t, this.recaptchaV2)
          .then(function (response) {
            console.log(response);
            //inicio de sesión correcto, enviar evento hacia arriba
            //y updatear información en vuex
            vm.$emit("e-sesion-iniciada");
          })
          .catch(function (error) {
            console.log(error);
            if(error.code == 'NH'){
              vm.recaptchaV2 = true;
            }
            vm.errorPeticionLogeo = error.mensaje ? error.mensaje : error;
          }).finally(function () {
        vm.peticionLogeo = false;
        vm.recaptchaV2Token = null;
        vm.$refs.recaptcha.reset();
      });
    },
    handleClickOpciones(elementoTo) {
      this.$emit("e-click-opcion");
      this.$router.push(elementoTo);
    }
  },
  computed: {
    errorsPassword: function () {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Ingrese su contraseña.");
      return errors;
    },
    errorsRut: function () {
      const errors = [];
      if (!this.$v.rut.$dirty) return errors;
      !this.$v.rut.required && errors.push("Ingrese su rut.");
      !this.$v.rut.rutValidator && errors.push("Ingrese un rut válido.");
      return errors;
    },
  },
}
</script>

<style scoped>

</style>