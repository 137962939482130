//usa vuelidate, rutValidate
import {helpers} from "vuelidate/lib/validators";

const errorsRut = (rut) => {
    const errors = [];
    if (!rut.$dirty) return errors;
    !rut.required && errors.push("Ingrese su rut.");
    !rut.rutValidate && errors.push("Ingrese un rut válido.");
    return errors;
}

const errorNombreCompleto = (nombre_completo) => {
    const errors = [];

    if (!nombre_completo.$dirty) return errors;
    !nombre_completo.nombreApellidoValidate && errors.push("Ingrese por lo menos un nombre y un apellido");
    !nombre_completo.required && errors.push("Ingrese un nombre completo");
    return errors;
}

const errorBanco = (banco) => {
    const errors = [];
    if (!banco.$dirty) return errors;
    !banco.required && errors.push("Debe seleccionar una entidad bancaria");
    return errors;
}
const errorTipoCuenta = (tipo_cuenta) => {
    const errors = [];
    if (!tipo_cuenta.$dirty) return errors;
    !tipo_cuenta.required && errors.push("Debe seleccionar un tipo de cuenta");
    return errors;
}

const errorNumeroCuenta= (numero_cuenta) => {
    const errors = [];
    if (!numero_cuenta.$dirty) return errors;
    !numero_cuenta.required && errors.push("Debe ingresar un numero de cuenta");
    return errors;
}

const  errorCorreo= (correo) => {
    const errors = [];
    if (!correo.$dirty) return errors;
    !correo.required && errors.push("Ingrese su correo electrónico");
    return errors;
}

const   errorAnho= (anho)=> {
    const errors = [];
    if (!anho.$dirty) return errors;

    !anho.required && errors.push("Porfavor, ingrese un año");
    // aca evaluava anteriormente si.
    // negaba between (ni idea por que) para entrar a los siguientes ifs.
        if (parseInt(anho.$model) > new Date().getFullYear()) {
            errors.push("No puede solicitar documentos del futuro");
            return errors;
        } else if (parseInt(anho.$model) <= 1941) {
            errors.push("Solo puede solicitar documentos desde el año 1942");
            return errors;
        }

    return errors
}

export const nombreApellidoValidate = helpers.regex('nombreApellidoValidate', /( )*([a-zA-ZáéíóúäëïöüñÁÉÍÓÚÄËÏÖÜÑ-]{2,})( ([a-zA-ZáéíóúäëïöüñÁÉÍÓÚÄËÏÖÜÑ-]{2,}))+( )*/);

const validEntity = {
    errorsRut,
    errorNombreCompleto,
    errorBanco,
    errorTipoCuenta,
    errorNumeroCuenta,
    errorCorreo,
    errorAnho
}

export default validEntity;
