import {obtenerCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";

const verificarTipoAlzamientoCarro = (certificado) => {

    let carro = obtenerCarroActual();

    console.log("certificado a revisar: ", certificado)
    let puede_continuar = true;
    if (certificado.id_tipo_certificado === ID_TIPO_CERTIFICADO.CANCELACION_HIPOTECA || certificado.id_tipo_certificado === ID_TIPO_CERTIFICADO.ALZAMIENTO_PROHIBICIONES) {
        carro.map(c => {
            console.log("elemento c: " , c)
            if(c.id_tipo_certificado_FK === ID_TIPO_CERTIFICADO.CANCELACION_HIPOTECA_SERVIU || c.id_tipo_certificado_FK === ID_TIPO_CERTIFICADO.ALZAMIENTO_PROHIBICIONES_SERVIU){
                puede_continuar = false;
            }
        })
    }

    if (certificado.id_tipo_certificado === ID_TIPO_CERTIFICADO.CANCELACION_HIPOTECA_SERVIU || certificado.id_tipo_certificado === ID_TIPO_CERTIFICADO.ALZAMIENTO_PROHIBICIONES_SERVIU) {
        carro.map(c => {
            console.log("elemento c: " , c)
            if(c.id_tipo_certificado_FK === ID_TIPO_CERTIFICADO.CANCELACION_HIPOTECA || c.id_tipo_certificado_FK === ID_TIPO_CERTIFICADO.ALZAMIENTO_PROHIBICIONES){
                puede_continuar = false;
            }
        })
    }

    return puede_continuar
}

export default verificarTipoAlzamientoCarro