import getEstadoCaratula from "./usecases/get-estado-caratula";
import getInformacionByRut from "./usecases/get-informacion-by-rut";
import getMisCaratulas from "./usecases/get-mis-caratulas";
import getDocumentosAnexosCaratula from "./usecases/get-documentos-anexos-caratula";
import getLinkPagoSaldo from "./usecases/get-link-pago-saldo";
import getCaratulaDevolucion from "./usecases/get-caratula-devolucion";
import createSolicitudDevolucion from "./usecases/create-solicitud-devolucion";
import getEstadoPagoSaldoCaratula from "@/model/caratula/usecases/get-estado-pago-saldo-caratula";
import getCaratulasConDocumentosPorCorregir from "@/model/caratula/usecases/get-caratulas-con-documentos-por-corregir";
import updateCorreccionCertificado from "@/model/caratula/usecases/update-correccion-certificado";

export const LIMITE_CARATULAS_ANTIGUAS = 500000;

export const ID_ESTADO_CARATULA = {
    LISTA_PARA_FIRMAR: 1000,
    FIRMADA_ELECTRONICAMENTE: 1002
}

export const ID_ESTADO_CORRECCION = {
    NO_NECESARIA: -1,
    POR_CORREGIR: 0,
    CORREGIDO: 1,
    NO_CORREGIR: 2
}

const caratulaEntity = {
    getEstadoCaratula: getEstadoCaratula,
    getInformacionByRut: getInformacionByRut,
    getMisCaratulas: getMisCaratulas,
    getDocumentosAnexosCaratula:getDocumentosAnexosCaratula,
    getLinkPagoSaldo: getLinkPagoSaldo,
    getCaratulaDevolucion: getCaratulaDevolucion,
    createSolicitudDevolucion: createSolicitudDevolucion,
    getEstadoPagoSaldoCaratula: getEstadoPagoSaldoCaratula,
    getCaratulasConDocumentosPorCorregir,
    updateCorreccionCertificado
}

export default caratulaEntity;
