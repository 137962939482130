import axios from "axios";
import qs from "qs";

export const obtenerURLDocumentoFeDigitalAPI = (codigoDocumento) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/solicitudesclientes?action=verificar-documento",
            qs.stringify({
                codigo_documento: codigoDocumento,
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const buscarDocumentoElectronicoApi = (id_documento) =>{

    return new Promise((resolve, reject) => {
        axios.post(
            "/validar-documento-electronico",
            qs.stringify({
                id: id_documento,
            })
        ).then(function (response) {
            resolve(response.data.obj);
            console.log("miresponse: " + response)
        }).catch(function (error) {
            reject(error);
        });
    })

}