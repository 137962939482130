import {getDocumentosTentativosCertificadoApi} from "@/model/registros/registros.repository";
import {evaluarDocumento} from "@/model/registros/usecases/get-certificado-tramites-en-linea";

const getDocumentosTentativosCertificado = (certificado, indice) => {
    return new Promise((resolve, reject) => {
        getDocumentosTentativosCertificadoApi(certificado, indice).then((response) => {

            let array_all_documentos = response;

            certificado.numero_copias = 1;
            certificado.vigencia = -1;
            let certificado_encontrado = false;
            array_all_documentos.map(s => {
                s.numero_copias = 0;

                if (certificado.id_tipo_certificado == s.id_tipo_certificado) {
                    certificado_encontrado = true;
                    s.numero_copias = 1;
                }

                if (evaluarDocumento(certificado, s)) {
                    s.vigencia = -1;
                }
            })
            if (!certificado_encontrado) {
                reject("NOT_CERTIFICADO");
            }
            resolve(array_all_documentos);

        }).catch(function (error) {
            reject(error);
        });
    })
}

export default getDocumentosTentativosCertificado;