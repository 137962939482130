<template>

  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-alert
          border="left"
          colored-border
          :type="tipo"
          class="mb-0"
          :icon="false"
      >
        <h3 class="text-center font-weight-medium pb-2 info--text">Límite de búsquedas alcanzado</h3>
        <v-divider></v-divider>
        <p class="text-center pt-4 px-4">Estimado cliente, usted ha alcanzado el límite de búsquedas diaro</p>
        <div class="text-center">
          <v-btn outlined color="info" @click="hideDialog()">Volver</v-btn>
        </div>

      </v-alert>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "ModalLimiteAlcanzado",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>
