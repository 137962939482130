<template>
  <div>
    <TheBreadcrumb in-certificados/>

    <v-row class="flex-row flex-sm-row-reverse">
      <v-col cols="12" md="8" class="pr-md-0">

        <v-scroll-y-reverse-transition mode="out-in">
          <v-card
              key="seleccion"
              v-if="!jurisdiccionSeleccionada"
              class="mb-6"
          >
            <v-card-text>
              <h2 class="font-weight-regular mb-3">{{ mensajes.titulo_jurisdiccion }}</h2>
              <v-divider></v-divider>
              <div class="d-flex px-0" style="font-size: 15px !important; cursor: pointer"
                   @click="seleccionarJurisdiccion()">
                <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-checkbox-blank-outline</v-icon>
                <p class="my-3 font-weight-medium pl-1" style="justify-content: left !important; ">
                  <!--                <span> La propiedad se encuentra ubicada en la comuna de Coquimbo</span>-->
                  <span> {{ mensajes.jurisdiccion_ok }}</span>
                </p>
              </div>
              <div class="d-flex px-0" style="font-size: 15px; cursor: pointer" @click="jurisdiccion.dialog = true">
                <v-icon color="info" small class="pr-2" style="font-size: inherit">mdi-checkbox-blank-outline</v-icon>
                <p class="my-0 font-weight-regular pl-1" style="justify-content: left !important;">
                  <!--               <span> La propiedad se encuentra en otra comuna</span>-->
                  <span>{{ mensajes.otra_jurisdiccion }}</span>
                </p>
              </div>


            </v-card-text>
          </v-card>

          <v-card
              key="confirmado"
              v-else
              class="mb-6"
          >
            <v-card-text class="py-0">
              <div class="d-flex px-4" style="font-size: 15px !important; cursor: pointer">
                <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-check</v-icon>
                <p class="my-3 success--text pl-1" style="justify-content: left !important; ">

                  <!--                <span> La propiedad se encuentra ubicada en la comuna de Coquimbo</span>-->
                  <span> {{ mensajes.jurisdiccion_ok }}</span>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-scroll-y-reverse-transition>

        <v-fade-transition>
          <v-card class="px-8 py-2" v-if="!load.certificado">
            <v-row>
              <v-col cols="12" class="pb-0">
                <h2 class="font-weight-regular d-flex">
                  <div>
                    {{ url_tipo_registro | notUrl }}
                  </div>


                </h2>
              </v-col>

              <!--Formulario FNA -->
              <v-col cols="12"
                     v-if="certificado.id_grupo_sugerencias === ID_GRUPO.COPIAS_Y_CERTIFICADOS || certificado.id_grupo_sugerencias === ID_GRUPO.PRH_PROPIEDAD || certificado.id_grupo_sugerencias === ID_GRUPO.HIP_PROPIEDAD">
                <h3 class="text-subtitle-1 mb-0 font-weight-regular" v-if="!buscarPorNombre">
                  Ingrese los datos de un título de dominio
                </h3>
                <v-row>
                  <v-col>
                    <!--                    :key="habilitarFormulario"-->
                    <formulario-fna
                        :key="keyFormularioFna"
                        @send="validarRef()"
                        :id_tipo_registro="certificado.id_tipo_registro_FK"
                        :campos-habilitados="habilitarFormulario"
                        :certificado="certificado"
                        @setFormulario="puedeValidar($event)"
                        @buscandoNombre="buscarPorNombre=$event"></formulario-fna>
                  </v-col>
                  <v-col class="pt-md-7" cols="12" md="3" v-if="!buscarPorNombre">
                    <validador-indices ref="validador" :puede-validar-indice="puedeValidarIndice"
                                       :habilitar-boton="habilitarFormulario"
                                       :certificado="certificado"
                                       :data-formulario="dataFormulario" @corregirFoja="corregirFoja($event)"
                                       @confirmarIndice="confirmarIndice"></validador-indices>
                  </v-col>

                  <v-col class="text-right" cols="12">
                    <v-btn text x-small color="info" :disabled="!mostrarDocumentosTentativos"
                           @click="limpiarFormularioFna">Limpiar formulario
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <!--Formulario Planos -->
              <v-col cols="12"
                     v-if="certificado.id_grupo_sugerencias === ID_GRUPO.PLANOS_LEY || certificado.id_grupo_sugerencias === ID_GRUPO.PLANOS_PROPIEDAD ">
                <formulario-planos :key="jurisdiccionSeleccionada" :campos-habilitados="jurisdiccionSeleccionada"
                                   @setFormulario="setFormulario($event)"></formulario-planos>
              </v-col>

              <!--Formulario Alzamiento -->
              <v-col cols="12" v-if="certificado.id_grupo_sugerencias === ID_GRUPO.ALZAMIENTO_Y_CANCELACION">

                <h3 class="text-subtitle-1 mb-0 font-weight-regular"> Ingrese los datos de escritura firmada
                  electrónicamente</h3>
                <div v-if="formularioAlzamientoPrecargado">
                  <alzamiento-precargado :data="dataFormulario"></alzamiento-precargado>
                </div>
                <div v-if="!formularioAlzamientoPrecargado">
                  <alzamiento-cancelacion :campos-habilitados="jurisdiccionSeleccionada"
                                          @setFormulario="setFormulario($event)"></alzamiento-cancelacion>
                </div>
              </v-col>

              <!--Formulario Alzamiento -->

              <!-- Formulario Escrituras Públicas -->
              <v-col cols="12"
                     v-if="certificado.id_grupo_sugerencias === ID_GRUPO.ESCRITURAS_PUBLICAS || certificado.id_grupo_sugerencias === ID_GRUPO.ESCRITURAS_PUBLICAS_2">
                <div v-if="!formularioEscrituraPublicaPrecargado">
                  <formulario-escritura-publica :campos-habilitados="jurisdiccionSeleccionada"
                                                @setFormulario="setFormulario($event)"></formulario-escritura-publica>
                </div>
                <div v-if="formularioEscrituraPublicaPrecargado">
                  <formulario-escritura-publica-precargado
                      :escritura="dataFormulario"
                      @setFormulario="setFormulario($event)"></formulario-escritura-publica-precargado>
                </div>

              </v-col>
              <!-- Formulario Escrituras Públicas -->

              <!-- Formulario documento agregado -->
              <v-col cols="12" v-if="certificado.id_grupo_sugerencias === ID_GRUPO.DOCUMENTO_AGREGADO">
                <formulario-documento-agregado :key="jurisdiccionSeleccionada" :campos-habilitados="jurisdiccionSeleccionada"
                                               @setFormulario="setFormulario($event)"></formulario-documento-agregado>
              </v-col>

            </v-row>
            <v-scroll-y-transition>
              <v-row v-if="mostrarDocumentosTentativos && !buscarPorNombre">
                <!--Documento Seleccionado -->
                <v-col cols="12">

                  <documentos-tentativos :show="certificado" :listaCertificados="sugerencias"
                                         :numero-carillas="carillas_encontradas"
                                         :precio-carillas="precio_adicional_carillas"
                                         @aumentar="aumentarCantidadDocumentos($event)"
                                         @reducir="reducirCantidadDocumentos($event)"
                  ></documentos-tentativos>

                </v-col>
                <!--Documento Seleccionado -->
                <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                  <p align="center" class="py-3 mb-1" style="color: #2196f3; background: #2196f324; border-radius: 3px">
                    ¿Desea
                    otros documentos para la inscripción citada?</p>


                </v-col>

                <!--Otros Documentos -->
                <v-col cols="12" class="pt-0">
                  <documentos-tentativos :hide="certificado" :listaCertificados="sugerencias"
                                         :numero-carillas="carillas_encontradas"
                                         :precio-carillas="precio_adicional_carillas"
                                         @aumentar="aumentarCantidadDocumentos($event)"
                                         @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>
                </v-col>
                <!--Otros Documentos -->


                <!-- Agregar al Carro -->
                <v-col cols="12" class="d-flex  flex-column pt-5">
                  <div class="d-flex justify-end mb-3"><span class="font-weight-bold pr-2">Subtotal: </span>

                    <span v-if="getPrecioTotal > 0">{{ getPrecioTotal | clp }}</span>
                    <span v-if="getPrecioTotal === 0">Por cotizar</span>
                  </div>
                  <div class="d-flex justify-end">

                    <v-btn :disabled="dataFormulario== null " color="primary" @click="validarSerie">Agregar
                      al carro
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-scroll-y-transition>
            <!-- Agregar al Carro -->

          </v-card>
        </v-fade-transition>
      </v-col>
      <v-col sm="12" md="4" class=" pl-sm-6">
        <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
      </v-col>
    </v-row>


    <!--    <modal-jurisdiccion :verDialog="jurisdiccion.dialog"-->
    <!--                        @ocultarDialogJurisdiccion="ocultarDialogJurisdiccion"></modal-jurisdiccion>-->
    <simple-modal :verDialog="jurisdiccion.dialog" @ocultarDialog="jurisdiccion.dialog = false">
      <div>
        <h3 class="text-center pb-3">Otras comunas</h3>
        <v-divider></v-divider>
        <p class="my-3 px-4 text-center">
          Si la comuna no pertenece al Conservador de Bienes Raíces de Coquimbo, deberá revisar el portal de la <a
            href="http://www.conservadores.cl/oficios_registrales/" target="_blank">Corporación Chilena de Estudios de
          Derecho Registral</a> para realizar su trámite.
        </p>
        <v-btn outlined block color="info" @click="jurisdiccion.dialog = false">Volver</v-btn>
      </div>
    </simple-modal>
    <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                              :documentosAgregados="modalAgregar.lista_agregados"
                              :documentosRepetidos="modalAgregar.documentos_repetidos"
                              :documentosEnLimite="modalAgregar.limite_carro"
                              @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>

    <simple-modal-static :verDialog="verModalIncompatibilidadAlzamiento">
      <v-row>
        <v-col class="text-center" cols="12">Documentos incompatibles en el carro, puede quitar los alzamientos y
          cancelaciones para realizar la operación solicitada
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
              color="warning"
              outlined
              @click="()=>{this.$router.push('/tramites')}"
          >Volver a Trámites
          </v-btn>
        </v-col>
      </v-row>
    </simple-modal-static>
    <modal-agregar-vigencia :certificado="certificadoModalAgregarVigencia" :verDialog="verModalAgregarVigencia"
                            @ocultarDialog="aumentarCantidadDocumentosVigencia(certificadoModalAgregarVigencia, $event)"></modal-agregar-vigencia>
    <simple-modal :verDialog="verModalGP" @ocultarDialog="verModalGP = false">
      <div><h3 class="font-weight-regular info--text text-center">Certificado de Hipotecas y Gravámenes</h3></div>
      <v-row>
        <v-col cols="3" class="d-flex justify-center align-center">
          <v-icon color="info" x-large>mdi-information-outline</v-icon>
        </v-col>
        <v-col cols="9" class="text-center">Los alzamientos y/o cancelaciones deben pertenecer a una sola propiedad
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn @click="verModalGP = false" color="info" outlined block>Entiendo</v-btn>
        </v-col>
      </v-row>
    </simple-modal>

    <simple-modal :verDialog="verModalAgregarAlzamiento" @ocultarDialog="verModalAgregarAlzamiento = false">
      <div>
        <h3 class="font-weight-regular warning--text text-center">Atención</h3>
        <v-row>
          <v-col cols="12" md="3" class="d-flex justify-center align-center">
            <v-icon color="warning" x-large>mdi-alert-circle</v-icon>
          </v-col>
          <v-col cols="12" md="9" class="text-justify">Al agregar los ítems especificados al carro de compras usted
            acepta que
            la
            escritura ingresada es únicamente de alzamiento(s) y/o cancelación(es).
          </v-col>
          <v-col cols="12" md="6" class="text-center pl-md-7">
            <v-btn outlined block color="success" @click="agregarAlCarro(sugerencias, dataFormulario)">Acepto
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="text-center pr-md-7 pt-2 pt-md-3">
            <v-btn outlined block color="info" @click="verModalAgregarAlzamiento = false">Cancelar</v-btn>
          </v-col>
        </v-row>
      </div>
    </simple-modal>

    <simple-modal tipo="info" :ver-dialog="dialogSeriePlano" @ocultarDialog="dialogSeriePlano = false">
      <div>
        <h3 class="font-weight-regular info--text text-center pb-2">Plano no encontrado</h3>
        <v-divider></v-divider>
        <p class="text-center py-4">
          El plano ingresado no puede ser solicitado desde nuestro portal, favor de acercarse personalmente al
          Conservador de Bienes Raíces de Coquimbo.
        </p>
        <div class="text-center">
          <v-btn color="info" outlined @click="dialogSeriePlano = false">Aceptar</v-btn>
        </div>
      </div>
    </simple-modal>
    <modal-confirma-escritura-publica :ver-dialog="modalConfirmaEscrituraPublica"
                                      @ocultarDialog="modalConfirmaEscrituraPublica = false"
                                      @confirmarEscrituraPublica="confirmarEscrituraPublica"></modal-confirma-escritura-publica>
    <modal-no-puede-agregar :ver-dialog="modalNoPuedeAgregar"
                            @ocultarDialog="modalNoPuedeAgregar = false"></modal-no-puede-agregar>
  </div>


</template>

<script>
import registros, {ID_AREA_AGRUPACION, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import InformacionDocumento from "@/components/SolicitudDocumentos/InformacionDocumento";
import AlzamientoCancelacion
  from "@/components/SolicitudDocumentos/Formularios/AlzamientoCancelacion/AlzamientoCancelacion";
import FormularioFna from "@/components/SolicitudDocumentos/Formularios/FormularioFna";
import DocumentosTentativos from "@/components/SolicitudDocumentos/DocumentosTentativos";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
import carro from "@/model/carro/carro.entity";
// import ModalJurisdiccion from "@/components/SolicitudDocumentos/modals/ModalJurisdiccion";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";
import AlzamientoPrecargado
  from "@/components/SolicitudDocumentos/Formularios/AlzamientoCancelacion/AlzamientoPrecargado";
import SimpleModalStatic from "@/components/SimpleModalStatic";
import ModalAgregarVigencia from "@/components/ModalAgregarVigencia";
import FormularioPlanos from "@/components/SolicitudDocumentos/Formularios/FormularioPlanos";
import SimpleModal from "@/components/SimpleModal";
import usuarioEntity from "@/model/usuario/usuario.entity";
import FormularioEscrituraPublica from "@/components/SolicitudDocumentos/Formularios/FormularioEscrituraPublica";
import FormularioEscrituraPublicaPrecargado
  from "@/components/SolicitudDocumentos/Formularios/FormularioEscrituraPublicaPrecargado";
import ValidadorIndices from "@/components/Tramites/ValidadorIndices";
import ModalConfirmaEscrituraPublica from "@/components/Tramites/ModalConfirmaEscrituraPublica";
import ModalNoPuedeAgregar from "@/views/index/tramites-en-linea/components/modalNoPuedeAgregar";
import FormularioDocumentoAgregado from "@/components/SolicitudDocumentos/Formularios/FormularioDocumentoAgregado";
import registrosEntity from "@/model/registros/registros.entity";
// import getDocumentosTentativosCertificado from "@/model/registros/usecases/get-documentos-tentativos-certificado";

export default {
  name: "TramitesEnLineaViewMenuCertificado",
  components: {
    FormularioDocumentoAgregado,
    ModalNoPuedeAgregar,
    ModalConfirmaEscrituraPublica,
    ValidadorIndices,
    FormularioEscrituraPublicaPrecargado,
    FormularioEscrituraPublica,
    SimpleModal,
    FormularioPlanos,
    SimpleModalStatic,
    AlzamientoPrecargado,
    // ModalJurisdiccion,
    AlzamientoCancelacion,
    InformacionDocumento,
    FormularioFna,
    DocumentosTentativos,
    TheBreadcrumb,
    ModalDocumentoAgregado,
    ModalAgregarVigencia
  },
  data() {
    return {

      keyFormularioFna: 0,
      ID_GRUPO: registros.ID_GRUPO,
      url_tipo_certificado: "",
      url_tipo_registro: "",
      certificado: {},
      sugerencias: {},
      dataFormulario: null,
      load: {
        certificado: false
      },
      dialogSerie: false,
      dialogSeriePlano: false,
      serie: null,
      mostrarDocumentosTentativos: false,
      formularioAlzamientoPrecargado: false,
      jurisdiccion: {
        switch: false,
        remarcado: false,
        dialog: false
      },

      verModalAgregarVigencia: false,
      certificadoModalAgregarVigencia: null,
      verModalDocumentosRepetidos: false,
      verModalIncompatibilidadAlzamiento: false,
      verModalGP: false,
      verModalAgregarAlzamiento: false,
      documentosRepetidosResponse: [],
      modalAgregar: {
        documentos_repetidos: [],
        limite_carro: [],
        lista_agregados: [],
        verModalDocumentoAgregado: false
      },
      jurisdiccionSeleccionada: false,
      buscarPorNombre: false,
      mensajes: {
        jurisdiccion_ok: "SE ENCUENTRA UBICADA EN LA COMUNA DE COQUIMBO",
        otra_jurisdiccion: "ESTA EN OTRO LUGAR",
        titulo_jurisdiccion: "Seleccione donde se encuentra ubicada la propiedad"
      },
      formularioEscrituraPublicaPrecargado: false,
      puedeValidarIndice: false,
      habilitarFormulario: false,
      carillas_encontradas: 0,
      precio_adicional_carillas: 0,
      modalConfirmaEscrituraPublica: false,
      modalNoPuedeAgregar: false
    }
  },
  methods: {
    confirmarEscrituraPublica() {
      this.modalConfirmaEscrituraPublica = false;
      this.agregarAlCarro();
    },
    limpiarFormularioFna() {

      console.log("intenta limpiar el formulario")
      this.keyFormularioFna++;
      this.puedeValidarIndice = false;
      this.habilitarFormulario = true;
      this.carillas_encontradas = 0;
      this.precio_adicional_carillas = 0;
      this.mostrarDocumentosTentativos = false;
    },

    validarRef() {
      this.$refs.validador.validarIndice();
    },
    confirmarSinIndice(data){
      if(data != null){
        //  this.habilitarFormulario = false;
        registrosEntity.getCertificadoTramitesEnLinea(this.url_tipo_registro, this.url_tipo_certificado).then(res=>{
          this.sugerencias = res.sugerencias_certificado;
          //  this.mostrarDocumentosTentativos = true;
          this.dataFormulario = data
        })
      }

    },
    confirmarIndice() {
      this.habilitarFormulario = false;
      let indice = this.dataFormulario.foja + "-" + this.dataFormulario.numero + "-" + this.dataFormulario.anho
      console.log("data formulario: ", this.dataFormulario)
      //this.load.certificado = true;
      registros.getDocumentosTentativosCertificado(this.certificado, indice).then(res => {
        this.sugerencias = res
        this.mostrarDocumentosTentativos = true;
        console.log(res)
      }).catch(e => {
        if (e === "NOT_CERTIFICADO") {
          console.log("no puede agregar el documento, bloqueo por indice")
          this.modalNoPuedeAgregar = true;
          this.habilitarFormulario = true;
        } else {
          console.log("Error inesperado")
        }
      })

    },
    desactivarBuscarNombre() {
      this.buscarPorNombre = false;
    },
    seleccionarJurisdiccion() {
      setTimeout(() => {
        this.jurisdiccionSeleccionada = true
        this.habilitarFormulario = true;

        if (this.formularioAlzamientoPrecargado) {
          this.mostrarDocumentosTentativos = true;
        }
      }, 200)
    },
    ocultarDialogJurisdiccion() {
      this.jurisdiccion.dialog = false;
    },
    corregirFoja(datosIndice) {
      this.carillas_encontradas = 0;
      this.precio_adicional_carillas = 0;

      console.log("llegan los dat")

      if (datosIndice != null) {
        this.dataFormulario.foja = datosIndice.foja;
        this.carillas_encontradas = datosIndice.numero_carillas;
        this.precio_adicional_carillas = datosIndice.precio_carillas
      }
      this.confirmarIndice()
    },
    agregarAlCarro() {
      console.log("intenta agregar al carro")
      carro.agregarAlCarro(this.sugerencias, this.dataFormulario, this.precio_adicional_carillas).then(response => {
        this.verModalAgregarAlzamiento = false;
        this.cerrarModalSerie();
        this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
        this.modalAgregar.limite_carro = response.limite_carro
        this.modalAgregar.lista_agregados = response.lista_agregados;
        this.modalAgregar.verModalDocumentoAgregado = true;
      })


    },
    puedeValidar(data) {
      this.puedeValidarIndice = data != null;
      this.dataFormulario = data;
    },

    setFormulario(data) {
      this.mostrarDocumentosTentativos = data != null;
      this.dataFormulario = data;
    },
    aumentarCantidadDocumentos(certificado) {

      if (certificado.id_tipo_registro_FK == registros.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION && certificado.id_tipo_certificado == registros.ID_TIPO_CERTIFICADO.ALZAMIENTO_GP_UNA_PRP) {
        this.verModalGP = true;
      }

      if (!certificado.has_vigencia) {
        if (certificado.solo_valija_electronica) {
          if (certificado.numero_copias < 1) {
            certificado.numero_copias++;

          }
        } else {
          certificado.numero_copias++;
        }
      } else {
        this.certificadoModalAgregarVigencia = certificado;
        this.verModalAgregarVigencia = true;
      }


    },
    aumentarCantidadDocumentosVigencia(certificado, vigencia) {

      certificado.vigencia = vigencia.anhos;
      if (certificado.solo_valija_electronica) {
        if (certificado.numero_copias < 1) {

          certificado.numero_copias++;

        }
      } else {
        certificado.numero_copias++;
      }
      this.verModalAgregarVigencia = false;
    },
    reducirCantidadDocumentos(certificado) {

      if (certificado === this.certificado) {
        if (certificado.numero_copias > 1) {
          certificado.numero_copias--;
        }
      } else {
        if (certificado.numero_copias > 0) {
          certificado.numero_copias--;

          if (certificado.numero_copias == 0) {
            certificado.vigencia = -1;
          }

        }
      }
    },
    // modificarLitigio(certificado){
    //
    // },
    validarSerie() {

      console.log("Data formulario: ", this.dataFormulario);
      if (this.dataFormulario == null) return;

      if (this.dataFormulario.id_tipo_formulario == ID_TIPO_FORMULARIO.ALZAMIENTO_Y_CANCELACION) {
        this.verModalAgregarAlzamiento = true;
        return;
      }

      if (this.dataFormulario.id_tipo_formulario == ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS) {
        this.modalConfirmaEscrituraPublica = true;
        return;
      }
      if (this.certificado.id_tipo_registro_FK === registros.ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD) {

        if (this.dataFormulario.id_tipo_formulario == ID_TIPO_FORMULARIO.PLANOS) {
          registros.isSerie(this.dataFormulario.numero, this.dataFormulario.anho, this.certificado.id_tipo_registro_FK, this.certificado.id_tipo_certificado).then(response => {
            if (response) {

              this.serie = response;
              this.serie.fojaBuscada = this.dataFormulario.foja;
              // registros.agregarAlCarro(this.sugerencias)
              if (response.fojaBuscada == response.foja) {
                this.agregarAlCarro()

              } else {
                this.dialogSerie = true;
              }

            } else {
              this.serie = null;
              this.dialogSeriePlano = true;
            }
          })
        } else {
          this.agregarAlCarro(this.sugerencias, this.dataFormulario);
        }
      } else {
        this.agregarAlCarro(this.sugerencias, this.dataFormulario);
      }
    },
    cerrarModalSerie() {
      this.dialogSerie = false;
    }
  },
  computed: {
    getPrecioTotal() {
      let precioTotal = 0;


      this.sugerencias.map(cert => {
        precioTotal += cert.numero_copias * (parseInt(cert.precio) + (cert.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precio_adicional_carillas) : 0))
      })

      let certificados_obligatorios = registros.getCertificadosObligatoriosFromListaCertificados(this.sugerencias);

      certificados_obligatorios.map(cert => {
        precioTotal += cert.precio;
      })

      console.log("filtro de obligatorios: ", certificados_obligatorios);

      return precioTotal;
    },
    getRutUsuario() {
      return usuarioEntity.getRutUsuario()
    }
  },

  created() {
    this.jurisdiccionSeleccionada = true
    this.habilitarFormulario = true;

    if (this.formularioAlzamientoPrecargado) {
      this.mostrarDocumentosTentativos = true;
    }
    let categoria = this.$route.params.categoriaRegistro;

    console.log("Tengo la categoria: ", categoria)

    switch (categoria) {
      case "propiedad":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde se encuentra ubicada la propiedad";
        this.mensajes.jurisdiccion_ok = "La propiedad se encuentra ubicada en la comuna de Coquimbo."
        this.mensajes.otra_jurisdiccion = "La propiedad se encuentra en otra comuna."
        break;
      case "comercio":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de Coquimbo."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      case "aguas":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de Coquimbo."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      case "minas":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de Coquimbo."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      case "escrituras-publicas":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La escritura pertenece a una notaría de la comuna de Coquimbo."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      default:
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de Coquimbo."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
    }


    this.url_tipo_registro = this.$route.params.tipoRegistro;
    this.url_tipo_certificado = this.$route.params.tipoCertificado;

    registros.getCertificadoByUrl(this.url_tipo_registro, this.url_tipo_certificado).then(response => {
      this.certificado = response

      if(response.id_tipo_registro != ID_TIPO_REGISTRO.COMERCIO){
        registrosEntity.getCertificadoTramitesEnLinea(this.url_tipo_registro, this.url_tipo_certificado).then(res=>{
          this.sugerencias = res.lista_certificados;
          console.log(res)
          console.log("sugerencias: ", this.sugerencias)
        })
      }

      switch (this.certificado.id_tipo_registro_FK) {
        case registros.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION:
          if (!carro.verificarTipoAlzamiento(this.certificado)) {
            //incompatibilidad de carros... volver a la pagina anterior
            this.verModalIncompatibilidadAlzamiento = true;
          }

          if (carro.obtenerAlzamiento()) {
            this.dataFormulario = carro.obtenerAlzamiento();
            this.formularioAlzamientoPrecargado = true;
          } else {
            //this.mostrarDocumentosTentativos = false;
            this.formularioAlzamientoPrecargado = false;
          }



          break;

        case registros.ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS:
          console.log("Es escritura publica: ", this.certificado);
          this.dataFormulario = carro.getDatosAdicionalesCertificado(ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS);

          console.log("mi dataFormulario: ", this.dataFormulario)
          if (this.dataFormulario) {
            this.formularioEscrituraPublicaPrecargado = true;

          } else {
            this.formularioEscrituraPublicaPrecargado = false;
          }
          break;
        default:
          //this.mostrarDocumentosTentativos = true;
          if (this.certificado.has_vigencia) {
            this.certificadoModalAgregarVigencia = this.sugerencias.filter(doc => {
              return doc.id_tipo_certificado == this.certificado.id_tipo_certificado
            })[0]
            this.verModalAgregarVigencia = true;
          }
      }

    })
    // this.load.certificado = true;
    // registros.getCertificadoTramitesEnLinea(this.url_tipo_registro, this.url_tipo_certificado).then(response => {
    //
    //   this.sugerencias = response.lista_certificados
    //
    // }).catch(() => {
    //   this.certificado = {
    //     data: "Certificado no encontrado"
    //   }
    // }).finally(() => this.load.certificado = false)
  }
  ,

}
</script>

<style scoped>
.remarcado-jurisdiccion {
  transition: background-color 0.4s linear 0.2s;
  background-color: #00acc130;
  border-radius: 7px;
}
</style>
