import {getEstadoPagoSaldoCaratulaFromApi} from "@/model/caratula/caratula.repository";

const getEstadoPagoSaldoCaratula = (numero_caratula) =>{
    return new Promise((resolve, reject) => {
        getEstadoPagoSaldoCaratulaFromApi(numero_caratula).then(resp=>{
            if(resp.status){
                resolve(resp)
            }else{
                reject(resp)
            }

        }).catch(error=>{
            reject(error)
        })
    })
}

export default getEstadoPagoSaldoCaratula;