import {getDocumentosAnexosCaratulaApi} from "../caratula.repository";

const getDocumentosAnexosCaratula = (numero_caratula) =>{

    return new Promise((resolve, reject)=>{
        getDocumentosAnexosCaratulaApi(numero_caratula).then(response=>{
            if(response.data.status){
                resolve(response.data.obj)
            }else{
                reject(response.data)
            }
        }).catch(error=>{
            reject(error)
        })
    })

}

export default getDocumentosAnexosCaratula;
