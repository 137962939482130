import {getPDFApi} from "../indice.repository";

const getPDFFna = (serie,tipoDocumento, token) => {
    return new Promise((resolve, reject) => {
        getPDFApi(serie,tipoDocumento, token).then((response) => {
            if (response) {
                resolve(response)
            } else {
                reject({error: response.data.obj})
            }
        }).catch(error => {
            if (error.response.status == 401) {
                reject({error: "Ha alcanzado el límite de visualizaciones diarias de documentos."})
            } else {
                reject(error);
            }
        });
    });
}

export default getPDFFna;
