import {updatearPasswordLogeadoAPI} from "@/model/usuario/usuario.repository";

const cambiarPassLogeado = (oldPass, newPass) => {
    return new Promise((resolve, reject) => {
        updatearPasswordLogeadoAPI(oldPass, newPass)
            .then((response) => {
                if (response.data.status) {
                    if(response.data.code === "CLIENTE:NOT_UPDATE_MATCH")
                    {
                        reject({response: response, mensaje: "La contraseña ingresada como actual no corresponde."})
                    }
                    else if(response.data.code === "CLIENTE:UPDATE_PASS")
                    {
                        resolve("Contraseña cambiada correctamente.");
                    }
                } else {
                    reject({response: response, mensaje: "No se pudieron actualizar los datos del usuario."})
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export default cambiarPassLogeado;