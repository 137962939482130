<template>
  <v-timeline
      align-top
      dense
      class="pt-0"
  >
    <v-timeline-item
        class="pb-4 py-0"
        v-for="(estado, index) in estadosCaratulaOrdenados"
        :key="index"
        :color="index === 0 ? 'secondary' : 'primary'"
        small

    >
      <div>
        <div>
          <v-icon size="16">mdi-calendar</v-icon>
          {{ estado.fecha | moment("DD/MM/YYYY HH:mm") }}
        </div>
        <div class="font-weight-normal">
          <strong>{{ estado.estado_caratula }}</strong>
        </div>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  name: "HistorialEstadosCaratula",
  props: {
    estados: Array,
  },
  computed: {
    estadosCaratulaOrdenados: function () {
      let estadosCaratulaOrdenados = [];

      this.estados.map(es=>{
        estadosCaratulaOrdenados.unshift(es)
      })

      return estadosCaratulaOrdenados

      // return estadosCaratulaOrdenados.sort((a, b) =>
      //     a.fecha > b.fecha ? -1 : b.fecha > a.fecha ? 1 : 0
      // );
    },
  }
}
</script>

<style scoped>

</style>