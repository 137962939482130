import {getAllCertificadosApi} from "@/model/registros/registros.repository";

const getAllCertificadosBuscador = () => {
    return new Promise((resolve, reject) => {
        getAllCertificadosApi()
            .then((response)=>{
                if (response.data.status) {
                    //convertir la lista en un objeto para el autocomplete
                    let certificadosAutocomplete = [];
                    response.data.obj.forEach(element => {
                        certificadosAutocomplete.push({
                            text: element.tipo_certificado,
                            value: element
                        })
                    });

                    resolve(certificadosAutocomplete);
                }
                else {
                    reject({ response: response, mensaje: `No se pudo cargar los registros` });
                }
            })
            .catch(function (error) {
                reject(error);
            })
    })
}

export default getAllCertificadosBuscador;