import {updateActivacionClienteApi} from "@/model/usuario/usuario.repository";

const updateActivacionCliente = (cliente, codigo) => {
    return new Promise((resolve, reject) => {
        updateActivacionClienteApi(cliente, codigo)
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data);
                } else {
                    reject(response.data)
                }
            })
            .catch((error) => {
                reject(error)
            });
    })


}

export default updateActivacionCliente;