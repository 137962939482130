<template>
  <div>
    <v-row class="d-none d-sm-flex" style="color: black">
      <v-col cols="12" class="py-0">
        <h3 class="pl-2 info--text font-weight-medium pb-3">{{ grupo.area_agrupacion }}</h3>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="8" class="py-1">
        <p class="mb-0 font-weight-medium pl-2">Documentos</p>
      </v-col>
      <v-col cols="2" class="py-1">
        <p class="mb-0 font-weight-medium text-center">Cantidad</p>
      </v-col>

      <v-col cols="2" class="py-1">
        <p class="mb-0 font-weight-medium text-center">Precio</p>
      </v-col>
      <v-col cols="12" class="py-0 hoverable-2" v-for="(certificado,index) in grupo.certificados" :key="index">

        <v-divider></v-divider>
        <v-row>
          <v-col cols="8" class="py-2">
            <p class="mb-0 pl-2">
              <v-btn text icon color="red" small class="pa-0" @click="deleteItemCarro(certificado)">
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-btn>
              {{ certificado.tipo_certificado }}
            </p>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center py-2">
            <v-btn class="px-0" style="min-width: 25px"
                   small
                   elevation="0"
                   height="26"
                   tile
                   outlined
                   color="primary"
                   @click="reducir(certificado)"
            >
              <v-icon small dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <input type="text"
                   class="input-spin"
                   disabled
                   v-model="certificado.numero_copias"
            >
            <v-btn
                outlined
                class="px-0" style="min-width: 25px"
                small
                elevation="0"
                height="26"
                tile
                color="primary"
                @click="aumentar(certificado)"
            >
              <v-icon small dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center py-2">
            <span v-if="certificado.precio * certificado.numero_copias > 0">
              {{ certificado.precio * certificado.numero_copias | clp }}</span>
            <span v-if="certificado.precio * certificado.numero_copias === 0">Por cotizar</span>
          </v-col>
          <v-col cols="12" class="pt-0"
                 v-if="certificado.certificados_obligatorios != null && certificado.certificados_obligatorios.length > 0">

            <small class="pl-2">Incluye los siguientes certificados:
              <ul>
                <li :key="obl.tipo_certificado + '-' + obl.id_tipo_registro_obligatorio"
                    v-for="obl in certificado.certificados_obligatorios">
                  {{ obl.tipo_certificado_obligatorio }} - Valor: {{ obl.precio | clp }}
                </li>
              </ul>

            </small>

          </v-col>
        </v-row>

      </v-col>

    </v-row>
    <v-row class="d-flex d-sm-none">
      <v-col cols="12" class="py-0 px-0">
        <h3 class="pl-2 info--text font-weight-medium pb-3">{{ grupo.area_agrupacion }}</h3>
      </v-col>

      <v-col cols="12" class="py-0 px-0 " v-for="(certificado,index) in grupo.certificados" :key="index">

        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" class="py-2 text-center">
            <p class="mb-0 pl-2 pr-1 ">
              <v-btn text icon color="red" small class="pa-0" @click="deleteItemCarro(certificado)">
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-btn>
              {{ certificado.tipo_certificado }} <span v-if="certificado.vigencia != -1">({{ certificado.vigencia }} Años)</span>
            </p>
          </v-col>

          <v-col cols="6"><p class="font-weight-medium mb-2">Cantidad:</p></v-col>
          <v-col cols="6" class="text-right">
            <v-btn class="px-0" style="min-width: 25px"
                   small
                   elevation="0"
                   height="26"
                   tile
                   outlined
                   color="primary"
                   @click="reducir(certificado)"
            >
              <v-icon small dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <input type="text"
                   class="input-spin"
                   disabled
                   v-model="certificado.numero_copias"
            >
            <v-btn
                outlined
                class="px-0" style="min-width: 25px"
                small
                elevation="0"
                height="26"
                tile
                color="primary"
                @click="aumentar(certificado)"
            >
              <v-icon small dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="pb-0"><p class="font-weight-medium ">Total:</p></v-col>
          <v-col cols="6" class="text-right pb-0">
            <p v-if="certificado.precio * certificado.numero_copias > 0">
              {{ certificado.precio * certificado.numero_copias | clp }}</p>
            <p v-if="certificado.precio * certificado.numero_copias === 0" class="">Por cotizar</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "ItemCarroAlzamiento",
  props: {
    grupo: {
      default: null
    }
  },
  methods: {
    deleteItemCarro(item) {
      this.$emit("deleteItemCarro", item)
    },
    aumentar(item) {
      this.$emit("aumentarCantidad", item);
    },
    reducir(item) {
      this.$emit("reducirCantidad", item);
    }
  }
}
</script>

<style scoped>
.hoverable-2:hover {
  background-color: rgba(128, 128, 128, 0.08);
  transition: background-color 0.3s linear;
}
</style>
