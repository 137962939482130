<template>
  <div>
    <v-dialog max-width="700" v-model="ver">
      <v-card>
        <v-card-text class="pt-5">

          <p class="info--text text-center font-weight-medium">Documentos que necesitan corrección</p>
          <div class="d-sm-none">
            <div v-for="item in documentosPorCorregir"
                 :key="item.name + '-m'">
              <p class="font-weight-medium mb-0">
                {{ item.tipo_certificado }}</p>
              <p class="mb-0">{{ item.indice.toLowerCase() != "no aplica" ? "Índice: " + item.indice : "" }}</p>
              <p class="mb-0" style="font-size: 11px">{{ item.tipo_registro }}</p>

              <div class="text-center py-3 pr-3" v-if="item.por_corregir == ID_ESTADO_CORRECCION.POR_CORREGIR">
                <v-btn outlined small color="info" @click="seleccionarDocumento(item)">
                  <v-icon small left>mdi-pen</v-icon>
                  Corregir
                </v-btn>
              </div>
              <div class="text-center py-3" v-if="item.por_corregir != ID_ESTADO_CORRECCION.POR_CORREGIR">
                <span class="success--text">Corregido</span><v-icon color="success" right>mdi-check</v-icon>
              </div>

              <hr>
            </div>
          </div>
          <v-simple-table class="d-none d-sm-block">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Documento
                </th>
                <th>
                  Total
                </th>
                <th class="text-center" style="width: 3rem">
                  Acciones
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in documentosPorCorregir"
                  :key="item.name"
              >
                <td>
                  <p class="font-weight-medium mb-0">
                    {{ item.tipo_certificado }}{{
                      item.indice.toLowerCase() != "no aplica" ? " | " + item.indice : ""
                    }}</p>
                  <p class="mb-0" style="font-size: 11px">{{ item.tipo_registro }}</p>
                </td>
                <td>
                  {{ item.precio_pagado | clp }}
                </td>
                <td class="text-center pointer" v-if="item.por_corregir == ID_ESTADO_CORRECCION.POR_CORREGIR"
                    @click="seleccionarDocumento(item)">
                  <v-icon color="info">mdi-pen</v-icon>
                </td>
                <td class="text-center pointer" v-if="item.por_corregir != ID_ESTADO_CORRECCION.POR_CORREGIR">
                  <v-icon color="success">mdi-check</v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="text-center pt-5">
            <v-btn color="info" @click="hideDialog">
              <v-icon left size="20px">mdi-close</v-icon>
              Volver
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-documento :ver-dialog="modal.documento" @ocultarDialog="modal.documento = false" :caratula="caratula"
                     :documento="documentoSeleccionado"
                     @documentoCorregido="updateDocumentoSeleccionado($event)"></modal-documento>
  </div>

</template>

<script>
import {ID_ESTADO_CORRECCION} from "@/model/caratula/caratula.entity";
import ModalDocumento from "@/views/index/cliente/caratulas-por-corregir/ModalDocumento";

export default {
  name: "ModalCorregirDocumentos",
  components: {ModalDocumento},
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    caratula: {default: null}
  },
  data() {
    return {
      ver: false,
      documentosPorCorregir: [],
      modal: {
        documento: false
      },
      documentoSeleccionado: null,
      ID_ESTADO_CORRECCION: ID_ESTADO_CORRECCION
    }
  },

  watch: {
    verDialog() {
      if (this.verDialog) {
        this.getDocumentosPorCorregir(this.caratula.solicitud_cliente)
      }

      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    getDocumentosPorCorregir(solicitud) {
      console.log(solicitud)
      this.documentosPorCorregir = solicitud.documentosSolicitud.filter(d => {
        return d.por_corregir == ID_ESTADO_CORRECCION.POR_CORREGIR
      })
    },
    seleccionarDocumento(item) {
      this.documentoSeleccionado = item;
      this.modal.documento = true;
    },
    updateDocumentoSeleccionado(accion) {
      this.documentoSeleccionado.por_corregir = accion;
      this.modal.documento = false;
    }
  }
}
</script>

<style scoped>

</style>
