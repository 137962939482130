<template>
  <div>
    <v-form
        @submit.prevent="verificarDocumento(formDocumento.notarioSeleccionado, formDocumento.idDocumento)"
        autocomplete="off"
    >
      <v-row>
        <v-col
            class="pb-0"
        >
          <div
              class="d-flex flex-column flex-sm-row align-stretch align-sm-baseline"
          >
            <v-autocomplete
                v-model="formDocumento.notarioSeleccionado"
                :items="listaNotarios"
                :loading="cargandoListaNotarios ? 'secondary' : false"
                :search-input.sync="busquedaNotario"
                hide-no-data
                :disabled="verificandoDocumento || documentoVerificado || !camposHabilitados"
                label="Notario titular"
                required
                :autofocus="!$vuetify.breakpoint.mobile"
                @input="$v.formDocumento.notarioSeleccionado.$touch()"
                @blur="$v.formDocumento.notarioSeleccionado.$touch()"
                :error-messages="notarioSeleccionadoErrors"
            ></v-autocomplete>
            <v-text-field
                class="ml-0 ml-sm-6"
                v-model="formDocumento.idDocumento"
                label="Código de verificación"
                :disabled="verificandoDocumento || documentoVerificado || !camposHabilitados"
                required
                @input="$v.formDocumento.idDocumento.$touch()"
                @blur="$v.formDocumento.idDocumento.$touch()"
                :error-messages="idDocumentoErrors"
            ></v-text-field>
            <v-btn
                class="ml-0 ml-sm-3 mt-3 mb-3 mt-sm-0"
                type="submit"
                color="primary"
                small
                :disabled="!camposHabilitados"
                :loading="verificandoDocumento"
            >
              <v-icon class="mr-2">mdi-text-box-search-outline</v-icon>
              Verificar
            </v-btn>
          </div>
        </v-col>
      </v-row>

    </v-form>
    <v-fade-transition>
      <v-row class="my-0" v-if="verificacionDocumento != null && !verificandoDocumento">
        <v-col class="py-0">
          <v-alert
              class="mb-3"
              outlined
              border="left"
              :type="verificacionDocumento.documentoVerificado ? 'success' : 'error' "
          >
            <p v-if="verificacionDocumento.documentoVerificado" class="mb-0">
              La escritura ha sido cargada correctamente.
              Presione <a class="text--primary" target="_blank" :href="verificacionDocumento.mensaje">aquí</a> para ver
              el documento.
            </p>
            <p v-else class="mb-0">
              {{ verificacionDocumento.mensaje }}
            </p>
          </v-alert>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>

<script>
import notario from "@/model/notario/notario.entity";
import {required} from "vuelidate/lib/validators";

export default {
  props: {
    documentoVerificado: {
      default: false
    },
    camposHabilitados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //form validación documento
      formDocumento: {
        //inputs
        notarioSeleccionado: null,
        idDocumento: null,
      },
      //autocomplete
      cargandoListaNotarios: false,
      listaNotarios: [],
      busquedaNotario: null,
      //verificación documento
      verificandoDocumento: false,
      verificacionDocumento: null,
    };
  },
  validations: {
    formDocumento: {
      notarioSeleccionado: {required},
      idDocumento: {required},
    },
    formRepertorioPartes: {
      repertorio: {required},
      partes: {required}
    },
  },
  created() {
    //los notarios ya se cargaron
    if (this.listaNotarios.length > 0) {
      return;
    }

    //los notarios ya se están pidiendo
    if (this.cargandoListaNotarios) {
      return;
    }

    this.cargandoListaNotarios = true;
    notario
        .getLista()
        .then((response) => {
          this.listaNotarios = response;
        })
        .catch(() => {
        })
        .finally(() => {
          this.cargandoListaNotarios = false;
        });
  },
  watch: {
    busquedaNotario() {

    },
  },
  methods: {
    verificarDocumento(notarioSeleccionado, idDocumento) {

      this.$v.formDocumento.$touch();

      if (this.$v.formDocumento.$invalid) {
        return;
      }

      this.verificandoDocumento = true;
      notario
          .verificarDocumento(notarioSeleccionado.id, idDocumento)
          .then((response) => {
            this.verificacionDocumento = response;
            if (response.documentoVerificado) {
              this.$emit("e-documento-validado", this.formDocumento);
            }

          })
          .catch((error) => {
            this.verificacionDocumento = {
              documentoVerificado: false,
              mensaje: error.mensaje,
            }
            console.error(error);
          })
          .finally(() => {
            this.verificandoDocumento = false;
          });
    },
  },
  computed: {
    notarioSeleccionadoErrors: function () {
      const errors = [];
      if (!this.$v.formDocumento.notarioSeleccionado.$dirty) return errors;
      !this.$v.formDocumento.notarioSeleccionado.required && errors.push("Escriba y seleccione el nombre de un notario");
      return errors;
    },
    idDocumentoErrors: function () {
      const errors = [];
      if (!this.$v.formDocumento.idDocumento.$dirty) return errors;
      !this.$v.formDocumento.idDocumento.required && errors.push("Ingrese el código de verificación de su documento");
      return errors;
    },
  },
};
</script>