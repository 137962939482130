import qs from "qs";
import axios from "axios";
import store from "@/store";


export const crearSesionLocal = (usuario) => {
    store.commit("sesionIniciada", usuario);
}

export const updateNombreUsuarioLocal = (nombreUsuario) => {
    store.commit("updateNombreUsuario", nombreUsuario);
}

export const eliminarSesionLocal = () => {
    store.commit("sesionEliminada");
    store.commit("deleteCarro");
}

export const obtenerUsuarioLocal = () => {
    return store.state.usuario;
}

export const obtenerTerminosCondicionesActual = () =>{
    console.log("Testing getTerms: " , store.state.terminos_y_condiciones)
    return store.state.terminos_y_condiciones;
}

export const actualizarActivo = (estado) => {
    store.commit("updateActivacion", estado)
}

export const iniciarSesionFromApi = (rut, password, token, v2) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/v2/clientes?action=login",
            qs.stringify({
                rut_cliente: rut,
                contrasena: password,
                token: token,
                tt: v2
            })
        ).then(function (response) {

            axios.post("v2/clientes?action=verify-terms").then(
                (res) => {
                    store.commit("actualizarTerminos", res.data.obj);
                    resolve(response);
                }
            )

        }).catch(function (error) {
            reject(error);
        });
    })

};

export const registrarUsuarioFromApi = (nuevo_usuario, token) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/v2/clientes?action=registrar_cliente",
            qs.stringify({
                obj_registro: JSON.stringify(nuevo_usuario),
                token: token
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const getAllComunasDeRegionesFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/v2/clientes?action=search-comunas",
            qs.stringify({})
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })

}

export const cerrarSesionFromApi = (rutCliente) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/v2/clientes?action=logout",
            qs.stringify({
                rut_cliente: rutCliente
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const isLoggedLocal = () => {
    return (store.state.usuario.nombreUsuario != null);
}

export const isLoggedApi = () => {
    return new Promise((resolve, reject) => {
        axios.get("/v2/clientes?action=is-logged")
            .then(function (response) {
                resolve(response);
            }).catch(function (error) {
            reject(error);
        })
    })
}

export const obtenerDatosDeUsuarioLogeadoAPI = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            "/clientes?action=read-cliente"
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const obtenerMisComprasFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.get("solicitudesclientes/v2?action=read-mis-compras").then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}

export const updatearDatosDeUsuarioLogeadoAPI = (obj) => {
    return new Promise((resolve, reject) => {

        if (obj.direccionAdicional?.length > 0) {
            obj.direccion = obj.direccion + " [ " + obj.direccionAdicional + " ] ";
        }

        axios.post(
            "/clientes?action=update-cliente",
            qs.stringify({
                obj_cliente: JSON.stringify({
                    nombre_completo: obj.nombreCompleto,
                    direccion: obj.direccion,
                    telefono: obj.telefono,
                    correo: obj.correo,
                    id_comuna_FK: obj.comuna,

                })
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const updatearPasswordLogeadoAPI = (oldPass, newPass) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/clientes?action=update-pass-cliente",
            qs.stringify({
                contrasena: oldPass,
                nueva_contrasena: newPass
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const recuperarCuentaAPI = (rutCliente, correoCliente, token) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "clientes?action=correo-recuperar",
            qs.stringify({
                rut_cliente: rutCliente,
                correo: correoCliente,
                token: token
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const verificarUpdatePasswordRecuperacionCuentaAPI = (rutCliente, id) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "v2/clientes?action=recuperar-cuenta",
            qs.stringify({
                rut_cliente: rutCliente,
                id: id
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}
export const updatePasswordRecuperacionCuentaAPI = (rutCliente, password, fechaRegistro) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "clientes?action=update-pass",
            qs.stringify({
                rut_cliente: rutCliente,
                contrasena: password,
                fecha_registro: fechaRegistro,
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const updateActivacionClienteApi = (rut_cliente, codigo) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "v2/clientes?action=activacion-cliente",
            qs.stringify({
                rut_cliente: rut_cliente,
                id: codigo
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    })
}

export const updateVerificacionClienteSesionApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            "v2/clientes?action=activar-cliente-sesion"
        ).then(() => {
            resolve(true)
        }).catch(er => {
            reject(er)
        })
    })
}

export const updateTerminosYCondicionesUsuarioApi = () =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "v2/clientes?action=update-terminos-condiciones-cliente"
        ).then(response => {
            store.commit("actualizarTerminosUsuario", response.data.obj);
            store.commit("actualizarTerminos", response.data.obj);

            resolve(response);
        }).catch(er => {
            reject(er)
        })
    })
}
