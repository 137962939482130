import registrosEntity from "@/model/registros/registros.entity";
import {obtenerCarroActual} from "@/model/carro/carro.repository";


const getCarroActualCategorizado = () => {

    let carro = [];
    let lista_carro = obtenerCarroActual();
    let lista_tipos_registros = [];
    console.log("carro actuaaal: ", lista_carro)
    lista_carro.map(item => {
        let id_tipo_registro = item.id_tipo_registro_FK;
        if (lista_tipos_registros.indexOf(id_tipo_registro) === -1) {
            lista_tipos_registros.push(id_tipo_registro);
        }
    })

    lista_tipos_registros.map(id => {
        let subtotal = 0;
        let certificados_grupo = lista_carro.filter(f => {
            return f.id_tipo_registro_FK === id;
        })

        let lista_areas = [];

        certificados_grupo.map(cert => {
            if (lista_areas.indexOf(cert.if_area_agrupacion) !== -1) {
                lista_areas.push(cert.if_area_agrupacion);
            }
        })

        let id_agrupaciones = [];
        let grupos_documentos = []

        certificados_grupo.map(grupo => {
            subtotal += grupo.precio * grupo.numero_copias;
            if (id_agrupaciones.indexOf(grupo.id_area_agrupacion) === -1) {
                id_agrupaciones.push(grupo.id_area_agrupacion)
            }
        })

        let certificados_obligatorios = registrosEntity.getCertificadosObligatoriosFromListaCertificados(certificados_grupo);

        certificados_obligatorios.map(ob => {
            subtotal += ob.precio;
        })

        id_agrupaciones.map(id => {
            let grupo = certificados_grupo.filter(f => {
                return f.id_area_agrupacion === id;
            })

            let area = {
                id_area_agrupacion: grupo[0].id_area_agrupacion,
                area_agrupacion: grupo[0].area_agrupacion,
                subtotal: subtotal,
                certificados: grupo

            }

            grupos_documentos.push(area);

        })

        let grupo = {
            tipo_registro: certificados_grupo[0].tipo_registro,
            id_tipo_registro: certificados_grupo[0].id_tipo_registro_FK,
            subtotal: subtotal,
            grupos_documentos: grupos_documentos
        }

        if (grupo.id_tipo_registro === registrosEntity.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION) {
            grupo.alzamiento = certificados_grupo[0].alzamiento
        }

        if (grupo.id_tipo_registro === registrosEntity.ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS) {
            grupo.datos_adicionales = certificados_grupo[0].datos_adicionales;
        }


        console.log("el grupo", grupo)

        carro.push(grupo);
    })

    return carro;
}

export default getCarroActualCategorizado;
