<template>
  <v-dialog v-model="mostrar" max-width="500">
    <v-card>
      <v-card-title>
        Ingresar a su cuenta
      </v-card-title>
      <v-card-text class="pb-0">
        <login @e-sesion-iniciada="handleSesionIniciada()" @e-click-opcion="handleClickOpcion()"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Login from "@/components/Login/Login";
import carro from "@/model/carro/carro.entity";
import {EventBus} from "@/main";

export default {
  name: "ModalLogin",
  components: {Login},
  data(){
    return{
      mostrar: false,
    }
  },
  created(){
    EventBus.$on("modal-login",(abrir)=>{
      this.mostrar = abrir;
    })
  },
  methods: {
    handleSesionIniciada() {

      this.mostrar = false;
      EventBus.$emit("login",true);

      carro.validarCarros().then(response => {
        switch (response) {
          case carro.CODE.CONSERVAR_CARRO_ACTUAL:

            carro.reemplazarCarro();
            EventBus.$emit("dialog-sesion-iniciada",true);
            break;

          case carro.CODE.CONSERVAR_CARRO_REMOTO:

            carro.obtenerCarro()
            EventBus.$emit("dialog-sesion-iniciada",true);
            break;

          case carro.CODE.ELEGIR_CARRO:
            EventBus.$emit("modal-seleccionar-carro",true);
            break;
        }
      })


    },
    handleClickOpcion() {
      this.mostrar = false;
    },
  },
}
</script>

<style scoped>

</style>
