<template>
  <v-list class="pt-0" dense>
    <template
        v-for="(documento,index) in documentos_anexos"
    >
      <v-list-item
          :key="documento.nombre"
          :href="documento.url"
          target="_blank"
      >
        <span class="blue--text">
          <v-icon
              size="16"
              color="blue"
              left
          >
            mdi-open-in-new
          </v-icon>
          {{ documento.nombreOrigen }}
        </span>
      </v-list-item>
      <v-divider
          v-if="index < documentos_anexos.length - 1"
          :key="index"
      />
    </template>
  </v-list>
</template>

<script>
export default {
  name: "ListaDocumentosAnexos",
  props: {
    documentos_anexos: {default: []}
  }
}
</script>

<style scoped>

</style>