<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <v-row v-if="!generandoSolicitudes">
          <v-col cols="12">
            <modal-pago-medios-pago @updateTipoPago="updateTipoPago($event)"
                                    :precio_total_carro="subtotal"></modal-pago-medios-pago>
            <v-divider></v-divider>
            <div v-if="hasValija">
              <modal-pago-forma-retiro @updateCorreoValija="updateCorreoValija($event)"></modal-pago-forma-retiro>
              <v-divider></v-divider>
            </div>
            <div v-if="hasPlano">
              <modal-pago-forma-retiro-planos :comunas="comunas"
                                              @updateTipoRetiro="updateTipoRetiro($event)"
                                              @tipoRetiroSeleccionado="setTipoRetiro($event)"></modal-pago-forma-retiro-planos>
              <v-divider></v-divider>
            </div>
            <modal-pago-datos-boleta :comunas="comunas"
                                     @updateClienteBoleta="updateClienteBoleta($event)"></modal-pago-datos-boleta>
            <v-divider></v-divider>
          </v-col>


          <v-col cols="6" class="pl-7 pt-0">
            <p class="font-sm mb-0">Subtotal:
              <span v-if="subtotal > 0">{{ subtotal | clp }}</span>
              <span v-if="subtotal == 0">Por cotizar</span>
            </p>
            <p class="font-sm mb-0" v-if="tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS">Envío: $3.500</p>
            <p class="font-sm mb-0" v-if="dataFormulario.id_tipo_pago == ID_TIPO_PAGO.KHIPU || dataFormulario.id_tipo_pago == ID_TIPO_PAGO.KHIPU_WEB">Cargos operacionales khipu: {{ comision | clp }}</p>
          </v-col>
          <v-col cols="6" class="d-flex justify-end pr-7 pt-0">
            <p class="font-sm mb-0 mt-auto font-weight-bold" style="font-size: 16px">Total:
              <span v-if="precio_total_carro == 0">Por cotizar</span>
              <span v-if="precio_total_carro > 0">{{precio_total_carro | clp}}</span>
            </p>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="generandoSolicitudes">
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>
            <v-btn color="success" outlined class="px-10" :disabled="!valido" @click="enviarFormulario()"
                   :loading="generandoSolicitudes">
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
            <p class="text-center font-md error--text mb-0" v-if="errorFormulario != null">{{ errorFormulario }}</p>
          </v-col>
        </v-row>
        <v-fade-transition>
          <v-row v-show="generandoSolicitudes">

            <v-col cols="12" class="px-15" v-if="dataFormulario.id_tipo_pago != ID_TIPO_PAGO.POR_COTIZAR">
              <div class="text-center pt-2">
                <img src="../../assets/img/logo_khipu.svg" width="200"/></div>
              <p class="text-center pb-3">Conectando con KHIPU, espere un momento...</p>
              <v-progress-linear
                  color="purple lighten-2"
                  buffer-value="0"
                  reverse
                  stream
              ></v-progress-linear>
            </v-col>

            <v-col cols="12" class="px-15 pt-5" v-if="dataFormulario.id_tipo_pago == ID_TIPO_PAGO.POR_COTIZAR">
              <p class="text-center">
                Estamos generando su solicitud, por favor, espere un momento...
              </p>
              <v-progress-linear
                  color="purple lighten-2"
                  buffer-value="0"
                  reverse
                  stream
              ></v-progress-linear>
            </v-col>

          </v-row>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalPagoMediosPago from "@/components/Carro/ModalPagoMediosPago";
import ModalPagoFormaRetiro from "@/components/Carro/ModalPagoFormaRetiro";
import ModalPagoFormaRetiroPlanos from "@/components/Carro/ModalPagoFormaRetiroPlanos";
import ModalPagoDatosBoleta from "@/components/Carro/ModalPagoDatosBoleta";
import usuarioEntity from "@/model/usuario/usuario.entity";
import carroEntity, {ID_TIPO_PAGO, ID_TIPO_RETIRO} from "../../model/carro/carro.entity";

export default {
  name: "ModalPago",
  components: {ModalPagoDatosBoleta, ModalPagoFormaRetiroPlanos, ModalPagoFormaRetiro, ModalPagoMediosPago},
  props: {
    verDialog: {default: false},
    time: {default: null},
    subtotal: {default: 0},
    listaObservaciones: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ver: false,
      comunas: [],
      isLoading: false,
      formularioValido: false,
      dataFormulario: {},
      valido: false,
      lista_carro: carroEntity.obtenerCarroActualCategorizado,
      errorFormulario: null,
      precio_total_carro: 0,
      comision: 0,
      tipo_retiro: null,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      generandoSolicitudes: false,
      ID_TIPO_PAGO:ID_TIPO_PAGO
    }
  },

  created() {

    this.isLoading = true
    usuarioEntity.getAllComunasDeRegiones(this.search).then(response => {

      this.comunas = response;

      this.comunas.map(item => {
        item.descripcion = item.comuna + ", " + item.region
      })
    }).catch(error => {
      console.log("Error: ", error)
    }).finally(() => (this.isLoading = false))
  },
  computed: {
    hasPlano() {
      return carroEntity.isFormPlanos()
    },
    hasValija() {
      return carroEntity.isFormValija()
    },

  },
  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    },
    tipo_retiro() {
      this.getPrecioTotal();
    }

  },

  methods: {
    setTipoRetiro(tipo_retiro) {
      this.tipo_retiro = tipo_retiro;
    },
    isFormularioValido() {
      console.log("llega a evaluar formulario: ", this.dataFormulario)
      if (this.dataFormulario == null) {
        return false;
      }
      console.log("Mi id tipo pago: ", this.dataFormulario.id_tipo_pago)
      if (this.dataFormulario.id_tipo_pago == null) {
        return false
      }
      if (this.hasPlano) {
        if (this.dataFormulario.retiro_planos == null) {
          return false
        }
      }

      if (this.hasValija) {
        if (this.dataFormulario.retiro_valija == null) {
          return false
        }
      }

      if (this.dataFormulario.cliente_boleta == null) {
        return false
      }

      return true
    },
    getPrecioTotal() {
      let tot = this.subtotal;
      if (this.tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS) {
        tot = this.subtotal + 3500;
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
        tot = parseInt(tot * carroEntity.COMISION.KHIPU)
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
        tot = parseInt(tot * carroEntity.COMISION.KHIPU_WEB)
      }

      this.precio_total_carro = tot
      this.getComision();
    },
    getComision() {

      let tot = this.subtotal;
      if (this.tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS) {
        tot = this.subtotal + 3500;
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
        this.comision = parseInt(tot * (carroEntity.COMISION.KHIPU - 1))
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
        this.comision = parseInt(tot * (carroEntity.COMISION.KHIPU_WEB - 1))
      }

    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTipoPago(id_tipo_pago) {
      this.dataFormulario.id_tipo_pago = id_tipo_pago;
      this.valido = this.isFormularioValido();
      this.getPrecioTotal();
    },
    updateTipoRetiro(obj_direccion) {
      this.dataFormulario.retiro_planos = obj_direccion;
      this.valido = this.isFormularioValido();
      this.getPrecioTotal();
    },
    updateClienteBoleta(obj_cliente_boleta) {
      this.dataFormulario.cliente_boleta = obj_cliente_boleta;
      this.valido = this.isFormularioValido();
    },
    updateCorreoValija(obj_valija) {
      this.dataFormulario.retiro_valija = obj_valija;
      this.valido = this.isFormularioValido();
    },

    enviarFormulario() {
      this.generandoSolicitudes = true;
      this.$emit("generandoCarro", null);
      this.dataFormulario.observaciones_solicitudes = this.listaObservaciones;


      carroEntity.validarFormularioPago(this.dataFormulario).then(resp => {
        console.log("response: ", resp)

        if (resp.status) {
          this.$emit("updateCarro", resp.obj);

        } else {
          this.errorFormulario = "Ocurrió un error inesperado..."
        }
      }).catch(error => {
        this.errorFormulario = error.mensaje;
      }).finally(() => {
        /*this.generandoSolicitudes = false;*/
      });
    }
  }
}
</script>

<style scoped>

</style>
