import {cerrarSesionFromApi, eliminarSesionLocal} from "@/model/usuario/usuario.repository";
import usuarioEntity from "@/model/usuario/usuario.entity";

const cerrarSesion = () => {
    return new Promise((resolve, reject) => {
        cerrarSesionFromApi(usuarioEntity.getRutUsuario())
            .then((response) => {
                if (response.data.status) {
                    resolve("Sesión finalizada correctamente.");
                    eliminarSesionLocal();
                } else {
                    reject({response: response, mensaje: "No se pudo cerrar la sesión."});
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export default cerrarSesion;