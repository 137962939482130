<template>
  <div>
    <v-card
        v-if="!$vuetify.breakpoint.smAndDown"
        class="mx-auto"
        width="256"
        tile
    >
      <div>
        <v-list>
          <v-list-item>
            <v-list-item-title class="title">
              Índices y Planos
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
          >
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-card>

    <div v-else>
      <NativeSelect
          label="Búsqueda de índices"
          outlined
          v-model="selectedItem"
          :items="items"
          name="country"
          autocomplete="country"
      />
    </div>
  </div>
</template>

<script>
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import {ID_GRUPO} from "@/model/registros/registros.entity";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import NativeSelect from "@/components/NativeSelect/NativeSelect";

export default {
  name: "IndicesNav",
  components: {NativeSelect},
  data() {
    return {
      selectedItem: 0,
      items: [
        {
          text: 'Registro de Propiedad',
          icon: 'mdi-home',
          id_tipo_registro: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
          registro: "PRP",
          menus: [1],
          default_agrupacion: ID_GRUPO.COPIAS_Y_CERTIFICADOS,
          id_tipo_formulario: ID_TIPO_FORMULARIO.FNA
        },

        // {
        //   text: "Registro de Hipotecas",
        //   icon: 'mdi-bank',
        //   id_tipo_registro: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
        //   registro: "HIP",
        //   menus: [0,1],
        //   default_agrupacion: ID_GRUPO.HIP_PROPIEDAD,
        //   id_tipo_formulario: ID_TIPO_FORMULARIO.FNA
        // },
        // {
        //   text: 'Registro de Prohibiciones',
        //   icon: 'mdi-cancel',
        //   id_tipo_registro: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
        //   registro: "PRH",
        //   menus: [0,1],
        //   default_agrupacion: ID_GRUPO.PRH_PROPIEDAD,
        //   id_tipo_formulario: ID_TIPO_FORMULARIO.FNA
        // },
        {
          text: 'Registro de Comercio',
          icon: 'mdi-store',
          id_tipo_registro: ID_TIPO_REGISTRO.COMERCIO,
          menus: [1],
          default_agrupacion: ID_GRUPO.COPIAS_Y_CERTIFICADOS,
          id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
          registro: "COM"
        },
        // {
        //   text: "Registro de Aguas",
        //   icon: 'mdi-water',
        //   id_tipo_registro: ID_TIPO_REGISTRO.AGUAS,
        //   menus: [0],
        //   default_agrupacion: ID_GRUPO.COPIAS_Y_CERTIFICADOS,
        //   id_tipo_formulario: ID_TIPO_FORMULARIO.FNA
        // },
        // {
        //   text: "Planos Propiedad",
        //   icon: "mdi-map",
        //   id_tipo_registro: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
        //   menus: [3],
        //   default_agrupacion: ID_GRUPO.PLANOS_PROPIEDAD,
        //   id_tipo_formulario: ID_TIPO_FORMULARIO.PLANOS
        // },
        // {
        //   text: "Planos Ley 19.537",
        //   icon: "mdi-office-building-marker",
        //   id_tipo_registro: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
        //   menus: [3],
        //   default_agrupacion: ID_GRUPO.PLANOS_LEY,
        //   id_tipo_formulario: ID_TIPO_FORMULARIO.PLANOS
        // }
      ],
    }
  },
  created() {
    this.$emit("itemSeleccionado", this.items[this.selectedItem])
  },
  watch: {
    selectedItem() {
      this.$emit("itemSeleccionado", this.items[this.selectedItem])
    }
  }
}
</script>

<style scoped>

</style>
