import {updatearDatosDeUsuarioLogeadoAPI, updateNombreUsuarioLocal} from "@/model/usuario/usuario.repository";

const updateUsuario = (obj) => {
    return new Promise((resolve, reject) => {
        updatearDatosDeUsuarioLogeadoAPI(obj)
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data.obj);
                    updateNombreUsuarioLocal(obj.nombreCompleto);
                } else {
                    reject({response: response, mensaje: "No se pudieron actualizar los datos del usuario."})
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export default updateUsuario;