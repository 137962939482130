export const toDateFilter = (val) => {
    if (!val) {
        return "-"
    }

    if (isNaN(val)) {
        return "Error en la fecha"
    }
    let d = new Date(parseInt(val));

    return (d.getDate() > 9 ? d.getDate() : "0" + d.getDate())
        + "/"
        + (d.getMonth() > 9 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1))
        + "/"
        + d.getFullYear()

}