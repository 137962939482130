import axios from "axios";
import qs from "qs";

export const obtenerNotariosFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            "/solicitudesclientes?action=obtener-notarias"
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const verificarDocumentoFromApi = (idNotario, idDocumento) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/solicitudesclientes?action=validar-documento-ext",
            qs.stringify({
                id_notario: idNotario,
                id_documento: idDocumento,
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}