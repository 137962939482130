import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#168ccc',
            secondary: '#38B9FF',
            accent: '#EB901C',
            error: '#EB3440',
          },
        },
      },
});
