import {getAllComunasDeRegionesFromApi} from "@/model/usuario/usuario.repository";

const getAllComunasDeRegiones = () => {
    return new Promise((resolve, reject) => {
        getAllComunasDeRegionesFromApi().then((response) => {
            if (response.data.status) {

                let data = response.data.obj;

                data.map(d => {

                    let d_split = d.comuna.split(" ");

                    let new_d = "";

                    d_split.map(sp=>{
                       new_d += sp.charAt(0) + sp.slice(1, sp.length).toLowerCase();
                       new_d += " ";
                    })

                    d.comuna = new_d.trim();
                    // d.comuna = d.comuna.toLowerCase();
                    // d.comuna = d.comuna.charAt(0).toUpperCase() + d.comuna.slice(1, d.comuna.length);
                })
                resolve(data);
            } else {
                reject({response: response, mensaje: `No se pudo buscar una comuna`})
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

export default getAllComunasDeRegiones;
