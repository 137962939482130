import { getCategoriasRegistrosTramitesEnLineaFromApi } from "../registros.repository"

const getCategoriasRegistros = () => {


    return new Promise((resolve, reject) => {
        getCategoriasRegistrosTramitesEnLineaFromApi().then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            }
            else {
                reject({ response: response, mensaje: `No se pudo cargar los tipos registros` });
            }
        }).catch(function (error) {
            reject(error);
        });
    })


}

export default getCategoriasRegistros;