<template>
  <v-breadcrumbs
      class="text-capitalize pa-2 grey lighten-3"
      :items="itemsBreadcrumb"
  >
    <template v-slot:divider>
      <v-icon size="25" color="primary">mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "TheBreadcrumb",
  props: {
    inCertificados: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemsBreadcrumb: function () {
      return this.createItemsBreadcrumb()
    }
  },
  methods: {
    createItemsBreadcrumb() {
      let pathArray = this.$route.path.split("/");

      //quitamos el primero
      pathArray.shift();

      let breadcrumbs = [];
      //para cuando no está en get-certificados
        breadcrumbs = pathArray.reduce(
            (breadcrumbArray, path, idx) => {
              breadcrumbArray.push({
                path: path,
                to: undefined,
                disabled: false,
                text: path.substr(path.lastIndexOf("/") + 1).replaceAll("-", " "),
                exact: true,
              });
              //se necesita agregar el "to" una vez ya se añadió el elemento anterior, por eso idx + 1
              breadcrumbArray[idx + 1].to = this.obtenerPathRecursivo(breadcrumbArray, breadcrumbArray.length - 1);

              //si el breadcrumb está en get-certificados
              if(this.inCertificados)
              {
                //sólo el nivel 2 se desactiva
                if(idx === 2){
                  breadcrumbArray[idx + 1].to = "/";
                  breadcrumbArray[idx + 1].disabled = true;
                }

              }

              return breadcrumbArray;
            },
            [
              //menú inicial
              {
                path: "/",
                to: "/",
                text: "Inicio",
                exact: true,
              },
            ]
        );

      return breadcrumbs;
    },
    obtenerPathRecursivo(breadcrumbArray, idx) {

      let pathCreator = "";
      let i = idx;

      while(i > 0)
      {
        pathCreator = "/" + breadcrumbArray[i].path + pathCreator;
        i--;
      }

      return pathCreator;

    }
  }
};
</script>