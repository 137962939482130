<template>
  <div>
    <v-row v-if="itemNav != null">
      <v-col cols="12" class="text-center pt-0 pl-md-5" v-if="itemNav != null">

        <v-tabs centered center-active v-model="tipo_formulario">
          <v-tab v-if="itemNav.menus.includes(0)">Foja, Número y Año</v-tab>
          <v-tab v-if="itemNav.menus.includes(1)">Nombre y apellidos</v-tab>
          <v-tab v-if="itemNav.menus.includes(3)">Búsqueda de planos</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tipo_formulario">
          <v-tab-item

              key="0"
              v-if="itemNav.menus.includes(0)"
          >
            <v-card-text class="px-0">
              <tab-f-n-a :key="itemNav.registro"
                         @seleccionarItem="abrirModalAgregar($event)"
                         :id_tipo_registro="itemNav.id_tipo_registro"
                         :item-nav="itemNav"

              ></tab-f-n-a>
            </v-card-text>

          </v-tab-item>
          <v-tab-item

              key="1"
              v-if="itemNav.menus.includes(1)"
          >

            <v-card-text>
              <tab-apellido @seleccionarItem="abrirModalAgregar($event)"
                            :item-nav="itemNav"
              ></tab-apellido>
            </v-card-text>

          </v-tab-item>

          <v-tab-item
              key="2"
              v-if="itemNav.menus.includes(3)"
              transition="fade" :reverse-transition="false"
          >

            <v-card-text>
              <tab-planos :itemNav="itemNav" @seleccionarItem="abrirModalAgregar($event)"></tab-planos>
            </v-card-text>

          </v-tab-item>
        </v-tabs-items>


        <!--  <v-btn-toggle
              v-model="tipo_formulario"
              borderless
              mandatory

          >
            <v-btn value="0" class="btn-tabs px-5" v-if="itemNav.menus.includes(0)">
              <v-icon left>
                mdi-folder-text-outline
              </v-icon>
              <span>Foja, Número y Año</span>
            </v-btn>

            <v-btn value="1" class="btn-tabs px-5 py-1" v-if="itemNav.menus.includes(1)">
              <v-icon left>
                mdi-account
              </v-icon>
              <span>Nombre y apellidos </span>
            </v-btn>

            <v-btn value="3" class="btn-tabs px-5 py-1" v-if="itemNav.menus.includes(3)">
              <v-icon left>
                mdi-map
              </v-icon>
              <span>Búsqueda de planos </span>
            </v-btn>

          </v-btn-toggle>
          <v-divider class="mt-3"></v-divider>
          -->
      </v-col>


      <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '0'">

      </v-col>

      <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '1'">

      </v-col>
      <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '3'">

      </v-col>

    </v-row>
    <modal-agregar-documento :item="item_modal" :itemNav="itemNav" :lista_registros="lista_registros"
                             :ver-dialog="verModalAgregarDocumento"
                             @ocultarDialog="verModalAgregarDocumento = false"
                             @verModalDocumentosAgregados="verModalDocumentosAgregados($event)"
    ></modal-agregar-documento>

    <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                              :documentosAgregados="modalAgregar.lista_agregados"
                              :documentosRepetidos="modalAgregar.documentos_repetidos"
                              :documentosEnLimite="modalAgregar.limite_carro"
                              @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>
  </div>
</template>

<script>
import TabFNA from "@/views/index/indices/TabFNA";
import TabApellido from "@/views/index/indices/TabApellido";
import ModalAgregarDocumento from "@/views/index/indices/ModalAgregarDocumento";
import registrosEntity from "@/model/registros/registros.entity";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";
import TabPlanos from "@/views/index/indices/TabPlanos";

export default {
  name: "IndicesViewTipo",
  components: {TabPlanos, ModalDocumentoAgregado, ModalAgregarDocumento, TabApellido, TabFNA},
  props: {
    itemNav: {default: null}
  },
  data() {
    return {
      tipo_formulario: "0",
      verModalAgregarDocumento: false,
      lista_registros: [],
      item_modal: null,
      modalAgregar: {
        verModalDocumentoAgregado: false,
        lista_agregados: [],
        documentos_repetidos: [],
        limite_carro: []
      }
    }
  },
  created() {

  },
  methods: {
    abrirModalAgregar(item) {

      this.url_categoria = this.$route.params.tipo_registro;

      this.cargandoCategorias = true;

      registrosEntity.getTiposRegistrosFromCategoria(this.url_categoria == null ? "propiedad" : this.url_categoria)
          .then((response) => {
            console.log("respuesta que trae de back: " , response)
            this.lista_registros = response;
            this.item_modal = item;
            this.verModalAgregarDocumento = true;
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.cargandoCategorias = false;
          })

    },
    verModalDocumentosAgregados(data) {
      this.modalAgregar.verModalDocumentoAgregado = true;
      this.modalAgregar.lista_agregados = data.lista_agregados;
      this.modalAgregar.limite_carro = data.limite_carro;
      this.modalAgregar.documentos_repetidos = data.documentos_repetidos;
    }
  },
  watch: {
    getRegistro() {
      this.tipo_formulario = this.itemNav.menus[0].toString();
    }

  },
  computed: {
    getRegistro() {
      if (this.itemNav != null) {
        return this.itemNav.registro;
      } else {
        return 0;
      }

    }
  }
}
</script>

<style scoped>
.btn-tabs {
  height: 36px !important;
}
</style>
