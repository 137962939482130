import {getEstadoCaratulaFromApi} from "../caratula.repository";

const getEstadoCaratula = (numeroCaratula) => {

    return new Promise((resolve, reject) => {
       getEstadoCaratulaFromApi(numeroCaratula).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            }
            else {
                reject({ response: response, mensaje: `La carátula ingresada no se encuentra disponible en nuestros registros. Vuelva a intentarlo en los próximos minutos.` });
            }
        }).catch(function (error) {
            reject(error);
        });
    })


}

export default getEstadoCaratula;