const getCertificadosObligatoriosFromListaCertificados = (items) => {
    let certificados_obligatorios = [];

    items.map(cert => {
        if (cert.certificados_obligatorios == null) {
            cert.certificados_obligatorios = [];
        }

        if (cert.numero_copias > 0) {
            cert.certificados_obligatorios.map(ob => {
                let search = certificados_obligatorios.find(f => {
                    return f.id_tipo_registro_obligatorio == ob.id_tipo_registro_obligatorio && f.id_tipo_certificado_obligatorio == ob.id_tipo_certificado_obligatorio && ob.unico_en_solicitud == true;
                })
                if (search == null) {
                    certificados_obligatorios.push(ob);
                }
            })
        }
    })

    return certificados_obligatorios;
}

export default getCertificadosObligatoriosFromListaCertificados;