import usuarioEntity from "@/model/usuario/usuario.entity";

import Vue from 'vue'
import VueRouter from 'vue-router'

const IndexView = () => import(/* webpackChunkName: "index-chunk" */'@/views/index/index/IndexView.vue');

import TramitesEnLineaView from '@/views/index/tramites-en-linea/TramitesEnLineaView';
import TramitesEnLineaViewMenu from '@/views/index/tramites-en-linea/TramitesEnLineaViewMenu';
import TramitesEnLineaViewMenuRegistro from '@/views/index/tramites-en-linea/TramitesEnLineaViewMenuRegistro';
import TramitesEnLineaViewMenuCertificado from "@/views/index/tramites-en-linea/TramitesEnLineaViewMenuCertificado";

import ConsultasEnLineaView from "@/views/index/consultas-en-linea/ConsultasEnLineaView.vue";
import ConsultasEnLineaViewMenu from "@/views/index/consultas-en-linea/ConsultasEnLineaViewMenu.vue";
import ConsultasEnLineaViewCaratulas from "@/views/index/consultas-en-linea/ConsultasEnLineaViewCaratulas.vue";
import ConsultasEnLineaViewVerificarDocumento from "@/views/index/consultas-en-linea/ConsultasEnLineaViewVerificarDocumento";
import ConsultasEnLineaViewEntregaEnLinea from "@/views/index/consultas-en-linea/ConsultasEnLineaViewEntregaEnLinea";

const RegistroView = () => import('@/views/index/registro/RegistroView');

import CarroView from "@/views/index/carro/CarroView";

/*Cliente chunk*/
const ClienteView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/ClienteView");
const ClienteViewMenu = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/ClienteViewMenu");
const ClienteViewEditar = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/ClienteViewEditar");
const ComprasView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/compras/ComprasView");
const RecuperarCuentaView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/RecuperarCuentaView");
const CambioPasswordRecuperarView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/CambioPasswordRecuperarView");
const MisCaratulasView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/caratulas/MisCaratulasView");
const DevolucionView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/devolucion/DevolucionView");


/*Informacion Chunk*/
const InformacionView = () => import("@/views/index/informacion/InformacionView");
const InformacionViewMenu = () => import("@/views/index/informacion/InformacionViewMenu");
const InformacionViewAcercaDe = () => import("@/views/index/informacion/InformacionViewAcercaDe");
const InformacionViewTerminosYCondiciones = () => import("@/views/index/informacion/InformacionViewTerminosYCondiciones");
// const InformacionViewTrabajaConNosotros = () => import("@/views/index/informacion/InformacionViewTrabajaConNosotros");
const InformacionViewContacto = () => import("@/views/index/informacion/InformacionViewContacto");
const InformacionViewPreguntasFrecuentes = () => import("@/views/index/informacion/InformacionViewPreguntasFrecuentes");

import IndicesView from "@/views/index/indices/IndicesView";
import IndicesVisorPDF from "@/views/index/indices/IndicesVisorPDF";

import VidTutorialesView from "@/views/index/videotutoriales/VidTutorialesView";
import CaratulasPorCorregirView from "@/views/index/cliente/caratulas-por-corregir/CaratulasPorCorregirView";

const PaginaNoEncontradaView = () => import("@/views/index/pagina-no-encontrada/PaginaNoEncontradaView");

const BoletasView = () => import("@/views/index/boletas/BoletasView");
const ActivacionView = () => import("@/views/index/activacion/ActivacionView");
const VerificacionPagoView = () => import("@/views/index/verificacion-pago/VerificacionPagoView");

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: IndexView
    },
    {
        path: '/consultas',
        component: ConsultasEnLineaView,
        name: "Consulta",
        children: [
            {
                path: '/',
                name: 'ConsultasMenu',
                component: ConsultasEnLineaViewMenu,
            },
            {
                path: 'caratulas/:ncaratula(\\d+)?',
                name: 'ConsultasCaratulas',
                component: ConsultasEnLineaViewCaratulas,
            },
            {
                path: 'entrega-en-linea/:ncaratula(\\d+)?',
                name: 'EntregaEnLinea',
                component: ConsultasEnLineaViewEntregaEnLinea,
            },
            {
                path: 'verificar-documento/:ndocumento(\\d+)?',
                name: 'ConsultasVerificarDocumento',
                component: ConsultasEnLineaViewVerificarDocumento,
            }
        ]
    },
    {
        path: '/tramites',
        component: TramitesEnLineaView,
        name: "Tramites",
        children: [
            {
                path: '/',
                name: 'TramitesMenu',
                component: TramitesEnLineaViewMenu,
            },
            {
                path: ':categoriaRegistro',
                name: 'CategoriasRegistros',
                component: TramitesEnLineaViewMenuRegistro,

            },
            {
                path: ":categoriaRegistro/:tipoRegistro/:tipoCertificado",
                name: "TipoCertificado",
                component: TramitesEnLineaViewMenuCertificado
            }
        ]
    },
    {
        path: '/registro',
        name: 'Registro',
        component: RegistroView
    },
    {
        path: '/informacion',
        component: InformacionView,
        name: "Informacion",
        children: [
            {
                path: '/',
                name: 'InformacionMenu',
                component: InformacionViewMenu,
            },
            {
                path: 'acerca-de',
                name: 'InformacionAcercaDe',
                component: InformacionViewAcercaDe,
            },
            {
                path: 'tyc',
                name: 'InformacionTerminosYCondiciones',
                component: InformacionViewTerminosYCondiciones,
            },
            // {
            //     path: 'trabaja-con-nosotros',
            //     name: 'InformacionTrabajaConNosotros',
            //     component: InformacionViewTrabajaConNosotros,
            // },
            {
                path: 'contacto',
                name: 'InformacionContacto',
                component: InformacionViewContacto,
            },
            {
                path: 'faq',
                name: 'InformacionPreguntasFrecuentes',
                component: InformacionViewPreguntasFrecuentes,
            }
        ]
    },
    {
        path: '/carro',
        component: CarroView,
        name: "Carro",
    },
    {
        path: "/cliente",
        component: ClienteView,
        name: "Cliente",
        beforeEnter: (to, from, next) => {

            usuarioEntity.isSessionActive().then((active) => {
                if (active) {
                    next();
                } else {
                    next({path: '/'});
                }
            });

        },
        children: [
            {
                path: "/",
                component: ClienteViewMenu,
                name: "ClienteMenu",
                beforeEnter: (to, from, next) => {
                    comprobarLogin(to, from, next);
                },
            },
            {
                path: "editar",
                component: ClienteViewEditar,
                name: "ClienteEditar",
                beforeEnter: (to, from, next) => {
                    comprobarLogin(to, from, next);
                },
            },
            {
                path: "compras",
                component: ComprasView,
                name: "Compras",
                beforeEnter: (to, from, next) => {
                    comprobarLogin(to, from, next);
                },
            },
            {
                path: "caratulas",
                component: MisCaratulasView,
                name: "Caratulas",
                children: [
                    {
                        path: ":numero_caratula",
                        component: MisCaratulasView
                    }
                ],

            },
            {
                path: "caratulas-por-corregir",
                component: CaratulasPorCorregirView,
                name: "CaratulasPorCorregir"
            }

        ]
    },
    {
        path: "/devoluciones",
        component: DevolucionView,
        name: "Devoluciones",
        children: [
            {
                path: ":numero_caratula",
                component: DevolucionView
            }
        ]
    },
    {
        path: "/recuperar-cuenta",
        component: RecuperarCuentaView,
        name: "RecuperarCuenta",
    },
    {
        path: "/recuperacion",
        component: CambioPasswordRecuperarView,
        name: "CambioPasswordRecuperar",
    },
    {
        path: "/verificacion-pago",
        component: VerificacionPagoView,
        name: "VerificacionaPago",
        children: [
            {
                path: ":seccion",
                component: VerificacionPagoView,
                children: [
                    {
                        path: ":recaudador",
                        component: VerificacionPagoView
                    }
                ]
            }
        ]
    },
    {
        path: "/indices",
        component: IndicesView,
        name: "Indices",
        children: [

            {
                path: ":tipo_registro",
                component: IndicesView
            }

        ]
    },
    {
        path: "/visor-documentos/:serie/:idDocumento/:tipo",
        component: IndicesVisorPDF,
    },
    {
        path: "/videotutoriales",
        component: VidTutorialesView,
        name: "Videotutoriales"
    },
    {
        path: '/boleta-no-disponible',
        component: BoletasView,
        name: "Boletas"
    },
    {
        path: '/activacion/:rut_cliente/:codigo',
        component: ActivacionView,
        name: "Activacion"
    },
    {
        path: "*",
        component: PaginaNoEncontradaView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

const comprobarLogin = (to, from, next) => {
    usuarioEntity.isSessionActive().then((active) => {
        if (active) {
            next();
        } else {
            next({path: '/'});
        }
    });
}

export default router
