<template>
  <v-row>
    <v-col cols="12" class="pb-0"><h3 class="text-center font-weight-medium">Datos de la boleta</h3></v-col>
    <v-col cols="12" class="pt-0">
      <v-radio-group
          v-model="boleta"
          row
          class="d-none d-md-flex"
          style="height: 44px"
      >
        <v-row>
          <v-col cols="12" md="6" class="d-flex align-center justify-center" @click="boleta='1'">
            <v-radio
                label=""
                value="1"
                class="mr-0"
            >
            </v-radio>
            <span class="font-md">La boleta es para mí</span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center" @click="boleta='2'">
            <v-radio
                label=""
                value="2"
                class="mr-0"
            >
            </v-radio>
            <span class="font-md">La boleta es para otra persona</span>
          </v-col>
        </v-row>
      </v-radio-group>

      <!-- Pantalla celu -->

      <v-radio-group hide-details v-model="boleta" class="d-flex d-md-none mt-0">
        <v-col cols="12" md="6" class="d-flex py-1" @click="boleta='1'">
          <v-radio
              label=""
              value="1"
              class="mr-0"
          >
          </v-radio>
          <span class="font-md">La boleta es para mí</span>
        </v-col>
        <v-col cols="12" md="6" class="d-flex py-0" @click="boleta='2'">
          <v-radio
              label=""
              value="2"
              class="mr-0"
          >
          </v-radio>
          <span class="font-md">La boleta es para otra persona</span>
        </v-col>
      </v-radio-group>

      <!-- Pantalla celu -->

    </v-col>
    <v-col cols="12" class="pb-0" v-if="boleta === '2'">
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" class="px-7" v-if="boleta === '2'">

      <p class="font-md font-weight-medium mt-3 mb-2">Datos del cliente boleta</p>
      <v-row>
        <v-col cols="4" class="pr-0">
          <v-text-field
              label="Rut"
              placeholder=" "
              outlined
              dense
              hide-details="auto"
              v-model="rut"
              :change="formatRut()"
              :error-messages="errorsRut"
              @input="delayTouchSearch($v.rut)"
              @blur="$v.rut.$touch()"
              :autofocus="!$vuetify.breakpoint.mobile"
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
              label="Nombre completo"
              placeholder=" "
              outlined
              dense
              hide-details="auto"
              v-model="nombre"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <vuetify-google-autocomplete
              outlined
              dense
              label="Domicilio"
              v-model="direccion"
              :error-messages="errorDireccion"
              @input="delayTouch($v.direccion)"
              @blur="$v.direccion.$touch"
              id="map"
              placeholder="Escriba la dirección de su domicilio."
              country="CL"
              @placechanged="mostrarEnMapa"
              :hide-details="true"

          >
          </vuetify-google-autocomplete>
          <v-fade-transition>
            <div class="text-center" v-if="sugerencias_direcciones.length > 0">
              <small @click="verModalSugerenciasDirecciones = true" class="info--text pointer">Ver sugerencias</small>
            </div>
          </v-fade-transition>
        </v-col>

        <v-col cols="12">
          <v-autocomplete class="text-sm" style=""
                          dense
                          outlined
                          v-model="comuna"
                          :items="comunas"
                          :search-input.sync="search"
                          hide-no-data
                          hide-details="auto"
                          hide-selected
                          item-text="descripcion"
                          item-value="API"
                          label="Comuna"
                          placeholder="Ej: Coquimbo, Coquimbo"
                          return-object
                          :error-messages="errorComuna"
                          @blur="$v.comuna.$touch()"

          ></v-autocomplete>
        </v-col>


      </v-row>
    </v-col>

    <simple-modal :ver-dialog="verModalSugerenciasDirecciones" @ocultarDialog="verModalSugerenciasDirecciones = false">

      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Direccion
            </th>
            <th class="text-left">
              Comuna
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="pointer"
              v-for="item in sugerencias_direcciones"
              :key="item.direccion"
              @click="seleccionarDireccionSugerida(item)"
          >
            <td>{{ item.direccion }}</td>
            <td>{{ item.comuna }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </simple-modal>

  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {required} from 'vuelidate/lib/validators'
import usuarioEntity from "../../model/usuario/usuario.entity";

// eslint-disable-next-line no-unused-vars
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import boletaEntity from "@/model/boleta/boleta.entity";
import SimpleModal from "@/components/SimpleModal";

const touchMap = new WeakMap();

export default {
  name: "ModalPagoDatosBoleta",
  components: {SimpleModal},
  props: {
    comunas: {
      defult: []
    }
  },
  data() {
    return {
      boleta: null,
      isLoading: false,
      comuna: null,
      search: null,
      rut: "",
      nombre: "",
      direccion: "",
      markerMap: {
        lat: null,
        lng: null
      },
      centerMap: {
        lat: -29.90453,
        lng: -71.24894
      },
      sugerencias_direcciones: [],
      verModalSugerenciasDirecciones: false
    }
  },
  validations: {
    rut: {required, rutValidate},
    comuna: {required},
    direccion: {required},
  },
  watch: {

    boleta() {
      this.emitValidacion();
    },
    comuna() {
      this.emitValidacion();
    },
    rut() {
      this.emitValidacion();
    },
    nombre() {
      this.emitValidacion();
    },
    direccion() {
      this.emitValidacion();
    },

  },
  methods: {
    seleccionarDireccionSugerida(item) {
      this.direccion = item.direccion.split(" [")[0]
      this.comuna = this.comunas.filter(f => {
        return f.id_comuna === item.id_comuna;
      })[0]

      this.verModalSugerenciasDirecciones = false;
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    delayTouchSearch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout(() => {
        $v.$touch();
        this.sugerencias_direcciones = []
        if (!this.$v.rut.$error) {
          boletaEntity.getClienteBoleta(
              rutClean($v.$model).slice(0, -1)).then(resp => {
            if (resp) {
              let {direcciones, cliente_boleta} = resp;
              this.nombre = cliente_boleta
              this.sugerencias_direcciones = direcciones;
            } else {
              this.nombre = "";
            }
          })
        }
      }, 500))

    },
    formatRut() {
      this.rut = rutFormat(this.rut);
    },
    emitValidacion() {
      let obj = {}
      if (this.boleta == "1") {
        obj.rut_cliente_boleta = usuarioEntity.getRutUsuario();
        obj.cliente_boleta = usuarioEntity.getNombreUsuario();
        obj.direccion_cliente_boleta = usuarioEntity.getDireccionUsuario();
        obj.id_comuna = usuarioEntity.getIdComunaUsuario()

      } else {
        if (this.rut.trim() != "" && this.nombre.trim() != "" && this.direccion != "" && this.comuna != null) {
          obj.rut_cliente_boleta = rutClean(this.rut).slice(0, -1),
              obj.cliente_boleta = this.nombre.trim();
          obj.direccion_cliente_boleta = this.direccion.trim();
          obj.id_comuna = this.comuna.id_comuna
        } else {
          obj = null;
        }
      }
      this.$emit("updateClienteBoleta", obj);
    },
    mostrarEnMapa(addressData) {
      //ponemos el marker
      this.markerMap.lat = addressData.latitude;
      this.markerMap.lng = addressData.longitude;
      //centramos el mapa
      this.centerMap.lat = addressData.latitude;
      this.centerMap.lng = addressData.longitude;
    }
  },
  created() {
    this.boleta = "1";
  },
  computed: {
    errorsRut() {
      const errors = [];
      if (!this.$v.rut.$dirty) return errors;
      !this.$v.rut.required && errors.push("Ingrese su rut.");
      !this.$v.rut.rutValidate && errors.push("Ingrese un rut válido.");
      return errors;
    },
    errorDireccion() {
      const errors = [];
      if (!this.$v.direccion.$dirty) return errors;
      !this.$v.direccion.required && errors.push("Ingrese su dirección principal");
      return errors;
    },
    errorComuna() {
      const errors = [];
      if (!this.$v.comuna.$dirty) return errors;
      !this.$v.comuna.required && errors.push("Ingrese la comuna en la cual reside");
      return errors;
    },
  }
}
</script>

<style scoped>

</style>
