import getCarroActualCategorizado from "./get-carro-actual-categorizado";
import {ID_AREA_AGRUPACION} from "../../registros/registros.entity";

const isFormValija = () => {
    let carro_categorizado = getCarroActualCategorizado()

    let has_plano = false;

    carro_categorizado.map(f => {

        f.grupos_documentos.map(g => {

            if (g.id_area_agrupacion != ID_AREA_AGRUPACION.PLANOS) {
                has_plano = true;
                return;
            }

        })
        if (has_plano) {
            return
        }
    });

    return has_plano;
}

export default isFormValija;
