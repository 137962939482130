<template>
  <v-form @submit.prevent="send()">
    <v-row class="">

      <v-col cols="12" sm="4" class="pb-0">
        <v-text-field
            label="Foja"
            placeholder="-"
            outlined
            dense
            hide-details="auto"
            disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="pb-0">
        <v-text-field
            label="Número"
            placeholder="Ej: 214"
            outlined
            dense
            :autofocus="!$vuetify.breakpoint.mobile"
            hide-details="auto"
            v-model="numero"
            :error-messages="errorNumero"
            @blur="$v.numero.$touch"
            @input="delayTouch($v.numero)"
            :disabled="!this.camposHabilitados"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="pb-0">
        <v-text-field
            label="Año"
            placeholder="Ej: 2020"
            outlined
            dense
            hide-details="auto"
            v-model="anho"
            :error-messages="errorAnho"
            @blur="$v.anho.$touch"
            @input="delayTouch($v.anho)"
            :disabled="!this.camposHabilitados"
        ></v-text-field>
      </v-col>

    </v-row>
    <input type="submit" v-show="false">
  </v-form>
</template>

<script>
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import {required, between, helpers} from 'vuelidate/lib/validators'

const touchMap = new WeakMap();

const validadorNumero = helpers.regex('validadorNumero', /^([0-9])+(BIS)?$/);

export default {
  name: "FormularioDocumentoAgregado",
  props: {
    camposHabilitados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formulario: {
        id_tipo_formulario: ID_TIPO_FORMULARIO.DOCUMENTO_AGREGADO,
      },
      numero: null,
      anho: null
    }
  },
  validations: {
    anho: {
      required,
      between: between(1942, new Date().getFullYear())
    },
    numero: {
      required,
      validadorNumero
    }
  },
  computed: {
    errorNumero() {
      const errors = [];
      if (!this.$v.numero.$dirty) return errors;
      !this.$v.numero.required && errors.push("Ingrese un numero");
      !this.$v.numero.validadorNumero && errors.push("Ingrese un número válido (Sólo números o números seguido de BIS en mayúsculas)");
      return errors;
    },
    errorAnho() {
      const errors = [];
      if (!this.$v.anho.$dirty) return errors;

      !this.$v.anho.required && errors.push("Porfavor, ingrese un año");
        if (this.anho > new Date().getFullYear()) {
          errors.push("No puede solicitar documentos del futuro");
          return errors;
        } else if (this.anho <= 1941) {
          errors.push("Solo puede solicitar documentos desde el año 1942");
          return errors;
        }
      return errors
    }
  },
  methods: {
    send() {

      if (this.$v.$invalid) {
        this.$v.$touch()
        return;
      }
      this.$emit("send", null);
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 800))
    },
    sendFormulario() {

      this.formulario.foja = "A"
      this.formulario.numero = this.numero;
      this.formulario.anho = this.anho;
      this.$emit("setFormulario", this.formulario);
    },
    /*
    numeroFormatter(e) {

      //retornar si es que se presiona el enter o backspace
      if (e.keyCode === 13 || e.keyCode === 8) {
        return;
      }

      let inputTemp = this.numero ? this.numero : "";
      const position = e.target.selectionStart;

      let k = e.which || e.keyCode;

      if (k === 98 || k === 66) {
        e.preventDefault();
        inputTemp = inputTemp.substr(0, position) + "BIS" + inputTemp.substr(position);
      } else {
        e.preventDefault();
        inputTemp = inputTemp.substr(0, position) + e.key + inputTemp.substr(position);
      }

      if ((/^([0-9])+(BIS)?$/.test(inputTemp))) {
        this.numero = inputTemp;
      }

    }
    */
  },
  watch: {
    numero(newVal, oldVal) {
      if(newVal === oldVal)
      {
        return;
      }

      //mayúscula
      this.numero = newVal.toUpperCase();

      if (this.$v.$invalid) {
        this.$emit("setFormulario", null);
        return;
      }
      this.sendFormulario();
    },
    anho(newVal, oldVal) {
      if(newVal === oldVal)
      {
        return;
      }

      if (this.$v.$invalid) {
        this.$emit("setFormulario", null);
        return;
      }
      this.sendFormulario();
    }
  }
}
</script>

<style scoped>

</style>
