import {getClienteBoletaApi} from "@/model/boleta/boleta.repository";

const getClienteBoleta = (rut_cliente_boleta) => {

    return new Promise((resolve, reject) => {
        getClienteBoletaApi(rut_cliente_boleta).then(response => {
            if (response.data.status) {
                resolve(response.data.obj)
            } else {
                reject(response.data)
            }
        }).catch(error => {
            reject(error)
        })
    })
}

export default getClienteBoleta;