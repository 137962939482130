<template>
  <div>
    <v-navigation-drawer
        style="height: 100%"
        :value="value"
        @input="$emit('input', $event)"
        clipped
        fixed
        disable-resize-watcher
        app
    >
      <v-list v-if="nombreUsuario != null">
        <v-list-item link>
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">{{ nombreUsuario }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="ma-2" v-else>
        <v-btn
            outlined
            block
            text
            color="primary"
            @click="handleIniciarSesion()"
        >
          <v-icon class="mr-1" small>mdi-login</v-icon>
          Ingresar
        </v-btn>
      </div>

      <v-divider v-if="nombreUsuario != null"></v-divider>

      <v-list nav v-if="nombreUsuario != null">
        <v-list-item to="/cliente" exact>
          <v-list-item-title>Mi cuenta</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>

        <v-list-item to="/" exact>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>

        <v-list-item to="/tramites">
          <v-list-item-title>Trámites en línea</v-list-item-title>
        </v-list-item>

        <v-list-item to="/consultas">
          <v-list-item-title>Consultas en línea</v-list-item-title>
        </v-list-item>

        <v-list-item to="/consultas/entrega-en-linea">
          <v-list-item-title>Entrega en línea</v-list-item-title>
        </v-list-item>

        <v-list-item to="/informacion">
          <v-list-item-title>Información CBR</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div v-if="nombreUsuario != null" class="pa-2">
          <v-btn block outlined color="primary" @click="handleCerrarSesion()">
            <v-icon class="mr-1" small>mdi-lock</v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>

    </v-navigation-drawer>
  </div>
</template>

<script>
import usuario from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nombreUsuario: function () {
      return usuario.getNombreUsuario();
    },
  },
  methods: {
    handleIniciarSesion() {
      EventBus.$emit("modal-login",true);
    },
    handleCerrarSesion() {
      usuario.cerrarSesionUsuario().then(() => {
        EventBus.$emit("dialog-sesion-finalizada",true);
        EventBus.$emit("login",false);
      }).catch((error) => {
        console.error(error);
      })
    }
  }
};
</script>
