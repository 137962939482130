import { verificarDocumentoFromApi } from "../notario.repository";

const verificarDocumento = (idNotario, idDocumento) => {
    return new Promise((resolve, reject) => {
        verificarDocumentoFromApi(idNotario, idDocumento).then((response) => {
            if (response.data.status) {
                if (response.data.code == "OK") {
                    resolve({ documentoVerificado: true, mensaje: response.data.obj });
                }
                else if (response.data.code == "FALSE") {
                    resolve({ documentoVerificado: false, mensaje: response.data.obj });
                }
                else {
                    reject({ response: response, mensaje: `No se pudo verificar el documento por una falla en la conexión, por favor intentar nuevamente.` });
                }
            }
            else {
                reject({ response: response, mensaje: `No se pudo verificar el documento por una falla en la conexión, por favor intentar nuevamente.` });
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

export default verificarDocumento;