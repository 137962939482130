<template>
  <div>
    <verificacion-escritura-notarios
        :key="camposHabilitados"
        :campos-habilitados="camposHabilitados" @e-documento-validado="enableFormRepertorioPartes($event)" :documentoVerificado="formRepertorioPartesEnabled"
    />
    <v-fade-transition>
      <v-form v-if="formRepertorioPartesEnabled">
        <v-row>
          <v-col
              cols="12"
              class="py-0"
              md="3"
          >
            <v-text-field
                v-model="formRepertorioPartes.repertorio"
                label="Repertorio"
                autocomplete="off"
                :autofocus="!$vuetify.breakpoint.mobile"
                required
                @input="$v.formRepertorioPartes.repertorio.$touch()"
                @blur="$v.formRepertorioPartes.repertorio.$touch()"
                :error-messages="repertorioErrors"

            >
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              class="py-0"
              md="9">
            <v-text-field
                v-model="formRepertorioPartes.partes"
                label="Partes"
                autocomplete="off"
                required
                @input="$v.formRepertorioPartes.partes.$touch()"
                @blur="$v.formRepertorioPartes.partes.$touch()"
                :error-messages="partesErrors"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-fade-transition>
  </div>
</template>

<script>
import VerificacionEscrituraNotarios
  from "@/components/SolicitudDocumentos/Formularios/AlzamientoCancelacion/VerificacionEscrituraNotarios";
import {required} from "vuelidate/lib/validators";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";

export default {
  name: "AlzamientoCancelacion",
  components: {VerificacionEscrituraNotarios},
  props: {
    camposHabilitados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formRepertorioPartesEnabled: false,
      formRepertorioPartes: {
        repertorio: null,
        partes: null
      },
      dataFormulario: {
        id_tipo_formulario: ID_TIPO_FORMULARIO.ALZAMIENTO_Y_CANCELACION
      }
    }
  },
  watch: {
    getRepertorio() {
      this.generarFormulario()
    },
    getPartes() {
      this.generarFormulario()
    }
  },
  methods: {
    generarFormulario() {
      if (this.$v.$invalid) {
        this.$emit("setFormulario", null)
        return
      }
      this.dataFormulario.partes = this.formRepertorioPartes.partes;
      this.dataFormulario.repertorio = this.formRepertorioPartes.repertorio;

      this.$emit("setFormulario", this.dataFormulario)
    },
    validarFormularioRepertorioPartes() {
      this.$v.formRepertorioPartes.$touch();

      if (this.$v.formRepertorioPartes.$invalid) {
        return;
      }

      alert("agregar documento al carro")
    }
    ,
    enableFormRepertorioPartes(data) {
      this.dataFormulario.notario = data.notarioSeleccionado;
      this.dataFormulario.idDocumento = data.idDocumento;
      this.formRepertorioPartesEnabled = true;

    }
  }
  ,
  validations: {
    formRepertorioPartes: {
      repertorio: {
        required
      }
      ,
      partes: {
        required
      }
    }
    ,
  }
  ,
  computed: {
    getRepertorio() {
      return this.formRepertorioPartes.repertorio
    },
    getPartes() {
      return this.formRepertorioPartes.partes
    },
    repertorioErrors: function () {
      const errors = [];
      if (!this.$v.formRepertorioPartes.repertorio.$dirty) return errors;
      !this.$v.formRepertorioPartes.repertorio.required && errors.push("Ingrese el repertorio de la escritura");
      return errors;
    }
    ,
    partesErrors: function () {
      const errors = [];
      if (!this.$v.formRepertorioPartes.partes.$dirty) return errors;
      !this.$v.formRepertorioPartes.partes.required && errors.push("Ingrese las partes de la escritura");
      return errors;
    }
    ,
  }
  ,
}
</script>

<style scoped>

</style>