<template>
  <v-row>

    <v-col cols="12" md="9" class="pt-0 pb-0">
      <formulario-planos ref="pln" :campos-habilitados="true" @setFormulario="setData($event)"
                         :key="itemNav.default_agrupacion" @send="buscarIndice()"></formulario-planos>
    </v-col>

    <v-col cols="12" md="3" class="pt-0">
      <v-btn block class="mt-4" color="primary" @click="ejecutarBuscar()" :loading="buscando">
        <v-icon left small>
          mdi-magnify
        </v-icon>
        <span>Buscar</span>
      </v-btn>
    </v-col>

    <v-col cols="12" class="pt-4" v-show="!mostrar_link_documento && !no_encontrado && !limite_busquedas">
      <v-alert
          type="info"
          outlined
          border="left"
          :icon="false"
          class="text-center ">
        <p class="mb-0 font-md info--text">
          En esta página usted podrá encontrar su plano indicando Número y Año
          de
          inscripción.
        </p>

      </v-alert>
    </v-col>

    <v-col cols="12" v-if="no_encontrado && !limite_busquedas">
      <v-alert color="info" :icon="false" outlined border="left">
        <p class="text-center my-0">El documento solicitado no se encuentra disponible</p>
      </v-alert>
    </v-col>

    <v-col cols="12" v-if="mostrar_link_documento && !limite_busquedas">
      <v-alert color="info" :icon="false" outlined border="left">

        <p class="text-center">La imagen referencial con índice {{ serie_buscada.foja }}-{{
            serie_buscada.numero
          }}-{{ serie_buscada.anho }} se encuentra disponible</p>

        <div class="text-center">
          <v-btn color="primary" class="mr-1" small
                 @click="verDocumento(serie_buscada.foja, serie_buscada.numero, serie_buscada.anho, idDocumento)">
            <v-icon left small>mdi-magnify</v-icon>
            <span>Ver Documento</span>
          </v-btn>
          <v-btn color="primary" class="ml-1" small @click="seleccionarItem()">
            <v-icon left small>mdi-cart-plus</v-icon>
            <span>Agregar al carro</span>
          </v-btn>
        </div>

      </v-alert>

    </v-col>

    <v-fade-transition>
      <v-col cols="12" class="pt-4" v-if="limite_busquedas">
        <v-alert type="info" text outlined border="left" :icon="false" class="text-center">
          <span v-if="limite_busquedas">Ha alcanzado el límite de busquedas</span>
        </v-alert>

      </v-col>
    </v-fade-transition>

    <modal-limite-alcanzado :ver-dialog="verModalLimiteBusqueda"
                            @ocultarDialog="verModalLimiteBusqueda = false"></modal-limite-alcanzado>

  </v-row>
</template>

<script>
import FormularioPlanos from "@/components/SolicitudDocumentos/Formularios/FormularioPlanos";
import indiceEntity from "@/model/indices/indice.entity";
import {ID_GRUPO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";
import ModalLimiteAlcanzado from "@/views/index/indices/ModalLimiteAlcanzado";

export default {
  name: "TabPlanos",
  components: {ModalLimiteAlcanzado, FormularioPlanos},
  props: {
    itemNav: {default: null}
  },
  data() {
    return {
      idDocumento: null,
      no_encontrado: false,
      mostrar_link_documento: false,
      formulario: null,
      serie_buscada: {},
      limite_busquedas: false,
      verModalLimiteBusqueda: false,
      buscando: false
    }
  },
  methods: {
    ejecutarBuscar() {
      this.$refs.pln.send();
    },
    setData(data) {
      this.formulario = data;
    },
    buscarIndice() {

      if (this.formulario == null) {
        return
      }

      let simulated_id;

      switch (this.itemNav.default_agrupacion) {
        case ID_GRUPO.PLANOS_PROPIEDAD:
          simulated_id = ID_TIPO_REGISTRO.PLANOS_PROPIEDAD
          break;
        case ID_GRUPO.PLANOS_LEY:
          simulated_id = ID_TIPO_REGISTRO.PLANOS_LEY;
          break;
        default:
          return;
      }

      let serie = {
        foja: "P",
        numero: this.formulario.numero,
        anho: this.formulario.anho,
        id_tipo_registro: simulated_id
      }
      this.buscando = true;
      indiceEntity.getDocFNA(serie).then(resp => {

        this.serie_buscada = serie;
        this.idDocumento = resp.obj;
        if (resp.code === "OK") {
          if (resp.obj.trim() !== "") {
            this.mostrar_link_documento = true;
            this.no_encontrado = false;

          } else {
            this.mostrar_link_documento = false;
            this.no_encontrado = true;
          }

        } else {
          this.limite_busquedas = true;
          this.verModalLimiteBusqueda = true;

          this.verModalLimiteBusqueda = true;
        }

      }).finally(()=>{
        this.buscando = false
      })
    },
    verDocumento(foja, numero, anho, idDocumento) {
      usuarioEntity.isSessionActive().then(res => {
        if (res) {
          const serie = `${foja}-${numero}-${anho}`
          window.open(`/visor-documentos/${serie}/${idDocumento}/p`, '_blank');
        } else {
          EventBus.$emit("modal-login", true);
        }
      })
    },
    seleccionarItem() {
      this.$emit("seleccionarItem", this.serie_buscada)
    }
  }
}
</script>

<style scoped>

</style>
