import {ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";
import {getCertificadoFromApiId} from "../../registros.repository";

const getCertificadoLitigios = (id_tipo_registro) =>{
 return new Promise((resolve, reject) => {
        getCertificadoFromApiId(id_tipo_registro, ID_TIPO_CERTIFICADO.LITIGIOS).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            }
            else {
                console.log("response?= ", response
                )
                reject({ response: response, mensaje: `No se pudo cargar los tipos registros` });
            }
        }).catch(function (error) {
            reject(error);
        });
    })
}


export default getCertificadoLitigios;
