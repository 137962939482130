import {continuarCompraApi} from "@/model/carro/carro.repository";

const continuarCompra = (id_carro_compra, id_tipo_pago) => {

    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        continuarCompraApi(id_carro_compra, id_tipo_pago).then(response => {
            if (response.status) {
                resolve(response.obj);
            }
        }).catch(error => {
            reject(error)
        })

    })

}

export default continuarCompra;