<template>
  <div>
    <TheBreadcrumb class="mb-5"/>
    <v-fade-transition>
      <div v-if="!cargandoCategorias">
        <v-row 
            no-gutters
            v-for="tipo_registro in lista_registros"
            :key="tipo_registro.id_tipo_registro"
            class="mt-0 mb-5"
        >

          <v-col cols="12">
            <v-alert
                class="ma-0"
                text
                style="color: rgb(33, 150, 243);"
                icon="mdi-file"
            >
              <span v-if="tipo_registro.tipo_registro === 'Propiedad'">
                Copias y certificados de
              </span>{{ tipo_registro.tipo_registro }}
            </v-alert>
          </v-col>

          <v-col >
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"

                  v-for="certificado in hideSoloAnexo(tipo_registro.tipos_certificados)"
                  :key="certificado.id_tipo_certificado"
              >
                <card-tipo-registro
                    :CertificadoProp="certificado"
                    :TipoRegistro="tipo_registro"

                />
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>


<script>
// import CardOpcionMenu from "@/components/CardOpcionMenu/CardOpcionMenu.vue";
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
import CardTipoRegistro from "@/components/SolicitudDocumentos/CardTipoRegistro";
import registro from "@/model/registros/registros.entity";

export default {
  components: {CardTipoRegistro, TheBreadcrumb},
  data() {
    return {
      cargandoCategorias: false,
      url_categoria: null,
      lista_registros: null,
    };
  },

  methods: {
    hideSoloAnexo(lista_certificados) {
      return lista_certificados.filter(f => {
        return !f.solo_anexo
      })
    }
  },

  mounted() {
    this.url_categoria = this.$route.params.categoriaRegistro;

    this.cargandoCategorias = true;

    registro.getTiposRegistrosFromCategoria(this.url_categoria)
        .then((response) => {
          this.lista_registros = response;
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.cargandoCategorias = false;
        })
  },
};
</script>
