import {obtenerNotariosFromApi} from "../notario.repository";

const getListaNotariosForAutocomplete = () => {
    //obtener la lista de los notarios
    return new Promise((resolve, reject) => {
        obtenerNotariosFromApi().then((response) => {
            if (response.data.status) {
                //convertir la lista en un objeto para el autocomplete
                let notariosAutocomplete = [];
                response.data.obj.forEach(element => {
                    notariosAutocomplete.push({
                        text: element.nombre,
                        value: element
                    })
                });

                resolve(notariosAutocomplete);
            }
            else {
                reject({ response: response, mensaje: `Error al cargar la lista de notarios` });
            }
        }).catch(function (error) {
            reject(error);
        });
    })
}

export default getListaNotariosForAutocomplete;