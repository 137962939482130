import {recuperarCuentaAPI} from "@/model/usuario/usuario.repository";

const recuperarCuenta = (rutCliente, correoCliente, token) => {
    console.log(rutCliente, correoCliente);

    return new Promise((resolve, reject) => {
        recuperarCuentaAPI(rutCliente, correoCliente, token)
            .then((response) => {
                if (response.data.status) {
                    if(response.data.code === "MAIL_OK:ENVIO_OK")
                    {
                        resolve(response.data.obj);
                    }
                    else if(response.data.code === "DATOS:NOT_MATCH")
                    {
                        reject({
                            response: response,
                            mensaje: "El rut ingresado no corresponde al correo electrónico registrado."
                        })
                    }

                } else {
                    reject({
                        response: response,
                        mensaje: "No se pudo recuperar la cuenta, favor intente nuevamente o comuníquese con el conservador."
                    })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export default recuperarCuenta;