<template>
  <div>
    <v-row>

      <v-col cols="12" md="8" class="pb-0">
        <small class="font-weight-bold">Notaría</small>
        <v-alert
            border="left"
            color="black"
            dense
            :icon="false"
            outlined
            class="text-center py-1"
        >
        <span style="color:black; font-size: 13px">
              {{ notaria_seleccionada }}
        </span>

        </v-alert>

      </v-col>

      <v-col cols="12" md="4" class="pb-0">
        <small class="font-weight-bold">Repertorio</small>
        <v-alert
            border="left"
            color="black"
            dense
            :icon="false"
            outlined
            class="text-center py-1"
        >
        <span style="color:black; font-size: 13px">
              {{ repertorio }}
        </span>

        </v-alert>

      </v-col>
      <v-col cols="12" md="8" class="py-0">
        <small class="font-weight-bold">Contratantes</small>
        <v-alert
            border="left"
            color="black"
            dense
            :icon="false"
            outlined
            class="text-center py-1"
        >
        <span style="color:black; font-size: 13px">
              {{ contratantes }}
        </span>

        </v-alert>

      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <small class="font-weight-bold">Fecha</small>
        <v-alert
            border="left"
            color="black"
            dense
            :icon="false"
            outlined
            class="text-center py-1"
        >
        <span style="color:black; font-size: 13px">
              {{ fecha | toDate }}
        </span>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";

export default {
  name: "FormularioEscrituraPublicaPrecargado",
  props: {
    escritura: {default: {}}
  },
  data() {
    return {
      notaria_seleccionada: "",
      repertorio: "",
      contratantes: "",
      fecha: ""
    }
  },

  mounted() {
    this.notaria_seleccionada = this.escritura.find(f => f.nombre === "notaria").valor;
    this.repertorio = this.escritura.find(f => f.nombre === "repertorio").valor;
    this.contratantes = this.escritura.find(f => f.nombre === "contratantes").valor;
    this.fecha = this.escritura.find(f => f.nombre === "fecha").valor;

    let dataFormulario = {
      id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
      datos_adicionales: this.escritura
    }
    this.$emit("setFormulario", dataFormulario);
  }
}
</script>

<style scoped>

</style>