import {obtenerTerminosCondicionesActual, obtenerUsuarioLocal} from "@/model/usuario/usuario.repository";

const verificarVersionTerminosCondiciones = () => {
    let termActual = obtenerTerminosCondicionesActual();
    let user = obtenerUsuarioLocal();

    return user.terminos_y_condiciones.version_terminos === termActual.version_terminos;

}

export default verificarVersionTerminosCondiciones;
