<template>
  <v-container class="custom-container">
    <v-fade-transition mode="out-in">
      <router-view :key="$route.fullPath"/>
    </v-fade-transition>
  </v-container>
</template>

<script>

export default {
  created: function () {
    this.$vuetify.goTo(0);
  },
};
</script>