import {obtenerCarroActual, obtenerCarroFromApi} from "@/model/carro/carro.repository";
import {CARRO_CODES} from "@/model/carro/carro.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";


const validarCarros = () => {
    return new Promise((resolve, reject) => {
        let carroActual = obtenerCarroActual();

        let carroRemoto;
        usuarioEntity.isSessionActive().then((resp) => {
            if (resp) {

                obtenerCarroFromApi().then(response => {

                    carroRemoto = response.data.obj;

                    if (carroActual.length > 0) {
                        if (carroRemoto.length > 0) {

                            resolve(CARRO_CODES.ELEGIR_CARRO);
                        } else {
                            resolve(CARRO_CODES.CONSERVAR_CARRO_ACTUAL);
                        }
                    } else {
                        resolve(CARRO_CODES.CONSERVAR_CARRO_REMOTO);
                    }

                }).catch(error => reject({response: error, mensaje: "No se pudo agregar al carro"}))
            } else {
                reject({response: null, mensaje: "No se ha iniciado sesión"})
            }
        })

    })
}

export default validarCarros;