import {updatePasswordRecuperacionCuentaAPI} from "@/model/usuario/usuario.repository";

const updatePasswordRecuperarCuenta = (rutCliente, password, fechaRegistro) => {
    return new Promise((resolve, reject) => {
        updatePasswordRecuperacionCuentaAPI(rutCliente, password, fechaRegistro)
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data.obj);

                } else {
                    reject({response: response, mensaje: "No se pudo cambiar la contraseña."})
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export default updatePasswordRecuperarCuenta;