<template>
  <v-footer class="footer-cbrls font-weight-light font-small pa-0">
    <v-container class="py-0">
      <v-row>
        <v-col cols="12" md="3" class="mb-4">
          <h5 class="text-h6 text-center black--text">Contacto</h5>
<!--          <ul class="pt-1">-->
<!--            <li class="footer-cbrls-item ">-->
<!--              <a>Consulta de carátula</a>-->
<!--            </li>-->
<!--            <li class="footer-cbrls-item ">-->
<!--              <a>Consulta de índices</a>-->
<!--            </li>-->
<!--            <li class="footer-cbrls-item">-->
<!--              <a>Verificar documentos</a>-->
<!--            </li>-->
<!--            <li class="footer-cbrls-item ">-->
<!--              <a>Solicitud de documentos</a>-->
<!--            </li>-->

<!--          </ul>-->
                    <ul>
                      <li class="footer-cbrls-item">
                        Alcalde 430, Coquimbo.
                      </li>
                      <li class="footer-cbrls-item">
                        secretaria@conservadorcoquimbo.cl
                      </li>
                      <li class="footer-cbrls-item">
                        Lunes a Viernes, 9:00 a 14:00 hrs.
                      </li>
                    </ul>
        </v-col>
        <v-col cols="12" md="3" class="mb-4">
          <h5 class="text-h6 black--text text-center black--text">Información CBR</h5>

          <ul class="mb-0 pt-1">
            <li class="footer-cbrls-item">
              <router-link to="/informacion/acerca-de">Acerca del conservador</router-link>
            </li>
            <li class="footer-cbrls-item">
              <router-link to="/informacion/tyc">Términos y condiciones</router-link>
            </li>
            <!--            <li class="footer-cbrls-item">-->
            <!--              <router-link to="/informacion/trabaja-con-nosotros">Trabaja con nosotros</router-link>-->
            <!--            </li>-->
          </ul>
        </v-col>
        <v-col cols="12" md="3" class="mb-4">
          <h5 class="text-h6 black--text text-center pb-1">Cuenta Usuario</h5>

          <ul class="mb-0">
            <li class="footer-cbrls-item">
              <router-link v-if="sesionIniciada" to="/cliente">Datos personales</router-link>
              <a v-else @click="modalLogin()">Datos personales</a>
            </li>
            <li class="footer-cbrls-item">
              <router-link v-if="sesionIniciada" to="/cliente/compras">Mis compras</router-link>
              <a v-else @click="modalLogin()">Mis compras</a>
            </li>
            <li class="footer-cbrls-item">
              <router-link v-if="sesionIniciada" to="/cliente/caratulas">Mis carátulas</router-link>
              <a v-else @click="modalLogin()">Mis carátulas</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="3" class="mb-4">
          <h5 class="text-h6 black--text text-center pb-1">Ayuda</h5>

          <ul class="mb-0">
            <li class="footer-cbrls-item">
              <router-link to="/informacion/faq">Preguntas frecuentes</router-link>
            </li>
            <!--
            <li class="footer-cbrls-item">
              <router-link to="/videotutoriales">Videotutoriales</router-link>
            </li>
            -->
            <li class="footer-cbrls-item">
              <router-link to="/informacion/contacto">Contacto</router-link>
            </li>
          </ul>
        </v-col>
        <v-col class="text-center pt-0" cols="12">
          <v-hover v-slot="{ hover }">
            <v-fade-transition mode="out-in">
              <p v-if="!hover" key="defecto">Desarrollado por <b>ASM tecnología</b>, La Serena.</p>
              <p v-else key="otro">Consulte por este y otros productos para CBR al correo <b>contacto@aionsoft.cl</b>
              </p>
            </v-fade-transition>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {EventBus} from "@/main";
import usuarioEntity from "@/model/usuario/usuario.entity";

export default {
  name: "IndexCompoundFooter",
  data() {
    return {
      sesionIniciada: false,
    }
  },
  created() {
    EventBus.$on("login", (login) => {
      this.sesionIniciada = login;
    });
  },
  mounted() {
    usuarioEntity.isSessionActive()
        .then((isActivo) => {
          this.sesionIniciada = isActivo;
        });
  },
  methods: {
    modalLogin() {
      EventBus.$emit("modal-login", true);
    },
  }
}

</script>

<style lang="scss" scoped>

$cbrls-color-primary: #168ccc;
$grey-lighten-3: #eee;
$grey-darken-4: #212121;
$grey-lighten-2: #cecece;

.footer-cbrls {
  border-top-color: $cbrls-color-primary !important;
  border-style: solid;
  border-width: 5px 0 0 0;
  background-color: $grey-lighten-3 !important;
  color: $grey-darken-4 !important;
  font-size: 0.9rem;
}

.footer-cbrls .container {

}

.footer-cbrls h5 {
  color: $cbrls-color-primary;
  font-weight: 400;
}

.footer-cbrls ul {
  padding-left: 0;
}

.footer-cbrls .footer-cbrls-item {
  width: 100%;
  list-style: none;
  padding-left: 0;
  border-bottom: 1px Solid $grey-lighten-2;
  padding-top: 0.17rem;
  padding-bottom: 0.17rem;
  text-align: center;
  color: #168ccc !important;
}

.footer-cbrls .footer-cbrls-item a {
  color: #168ccc !important;
}

.footer-cbrls a {
  text-decoration: none;
  color: $grey-darken-4 !important;
}
</style>
