import {obtenerURLDocumentoFeDigitalAPI} from "@/model/fe-digital/documento.repository";

const verificarDocumento = (codigoDocumento) => {
    return new Promise((resolve, reject) => {
        obtenerURLDocumentoFeDigitalAPI(codigoDocumento).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            }
            else {
                reject({ response: response, mensaje: `No se pudo verificar el documento por una falla en la conexión, por favor intentar nuevamente.` });
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

export default verificarDocumento;