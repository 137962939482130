import axios from "axios";
import qs from "qs";

export const getVideotutorialesFromApi = (start) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "/clientes?action=r-all-publicados-pub-vid",
            qs.stringify({
                d: start
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })

}