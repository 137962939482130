import {obtenerCarroActual} from "@/model/carro/carro.repository";
import registrosEntity from "@/model/registros/registros.entity";


const getResumen = () => {

    let carro = obtenerCarroActual();

    let resumen = {
        precio_total: 0
    };

    carro.map(item => {
        resumen.precio_total += item.precio * item.numero_copias;
    })

    let documentos_obligatorios = registrosEntity.getCertificadosObligatoriosFromListaCertificados(carro);

    documentos_obligatorios.map(ob=>{
        resumen.precio_total += ob.precio;
    })

    resumen.cantidad_documentos = (carro.length + documentos_obligatorios.length).toString();

    return resumen;


}

export default getResumen;