<template>
    <v-card class="fill-height">
      <v-card-text v-if="video != null">
        <v-row>

          <v-col cols="12" class="d-flex align-center">
            <iframe width="100%" height="300px" :src="video.link" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe>
          </v-col>
          <v-col cols="12">
            <h3 class=" font-weight-medium">
              {{ video.titulo }}
            </h3>
            <small>
              <v-icon size="14">mdi-calendar</v-icon>
              {{ video.fecha_publicacion | toDate }}</small>
            <p class="pt-3 text-justify">{{ video.descripcion }}</p>
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>
</template>

<script>
export default {
  name: "VideoTutorialesItem",
  props: {
    video: {default: null}
  }
}
</script>

<style scoped>

</style>