import qs from "qs";
import axios from "axios";


export const getClienteBoletaApi = (rut_cliente_boleta) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/v2/clientes?action=get-cliente-boleta",
            qs.stringify({rut_cliente_boleta: rut_cliente_boleta})
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}
