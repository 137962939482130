<template>
  <v-container class="custom-container">
    <TheBreadcrumb/>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-container>
</template>

<script>
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
export default {
  components: {TheBreadcrumb},
  computed: {
    itemsBreadcrumb: function () {
      let pathArray = this.$route.path.split("/");

      pathArray.shift();

      return pathArray.reduce(
        (breadcrumbArray, path, idx) => {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: path.substr(path.lastIndexOf("/") + 1), //this.$route.matched[idx].meta.breadCrumb || path,
            exact: true,
          });
          return breadcrumbArray;
        },
        [
          //ícono inicial
          {
            path: "/",
            to: "/",
            text: "Home",
            exact: true,
          },
        ]
      );
    },
  },
  created: function () {
    this.$vuetify.goTo(0);
  },
};
</script>