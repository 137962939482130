import {
    actualizarActivo,
    updateVerificacionClienteSesionApi,
} from "@/model/usuario/usuario.repository";

const updateVerificacionClienteSesion = () => {

    return new Promise((resolve, reject) => {
        updateVerificacionClienteSesionApi()
            .then((response) => {

                actualizarActivo(true);
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })

}

export default updateVerificacionClienteSesion;
