import qs from "qs";
import axios from "axios";

export const getCategoriasRegistrosTramitesEnLineaFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/solicitudesclientes/v2?action=get-categorias-registros",
            qs.stringify({})
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getRegistroFromApi = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `/solicitudesclientes/v2?action=get-tipos-certificados-from-categoria&url_categoria=${url}`
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getCertificadoFromApi = (url_tipo_registro, url_tipo_certificado) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `/solicitudesclientes/v2?action=get-informacion-certificado&url_tipo_registro=${url_tipo_registro}&url_tipo_certificado=${url_tipo_certificado}`
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getSerieFromApi = (numeroSerie, anhoSerie, idTipoRegistro, id_tipo_certificado) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `/indices/v2?action=is-serie`,
            qs.stringify({
                serie: JSON.stringify({
                        numero: numeroSerie,
                        anho: anhoSerie,
                        id_tipo_registro: idTipoRegistro,
                        id_tipo_certificado: id_tipo_certificado
                    }
                ),
            })
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        })
    })
}

export const getAllCertificadosApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/solicitudesclientes/v2?action=get-certificados-buscador`)
            .then(response => {
                resolve(response);
            }).catch(error => {
            reject(error)
        })
    })
}

export const getCertificadoFromApiId = (id_tipo_registro, id_tipo_certificado) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `solicitudesclientes/v2?action=get-informacion-certificado-id`,
            qs.stringify({
                id_tipo_registro: id_tipo_registro,
                id_tipo_certificado: id_tipo_certificado
            })
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        })
    })
}

export const getAllDataRegistrosApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            `solicitudesclientes/v2?action=get-all-data-registros`
        ).then(response => {
            resolve(response.data.obj);
        }).catch(error => {
            reject(error)
        })
    })
}

export const getCertificadoByUrlApi = (url_tipo_registro, url_tipo_certificado) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `solicitudesclientes/v2?action=get-certificado-by-url`, qs.stringify({
                url_tipo_registro: url_tipo_registro,
                url_tipo_certificado: url_tipo_certificado
            })
        ).then(response => {
            resolve(response.data.obj);
        }).catch(error => {
            reject(error)
        })
    })
}

export const getDocumentosTentativosCertificadoApi = (certificado, indice) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `/solicitudesclientes/v2?action=get-documentos-tentativos-certificado`,
            qs.stringify({
                    certificado: JSON.stringify(certificado),
                    indice: indice
                }
            )).then(function (response) {
            resolve(response.data.obj);
        }).catch(function (error) {
            reject(error);
        });
    })
}