export const notUrlFilter = (value) => {

    let textSplit = value.split("-");

    let newText = "";

    textSplit.map(t=>{
        if(t.length> 2){
            t = t.replace("cancelacion", "cancelación")
            newText+= t.charAt(0).toUpperCase()  + t.slice(1, t.length) + " " ;
        }else{
            newText+= t + " "
        }
    })

    return newText.trim();

}
