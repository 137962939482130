import getCategoriasRegistrosTramitesEnLinea
    from '@/model/registros/usecases/get-categorias-registros-tramites-en-linea'
import getTiposRegistrosFromCategoria from "@/model/registros/usecases/get-tipos-registros-from-categoria";
import getCertificadoTramitesEnLinea from "@/model/registros/usecases/get-certificado-tramites-en-linea";
import toCase from "@/model/registros/usecases/text-convert";
import isSerie from "@/model/registros/usecases/is-serie";
import getAllCertificadosBuscador from "@/model/registros/usecases/get-all-certificados-buscador";
import getCertificadoLitigios from "@/model/registros/usecases/get-certificados/get-certificado-litigios";
import getImagenCertificado from "@/model/registros/usecases/get-imagen-certificado";
import getAllDataRegistros from "@/model/registros/usecases/get-all-data-registros";
import getCertificadosObligatoriosFromListaCertificados
    from "@/model/registros/usecases/get-certificados-obligatorios-from-lista-certificados";
import getCertificadoByUrl from "@/model/registros/usecases/get-certificado-by-url";
import getDocumentosTentativosCertificado from "@/model/registros/usecases/get-documentos-tentativos-certificado";

export const ID_CATEGORIA_REGISTRO = {
    PROPIEDAD: 1,
    COMERCIO: 2,
    AGUAS: 3,
    MINAS: 4,
}

export const ID_TIPO_REGISTRO = {
    REGISTRO_DE_PROPIEDAD: 1,
    AGUAS: 2,
    HIPOTECA_PROPIEDAD: 3,
    PLANOS_PROPIEDAD: 5,
    PLANOS_LEY: 6,
    COMERCIO: 7,
    ALZAMIENTO_Y_CANCELACION: 15,
    ESCRITURAS_PUBLICAS: 20
}

export const ID_TIPO_CERTIFICADO = {
    COPIA_AUTORIZADA: 1,
    HIPOTECAS_Y_GRAVAMENES: 4,
    LITIGIOS: 8,
    CANCELACION_HIPOTECA: 10,
    CANCELACION_HIPOTECA_SERVIU: 11,
    ALZAMIENTO_PROHIBICIONES: 12,
    ALZAMIENTO_PROHIBICIONES_SERVIU: 13,
    COPIA_AUTORIZADA_HIPOTECA: 79,
    COPIA_AUTORIZADA_PROHIBICION: 80,
    ALZAMIENTO_GP_UNA_PRP: 90
}

export const ID_GRUPO = {
    COPIAS_Y_CERTIFICADOS: 2,
    PLANOS_LEY: 3,
    ALZAMIENTO_Y_CANCELACION: 4,
    PLANOS_PROPIEDAD: 6,
    PRH_PROPIEDAD: 7,
    HIP_PROPIEDAD: 8,
    ESCRITURAS_PUBLICAS: 9,
    ESCRITURAS_PUBLICAS_2: 10,
    DOCUMENTO_AGREGADO: 11

}

export const ID_AREA_AGRUPACION = {
    COPIAS_PROPIEDAD: 1,
    COPIAS_COMERCIO: 4,
    COPIAS_AGUAS: 6,
    PLANOS: 14,
    ESCRITURAS_PUBLICAS: 31
}

export const PRECIO = {
    CERTIFICADO_LITIGIOS: 2300
}

 const isPrecioVariable = (id_area_agrupacion) => {

    let agrupaciones_variable = [ID_AREA_AGRUPACION.COPIAS_PROPIEDAD, ID_AREA_AGRUPACION.COPIAS_AGUAS, ID_AREA_AGRUPACION.COPIAS_COMERCIO, ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS]

    let isIn = agrupaciones_variable.find(f=>{
        return f == id_area_agrupacion
    })

    return isIn != null;

}

export const LITIGIO_PREDETERMINADO = false;

const registrosEntity = {

    //
    getCategoriasRegistros: getCategoriasRegistrosTramitesEnLinea,
    getTiposRegistrosFromCategoria: getTiposRegistrosFromCategoria,
    getCertificadoTramitesEnLinea: getCertificadoTramitesEnLinea,
    isSerie: isSerie,
    getAllCertificadosBuscador: getAllCertificadosBuscador,
    toCase: toCase,
    ID_GRUPO: ID_GRUPO,
    ID_TIPO_REGISTRO: ID_TIPO_REGISTRO,
    ID_TIPO_CERTIFICADO: ID_TIPO_CERTIFICADO,
    getCertificadoLitigios: getCertificadoLitigios,
    getImagenCertificado: getImagenCertificado,
    getAllDataRegistros,
    isPrecioVariable,
    getCertificadosObligatoriosFromListaCertificados,
    getCertificadoByUrl,
    getDocumentosTentativosCertificado
}

export const idToIcon = (idRegistro) => {
    switch (idRegistro) {
        case ID_CATEGORIA_REGISTRO.PROPIEDAD:
            return "mdi-home";
        case ID_CATEGORIA_REGISTRO.COMERCIO:
            return "mdi-store";
        case ID_CATEGORIA_REGISTRO.AGUAS:
            return "mdi-water-pump";
        case ID_CATEGORIA_REGISTRO.MINAS:
            return "mdi-pickaxe";
    }
}

export const idToImage = (idRegistro) => {
    switch (idRegistro) {
        case ID_CATEGORIA_REGISTRO.PROPIEDAD:
            return "propiedad";
        case ID_CATEGORIA_REGISTRO.COMERCIO:
            return "comercio";
        case ID_CATEGORIA_REGISTRO.AGUAS:
            return "aguas";
        case ID_CATEGORIA_REGISTRO.MINAS:
            return "minas";
    }
}

export default registrosEntity;
