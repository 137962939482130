<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-alert
          border="left"
          colored-border
          :type="tipo"
          class="mb-0"
          :icon="false"
      >
        <slot/>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SimpleModalStatic",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>