import {obtenerMisComprasFromApi} from "@/model/usuario/usuario.repository";
import isSessionActive from "@/model/usuario/usecases/is-session-active";

const getMisCompras = () => {
    return new Promise((resolve, reject) => {
        isSessionActive().then(resp => {
            if (resp) {
                obtenerMisComprasFromApi().then(response => {

                    if (response.status) {
                        console.log("response: ", response)
                        resolve(response.obj)
                    } else {
                        reject({mensaje: response.obj});
                    }
                })
            } else {
               reject({mensaje: "No ha iniciado sesión"})
            }
        }).catch(error=>{
            reject({mensaje: error})
        })
    })

}

export default getMisCompras;