<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <h2 class="info--text py-5 font-weight-medium text-center">No es posible agregar el documento</h2>
        <p class="text-justify">Estimado cliente, no es posible agregar el documento debido a que el documento tanto tanto...</p>

        <div class="text-center pt-2">
          <v-btn @click="hideDialog" color="info" type="button" outlined ><v-icon left>mdi-close</v-icon>Entiendo, volver </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modalNoPuedeAgregar",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>