<template>
  <div>
    <v-row :key="certificado.id_tipo_certificado" v-for="certificado in certificados">
      <v-col cols="12" v-if="show === null">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="5" class="d-flex align-center pb-3 pb-sm-3 pb-md-0 pt-0 ">
        <p class=" mb-0 pl-2">{{ certificado.tipo_certificado }} <span
            v-if="certificado.vigencia != -1">({{ certificado.vigencia }} Años)</span></p>

      </v-col>
      <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
        <v-btn class="px-0" style="min-width: 25px"
               small
               elevation="0"
               height="26"
               tile
               outlined
               color="primary"
               @click="reducir(certificado)"
        >
          <v-icon small dark>
            mdi-minus
          </v-icon>
        </v-btn>
        <input type="text"
               class="input-spin"
               disabled
               v-model="certificado.numero_copias"
        >
        <v-btn
            outlined
            class="px-0" style="min-width: 25px"
            small
            elevation="0"
            height="26"
            tile
            color="primary"
            @click="aumentar(certificado)"
        >
          <v-icon small dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
        <span :style="{color: certificado.numero_copias > 0 ? 'inherit' : '#6b666694'}">

          <span v-if="showTotal(certificado) > 0"> {{ showTotal(certificado) | clp }}</span>
          <span v-if="showTotal(certificado) == 0">  Por cotizar</span>
        </span>
      </v-col>
      <v-col cols="12" class="pt-0"
             v-if="certificado.certificados_obligatorios != null && certificado.certificados_obligatorios.length > 0">

        <small class="pl-2">Incluye los siguientes certificados:
          <ul>
            <li :key="obl.tipo_certificado + '-' + obl.id_tipo_registro_obligatorio"
                v-for="obl in certificado.certificados_obligatorios">
              {{ obl.tipo_certificado_obligatorio }} - Valor: {{ obl.precio | clp }}
            </li>
          </ul>

        </small>

      </v-col>

    </v-row>
  </div>


</template>

<script>
import {ID_AREA_AGRUPACION, ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";
// import {LITIGIO_PREDETERMINADO} from "../../model/registros/registros.entity";

export default {
  name: "DocumentosTentativos",
  components: {},

  data() {
    return {
      certificados: [],
      ID_TIPO_CERTIFICADO: ID_TIPO_CERTIFICADO,
      ID_AREA_AGRUPACION: ID_AREA_AGRUPACION

    }
  },
  props: {
    listaCertificados: {
      type: Array,
      default: () => []
    },
    show: {
      default: null
    },
    hide: {
      default: null
    },
    model: {
      default: 0
    },
    numeroCarillas: {
      default: 0
    },
    precioCarillas: {
      default: 0
    }
  },
  created() {


    if (this.show != null) {
      this.certificados = this.listaCertificados.filter(f => {
        return f.id_tipo_certificado === this.show.id_tipo_certificado && f.id_tipo_registro_FK === this.show.id_tipo_registro_FK;
      });
      // if (this.show.id_tipo_certificado == ID_TIPO_CERTIFICADO.HIPOTECAS_Y_GRAVAMENES) {
      //   this.$set(this.certificados[0], 'has_litigio', LITIGIO_PREDETERMINADO)
      // }

    } else if (this.hide != null) {
      this.certificados = this.listaCertificados.filter(f => {
        return f.id_tipo_certificado !== this.hide.id_tipo_certificado && f.id_tipo_registro_FK === this.hide.id_tipo_registro_FK;
      });
      //
      // this.certificados.map(c=>{
      //   if(c.id_tipo_certificado == ID_TIPO_CERTIFICADO.HIPOTECAS_Y_GRAVAMENES){
      //     this.$set(c, 'has_litigio', LITIGIO_PREDETERMINADO)
      //   }
      // })

    } else {
      this.certificados = this.listaCertificados;
    }

  },
  watch: {},
  methods: {
    modificarLitigio(certificado) {
      this.$emit("modificarLitigio", certificado);
    },
    aumentar(certificado) {
      this.$emit("aumentar", certificado);
    },
    reducir(certificado) {

      this.$emit("reducir", certificado)

    },
    showTotal(certificado) {
      if (certificado.numero_copias > 0) {
        return certificado.numero_copias * (parseInt(certificado.precio) + (certificado.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precioCarillas) : 0));
      } else {
        return (certificado.precio + (certificado.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precioCarillas) : 0));
      }
    }
  }
}
</script>

<style scoped>

</style>
