import axios from "axios";
import qs from "qs";
import store from "@/store";

export const obtenerCarroActual = () => {
    return store.state.carro;
}

export const updateCarroActual = (carro) => {
    store.commit("updateCarro", carro)
}

export const obtenerCarroFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            "/solicitudesclientes?action=obtener-carro"
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        });
    })
}

export const agregarAlCarroApi = (listaCarro) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes?action=agrega-carro",
            qs.stringify({
                obj_items_carro: JSON.stringify(listaCarro)
            })
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const vaciarCarroActual = () => {
    store.commit("deleteCarro");
}

export const reemplazarCarroRemoto = (carro) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes?action=actualiza-carro",
            qs.stringify({
                obj_items_carro: JSON.stringify(carro)
            })
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const crearSolicitudCliente = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=create-solicitud-cliente",
            qs.stringify({
                obj_solicitud_cliente: JSON.stringify(data)
            })
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const continuarCompraApi = (id_carro_compra, id_tipo_pago) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=continuar-pago-carro-compras",
            qs.stringify({
                id_carro_compras: id_carro_compra,
                id_tipo_pago: id_tipo_pago
            })
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getEstadoPagoCarroApi = (id_carro) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=get-estado-pago-carro",
            qs.stringify({
                id_carro_compras: id_carro,
            })
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}

