<template>
  <simple-modal :verDialog="mostrar" @ocultarDialog="mostrar = false" time="1">
    <p class="mb-0 text-sm-center">
      <v-icon color="info" size="20">mdi-information-outline</v-icon>
      Bienvenido/a {{ nombreUsuario }}
    </p>
  </simple-modal>

</template>

<script>
import SimpleModal from "@/components/SimpleModal";
import {EventBus} from "@/main";
import usuario from "@/model/usuario/usuario.entity";

export default {
  name: "DialogSesionIniciada",
  components: {SimpleModal},
  created() {
    EventBus.$on("dialog-sesion-iniciada", (mostrar) => {
      this.mostrar = mostrar;
    })
  },
  data() {
    return {
      mostrar: false,
    }
  },
  computed: {
    nombreUsuario: function () {
      return usuario.getNombreUsuario();
    },
  }
}
</script>

<style scoped>

</style>
