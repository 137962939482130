import {getDocFNAApi} from "../indice.repository";

const getDocFna = (serie) => {
    return new Promise((resolve, reject) => {
        getDocFNAApi(serie).then((response) => {
            if (response.data.status) {
                resolve(response.data)
            } else {
                reject({error: response.data})
            }
        }).catch(error => {
            reject(error)
        });
    });
}

export default getDocFna;
