import usuarioEntity from "@/model/usuario/usuario.entity";
import {obtenerCarroActual, reemplazarCarroRemoto, updateCarroActual} from "@/model/carro/carro.repository";

const reemplazarCarro = () => {
    return new Promise((resolve, reject) => {

        usuarioEntity.isSessionActive().then(resp => {
            let carro = obtenerCarroActual();
            if (resp) {
                reemplazarCarroRemoto(carro).then(() => {
                    updateCarroActual(carro);
                    resolve(carro);
                }).catch(reject({response: null, mensaje: "Error al actualizar carro"}))
            } else {
                reject({response: null, mensaje: "Debe iniciar sesión para reemplazar el carro"})
            }
        })

    })
}

export default reemplazarCarro;

