<template>
  <div>
    <!-- visor planos -->
    <v-container v-if="tipo == 'p'" class="pa-0 ma-0" style="width: auto; max-width: 100%;">
      <div v-if="usuarioActivo">
          <visualizador-documentos :id-documento="this.idDocumento" tipo-documento="p"/>
      </div>

      <v-row v-else>
        <v-col>
          <v-alert
              text
              class="mt-6"
              border="left"
              type="info"
          >
            Para visualizar este documento debe <a href="#" @click="modalIniciarSesion()">iniciar sesión</a>. En caso
            que no posea una cuenta registrada en nuestro portal
            puede crear una
            <router-link to="/registro">aquí</router-link>
            .
          </v-alert>
        </v-col>
      </v-row>
    </v-container>

    <!-- visor documentos -->
    <v-container v-else class="custom-container">

      <v-row v-if="usuarioActivo">
        <v-col cols="12" md="3" >
          <v-card>
            <v-card-text>
              <div>
                <p class="subtitle-1 mb-0">Índice</p>
                <p class="headline mb-3 black--text">{{ this.serie }}</p>
              </div>
              <div class="d-flex justify">
                <div>
                  <p class="text-justify">Imagen referencial. Esta puede haber tenido cambios desde el momento de su digitalización.</p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="9">
          <visualizador-documentos :id-documento="this.idDocumento" tipo-documento="d"/>
        </v-col>

      </v-row>

      <v-row v-else>
        <v-col>
          <v-alert
              text
              class="mt-6"
              border="left"
              type="info"
          >
            Para visualizar este documento debe <a href="#" @click="modalIniciarSesion()">iniciar sesión</a>. En caso
            que no posea una cuenta registrada en nuestro portal
            puede crear una
            <router-link to="/registro">aquí</router-link>
            .
          </v-alert>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import VisualizadorDocumentos from "@/components/VisualizadorDocumentos/VisualizadorDocumentos";
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  name: "IndicesVisorPDF",
  components: {VisualizadorDocumentos},
  data() {
    return {
      usuarioActivo: false,
      idDocumento: null,
      serie: null,
      tipo: null,
    }
  },
  created() {
    EventBus.$on("login", (login) => {
      this.usuarioActivo = login;
    });
  },
  mounted() {
    usuarioEntity.isSessionActive()
        .then((isActivo) => {
          this.usuarioActivo = isActivo;
        });
    this.idDocumento = this.$route.params.idDocumento;
    this.serie = this.$route.params.serie;
    this.tipo = this.$route.params.tipo;
  },
  methods: {
    modalIniciarSesion() {
      EventBus.$emit("modal-login", true);
    }
  }
}
</script>

<style scoped>

</style>