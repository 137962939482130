const verificarSolicitudesDeCarroPagadas = (carro) =>{
    if(!carro.pagado) return false;

    let ok = true;

    carro.solicitudes_cliente.map(sol=>{
        if(!sol.pago_ok) ok = false
    })

    return ok;
}

export default verificarSolicitudesDeCarroPagadas
