<template>
  <v-row justify="center" align="stretch">
    <v-col cols="12" sm="6" md="6" lg="6">
      <CardOpcionMenu
          min-height="155"
          to="/consultas/caratulas"
          titulo="Estado de carátulas"
          icono="mdi-briefcase"
          :to-left="true"
          max-height
      >Verifique el estado de las carátulas que ha solicitado.
      </CardOpcionMenu>
    </v-col>

    <v-col cols="12" sm="6" md="6" lg="6">
      <CardOpcionMenu
          to="/consultas/entrega-en-linea"
          titulo="Entrega en línea"
          icono="mdi-briefcase-download"
          :to-left="true"
          max-height
      >
        Acceda a la descarga directa de documentos de compras realizadas en este sitio Web.
      </CardOpcionMenu>
    </v-col>

    <v-col cols="12" sm="6" md="6" lg="6">
      <CardOpcionMenu
          to="/consultas/verificar-documento"
          titulo="Verificación de documentos"
          icono="mdi-file-check"
          :to-left="true"
          max-height
      >
        Verifique la autenticidad de documentos firmados electrónicamente por el Conservador de Bienes Raíces de
        Coquimbo.
      </CardOpcionMenu>
    </v-col>

    <v-col cols="12" sm="6" md="6" lg="6">
      <CardOpcionMenu
          to="/indices"
          titulo="Índices"
          icono="mdi-view-list"
          :to-left="true"
          max-height
      >
        Consulte índices de diferentes registros.
      </CardOpcionMenu>
    </v-col>
  </v-row>
</template>

<script>
import CardOpcionMenu from "@/components/CardOpcionMenu/CardOpcionMenu.vue";

export default {
  components: {CardOpcionMenu},
};
</script>
