import {obtenerCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";

const getDatosAdicionalesCertificado = (id_tipo_registro) => {

    let carro = obtenerCarroActual();
    console.log("Carro: ", carro)
    let escritura;
    switch (id_tipo_registro) {
        case ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS:
            escritura = carro.find(f => f.id_tipo_registro_FK === ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS);
            console.log("Escritura: ", escritura);
            if (escritura) {
                return escritura.datos_adicionales;
            } else {
                return null;
            }
        default:
            return null;

    }
}

export default getDatosAdicionalesCertificado;