import { getRegistroFromApi } from "@/model/registros/registros.repository";

const getTiposRegistrosFromCategoria = (id) => {
    return new Promise((resolve, reject) => {
        getRegistroFromApi(id).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            }
            else {
                reject({ response: response, mensaje: `No se pudo cargar los registros` });
            }
        }).catch(function (error) {
            reject(error);
        });
    })
}

export default getTiposRegistrosFromCategoria;