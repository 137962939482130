import {getLinkPagoSaldoFromApi} from "../caratula.repository";

const getLinkPagoSaldo = (numero_caratula, id_tipo_pago) => {

    return new Promise((resolve, reject) => {
        getLinkPagoSaldoFromApi(numero_caratula, id_tipo_pago).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            } else {
                reject(response.data.obj);
            }
        }).catch(function (error) {
            reject(error);
        });
    })

}

export default getLinkPagoSaldo;