import {crearSesionLocal, iniciarSesionFromApi} from "../usuario.repository";


export const iniciarSesionUsuario = (rut, password, token, v2) => {

    return new Promise((resolve, reject) => {
        iniciarSesionFromApi(rut, password, token, v2).then((response) => {
            if (response.data.status) {
                const obj = response.data.obj;
                console.log("datos de login: ", obj)
                crearSesionLocal(obj);
                resolve(obj);
            }
            else
            {
                if(response.data.code == 'ERROR:NOT_HUMAN'){
                    reject({ response: response, mensaje: `Ha ocurrido un error al validar la seguridad de su conexión, favor haga click en el recuadro de validación "No soy un Robot"`,code: 'NH' });
                }else{
                    reject({ response: response, mensaje: `Al parecer los datos ingresados no coinciden con una cuenta válida`, code: 'ERROR' });
                }
            }
        }).catch((error) => {
            reject(error);
        })
    })

}

