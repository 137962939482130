<template>
  <div>
    <v-fade-transition>

      <div v-if="!cargandoRegistros">
        <v-row>

          <v-col
              v-for="registro in categorias_registros"
              :key="registro.id_categoria_registro"
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="3"
              xl="3"
          >
            <router-link :to="{ name: 'CategoriasRegistros', params: {categoriaRegistro: registro.url_categoria}}">
              <v-card class="fill-height">
                <v-card-title>
                  <v-icon left color="info" v-if="registro.id_categoria_registro == ID_CATEGORIA_REGISTRO.PROPIEDAD">mdi-home</v-icon>
                  <v-icon left color="info" v-if="registro.id_categoria_registro == ID_CATEGORIA_REGISTRO.COMERCIO">mdi-office-building-outline</v-icon>
                  <v-icon left color="info" v-if="registro.id_categoria_registro == ID_CATEGORIA_REGISTRO.AGUAS">mdi-water</v-icon>

                  {{ registro.categoria_registro }}</v-card-title>
                <v-card-text>{{ registro.descripcion }}</v-card-text>
              </v-card>
            </router-link>

          </v-col>

          <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="3"
              xl="3"
          >
            <router-link :to="{ name: 'Indices'}">
              <v-card class="fill-height">
                <v-card-title><v-icon left color="info">mdi-file</v-icon> Consulta de índices</v-card-title>
                <v-card-text>Consulte la Foja, Número y Año de Inscripciones del Registro de Propiedad</v-card-text>
              </v-card>
            </router-link>

          </v-col>


        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import registros, {ID_CATEGORIA_REGISTRO, idToImage} from "@/model/registros/registros.entity";

export default {
  name: "RegistrosPrincipales",
  data() {
    return {
      cargandoRegistros: false,
      categorias_registros: [],
      ID_CATEGORIA_REGISTRO: ID_CATEGORIA_REGISTRO
    };
  },
  created() {
    this.cargandoRegistros = true;
    registros.getCategoriasRegistros().then((response) => {
      this.categorias_registros = response.filter((reg) => {
        if (reg.id_categoria_registro <= 4) {
          return reg;
        }
      })
      this.categorias_registros.splice(-1,1)
      console.log("tarjetas que se mostrarán", this.categorias_registros);
      //this.categorias_registros = response;
    }).finally(() => {
      this.cargandoRegistros = false;
    });
  },
  methods: {
    idToImage: idToImage
  }
};
</script>

<style scoped>
.descripcion-registros {
  color: #088397 !important;
}

a {
  text-decoration-line: none !important;
}

.oscurecer:hover {
  transition-duration: 500ms;
  transition-property: filter;
  filter: brightness(0.8)
}
</style>
