import usuarioEntity from "@/model/usuario/usuario.entity";
import {reemplazarCarroRemoto, updateCarroActual} from "@/model/carro/carro.repository";

const updateCarro = (carro) => {
    return new Promise((resolve, reject) => {

        usuarioEntity.isSessionActive().then(resp => {
                if (resp) {
                    try {
                        reemplazarCarroRemoto(carro).then(() => {
                            updateCarroActual(carro);
                            resolve(carro);
                        })
                    } catch (e) {
                        reject({response: e, mensaje: "Error al actualizar"})
                    }
                } else {
                    updateCarroActual(carro);
                    resolve(carro);
                }
            }
        )

    })
}


export default updateCarro;

