<template>
  <v-dialog max-width="700" v-model="ver">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0 pt-5" v-if="item != null">

            <h3 class="text-center ">Solicite documentos para el título
              {{ item.foja }}-{{ item.numero }}-{{ item.anho }}</h3>

          </v-col>
          <v-col cols="12">
            <documentos-tentativos v-if="lista_certificados.length > 0" :lista-certificados="lista_certificados"
                                   :numero-carillas="carillas_encontradas"
                                   :precio-carillas="precio_carillas"
                                   @aumentar="aumentarCantidadDocumentos($event)"
                                   @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>

          </v-col>
          <v-col cols="12" class="text-right"><span class="font-weight-medium">Subtotal:</span> {{ getSubtotal | clp }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn color="primary" small class="px-6" @click="agregarAlCarro" :disabled="validateItemsCarro">
              <v-icon left small>mdi-cart</v-icon>
              <span>Agregar al carro</span>
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <modal-agregar-vigencia :certificado="certificadoModalAgregarVigencia" :verDialog="verModalAgregarVigencia"
                            @ocultarDialog="aumentarCantidadDocumentosVigencia(certificadoModalAgregarVigencia, $event)"></modal-agregar-vigencia>
  </v-dialog>
</template>

<script>
import DocumentosTentativos from "@/components/SolicitudDocumentos/DocumentosTentativos";
import {
  ID_AREA_AGRUPACION,
  ID_TIPO_CERTIFICADO,
  ID_TIPO_REGISTRO
} from "@/model/registros/registros.entity";
import ModalAgregarVigencia from "@/components/ModalAgregarVigencia";
import carroEntity from "@/model/carro/carro.entity";
import registros from "@/model/registros/registros.entity";

export default {
  name: "ModalAgregarDocumento",
  components: {ModalAgregarVigencia, DocumentosTentativos},
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },

    lista_registros: {
      type: Array,
      default: () => []
    },
    itemNav: {default: null},
    item: {default: null}
  },
  data() {
    return {
      ver: false,
      lista_certificados: [],
      grupo_seleccionado: 0,
      certificadoModalAgregarVigencia: null,
      verModalAgregarVigencia: false,
      precio_carillas: 0,
      carillas_encontradas: 0
    }
  },

  watch: {
    tipo_registro() {

    },
    item() {

      if (this.item != null) {
        registros.isSerie(this.item.numero, this.item.anho, this.itemNav.id_tipo_registro, -1).then(res => {
          console.log(res)
          this.grupo_seleccionado = this.itemNav.default_agrupacion;
          console.log("precio de las carillas: " , res.precio_carillas)
          this.precio_carillas = parseInt(res.precio_carillas == null ? 0 : res.precio_carillas);
          this.carillas_encontradas = parseInt(res.numero_carillas);
          this.lista_certificados = [];
          setTimeout(() => {
            this.lista_registros.map(tr => {
              tr.tipos_certificados.map(tc => {
                console.log("id grupo sugerencias del certificado: ", tc.id_grupo_sugerencias)
                console.log("id grupo seleccionado: " , this.grupo_seleccionado)
                if (tc.id_grupo_sugerencias === this.grupo_seleccionado) {
                  console.log("agrega")
                  this.lista_certificados.push(tc);
                }
              })
            })

            this.lista_certificados.map(cer => {
              this.$set(cer, "numero_copias", 0)
              this.$set(cer, "vigencia", -1)
              this.$set(cer, "has_litigio", false)
            })
          }, 10)
        })

      }


    },
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    aumentarCantidadDocumentos(certificado) {
      if (certificado.id_tipo_registro_FK == ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION && certificado.id_tipo_certificado == ID_TIPO_CERTIFICADO.ALZAMIENTO_GP_UNA_PRP) {
        this.verModalGP = true;
      }

      if (!certificado.has_vigencia) {
        if (certificado.solo_valija_electronica) {
          if (certificado.numero_copias < 1) {
            this.lista_certificados[this.lista_certificados.indexOf(certificado)].numero_copias++;

          }
        } else {
          this.lista_certificados[this.lista_certificados.indexOf(certificado)].numero_copias++;
        }
      } else {
        this.certificadoModalAgregarVigencia = certificado;
        this.verModalAgregarVigencia = true;
      }


    },
    reducirCantidadDocumentos(certificado) {

      if (certificado == this.certificado) {
        if (certificado.numero_copias > 1) {
          certificado.numero_copias--;
        }
      } else {
        if (certificado.numero_copias > 0) {
          certificado.numero_copias--;

          if (certificado.numero_copias == 0) {
            certificado.vigencia = -1;
          }

        }
      }
    },
    aumentarCantidadDocumentosVigencia(certificado, vigencia) {

      certificado.vigencia = vigencia.anhos;
      if (certificado.solo_valija_electronica) {
        if (certificado.numero_copias < 1) {

          this.lista_certificados[this.lista_certificados.indexOf(certificado)].numero_copias++;

        }
      } else {
        certificado.numero_copias++;
      }
      this.verModalAgregarVigencia = false;
    },
    agregarAlCarro() {

      let elementos = [];
      this.lista_certificados.map(e => {
        if (e.numero_copias > 0) {
          elementos.push(e)
        }
      })

      let dataFormulario = {
        id_tipo_formulario: this.itemNav.id_tipo_formulario,
        foja: this.item.foja,
        numero: this.item.numero,
        anho: this.item.anho,
      }


      carroEntity.agregarAlCarro(elementos, dataFormulario, this.precio_carillas).then(response => {
        let modalAgregar = {};
        modalAgregar.documentos_repetidos = response.documentos_repetidos;
        modalAgregar.limite_carro = response.limite_carro
        modalAgregar.lista_agregados = response.lista_agregados;
        this.hideDialog();
        this.$emit("verModalDocumentosAgregados", modalAgregar)
      })


    },

  },
  computed: {
    validateItemsCarro() {

      let lock = true;

      this.lista_certificados.map(m => {
        if (m.numero_copias > 0) {
          lock = false;
        }
      })

      return lock;
    },
    getSubtotal() {
      let monto = 0;

      this.lista_certificados.map(m => {
        if (m.numero_copias > 0) {
          monto += m.precio * m.numero_copias + (m.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precio_carillas) : 0);
        }

      })

      return monto;
    }
  }
}
</script>

<style scoped>

</style>
