<template>
  <div>
    <v-app-bar app dense color="primary" dark>
      <v-app-bar-nav-icon
          v-show="$vuetify.breakpoint.smAndDown"
          @click.stop="showDrawer()"
      >
      </v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <router-link replace to="/">
          <v-img
              alt="CBRLS Logo"
              class="mr-2"
              contain
              :src="require('@/assets/img/cbrls-logo-white.svg')"
              width="130"
              eager
          />
        </router-link>
      </div>

      <!-- app bar pantallas grandes-->
      <v-tabs
          v-show="!$vuetify.breakpoint.smAndDown"
          centered
          optional
          hide-slider
          color="white"
      >

        <v-menu
            :offset-y="true"
            left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tab
                v-bind="attrs"
                v-on="on"
            >
              Trámites en línea
              <v-icon right>mdi-menu-down</v-icon>
            </v-tab>
          </template>

          <v-list dense flat class="pb-0 pt-0">
            <div
                v-for="registro in menuTramitesEnLinea"
                :key="registro.nombre"
            >
              <!-- título -->
              <v-list-item
                  class="grey lighten-3"
                  :to="registro.link ? registro.link : null"
              >
                <v-list-item-title>
                  <v-icon
                      left
                      size="20"
                  >
                    {{ registro.icono }}
                  </v-icon>
                  {{ registro.nombre }}
                </v-list-item-title>
              </v-list-item>

              <!-- ítems -->
              <v-list-item
                  v-for="certificado in registro.hijos"
                  exact
                  :to="certificado.link"
                  :key="certificado.link"
                  class="py-0"
              >
                <v-list-item-subtitle>{{ certificado.nombre }}</v-list-item-subtitle>
              </v-list-item>

            </div>
          </v-list>
        </v-menu>

        <v-tab replace exact to="/consultas">Consultas en línea</v-tab>
        <v-tab replace to="/consultas/entrega-en-linea">Entrega en línea</v-tab>
        <v-tab replace to="/informacion">Información</v-tab>

      </v-tabs>

      <v-spacer></v-spacer>

      <v-badge
          :content="getCantidadDocumentosCarro"
          color="red"
          overlap

      >
        <v-btn class="black--text" small color="white" outlined @click="irAlCarro()">
          <v-icon class="mr-1" small>mdi-cart</v-icon>
          {{ getPrecioTotalCarro | clp }}
        </v-btn>
      </v-badge>

      <v-btn
          v-if="nombreUsuario == null"
          @click="handleIniciarSesion()"
          v-show="$vuetify.breakpoint.smAndUp"
          class="ml-3 black--text"
          small
          color="white"
          outlined
      >
        <v-icon class="mr-1" small>mdi-login</v-icon>
        Ingresar
      </v-btn>

      <v-menu
          v-else
          offset-y
          open-on-hover
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-show="$vuetify.breakpoint.smAndUp"
              class="ml-3 black--text"
              small
              color="white"
              outlined
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="mr-1" small>mdi-account</v-icon>
            {{ nombreUsuario }}
          </v-btn>
        </template>

        <v-list dense flat>
          <v-list-item to="/cliente">
            <v-list-item-title>Mi cuenta</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleCerrarSesion()">
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import usuario from "@/model/usuario/usuario.entity";
import carro from "@/model/carro/carro.entity"
import {EventBus} from "@/main";

export default {
  name: "WebPageCompoundHeader",
  data() {
    return {
      menuTramitesEnLinea: [
        {
          nombre: "Registro de Propiedad",
          icono: "mdi-home",
          link: "/tramites/propiedad"
        },
        {
          nombre: "Registro de Aguas",
          icono: "mdi-water",
          link: "/tramites/aguas"
        }, {
          nombre: "Registro de Comercio",
          icono: "mdi-store",
          link: "/tramites/comercio"
        },
        {
          nombre: "Escrituras públicas",
          icono: "mdi-file",
          link: "/tramites/escrituras-publicas"
        }
      ],
      menuConsultaEnLinea: [
        {
          nombre: "Estado de carátula",
          icono: "mdi-briefcase",
          link: "/consultas/caratulas"
        },
        // {
        //   nombre: "Entrega en línea",
        //   icono: "mdi-briefcase-download",
        //   link: "/consultas/entrega-en-linea"
        // },
        {
          nombre: "Verificación de documentos",
          icono: "mdi-file-check",
          link: "/consultas/verificar-documento"
        },
        {
          nombre: "Búsqueda de Índices y Planos",
          icono: "mdi-view-list",
          link: "/indices"
          //  hijos:[
          //    {
          //      nombre: "Índices Propiedad",
          //      link: "/tramites/comercio/copias-y-certificados-comercio/copia-con-vigencia"
          //    }
          //  ]
        },
      ],
      menuInformacion: [
        {
          nombre: "Acerca del conservador",
          icono: "mdi-information",
          link: "/informacion/acerca-de"
        },
        {
          nombre: "Términos y condiciones",
          icono: "mdi-file-document-edit",
          link: "/informacion/tyc"
        },
        {
          nombre: "Preguntas frecuentes",
          icono: "mdi-help-circle",
          link: "/informacion/faq"
        },
        {
          nombre: "Trabaja con nosotros",
          icono: "mdi-account-hard-hat",
          link: "/informacion/trabaja-con-nosotros"
        },
        {
          nombre: "Contacto",
          icono: "mdi-card-account-mail",
          link: "/informacion/contacto"
        },
      ]
    }
  },
  computed: {
    nombreUsuario: function () {
          return usuario.getNombreUsuario();
    },
    getPrecioTotalCarro() {
      return carro.obtenerResumen().precio_total
    },
    getCantidadDocumentosCarro() {
      return carro.obtenerResumen().cantidad_documentos
    }
  },

  created() {
    carro.obtenerCarro();
  },
  methods: {
    irAlCarro() {
      this.$router.push("/carro");
    },
    obtenerCarro() {
      carro.obtenerCarro();
    },
    showDrawer() {
      this.$emit("show-drawer");
    },
    handleIniciarSesion() {
      EventBus.$emit("modal-login", true);
    },
    handleCerrarSesion() {
      usuario.cerrarSesionUsuario().then(() => {
        EventBus.$emit("dialog-sesion-finalizada", true);
        EventBus.$emit("login", false);
      }).catch((error) => {
        console.error(error);
      })
    }
  },
};
</script>

<style scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 31px;
}

.v-tab {
  font-size: 0.8rem;
  padding: 0 10px;
}

.v-tab .v-icon {
  margin-left: 0;
}
</style>
