import axios from "axios";
import qs, {} from "qs";

export const getIndicesNombreApi = (id_tipo_registro, titular, tabla_ajax, paginacion, anho) => {
    return new Promise((resolve, reject) => {

        if(isNaN(anho))
        {
            anho = -1;
        }

        axios.post(
            "/indices?action=r-all-like",
            qs.stringify({
                id_tipo_registro_FK: id_tipo_registro,
                titular,
                tabla_ajax: JSON.stringify(tabla_ajax),
                desde_paginacion: paginacion,
                anho: anho
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getDocFNAApi = (serie) =>{
    return new Promise((resolve, reject) => {

        axios.post(
            "/indices?action=r-docbyfna",
            qs.stringify({
                serie: JSON.stringify(serie)
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}


export const getPDFApi = (serie,tipoDocumento,token) =>{
    return new Promise((resolve, reject) => {

        axios.get(
            "/documento?t="+serie+"&t1="+token+"&td="+tipoDocumento,{
            responseType: 'blob', // had to add this one here
        }
            
        ).then(function (response) {
            if(typeof response.data.status !== 'undefined'){
                reject({error: response.data.obj})
            }else{
               resolve(response);
            }
            
        }).catch(function (error) {
            reject(error);
        });
    })
}