<template>
  <div>
    <v-row>
      <v-col cols="12">

        <v-data-table
            :headers="headers"
            :items="caratulasPorCorregir"
            :loading="load.caratulasConDocsCorreccion"
            loading-text="Cargando sus carátulas... espere un momento"
            :item-class="itemRowBack"
            :footer-props="{
              'items-per-page-text': 'Ítems por página',
              'items-per-page-all-text': 'Todo',
            }"
        >

          <!-- PARTE SUPERIOR DE LA TABLA -->

          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>
                <p class="d-flex align-center mb-0 upp">
                  <v-icon color="info" class="pr-1">mdi-file-document-outline</v-icon>
                  Mis carátulas por corregir
                </p>
              </v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>


          <template v-slot:item.ult_estado="{item}">

            <span v-if="item.estados[item.estados.length -1].id_estado_caratula_FK !== 1002">{{
                item.estados[item.estados.length - 1].estado_caratula
              }}</span>
            <span v-if="item.estados[item.estados.length -1].id_estado_caratula_FK === 1002">Carátula firmada electrónicamente</span>
          </template>
          <template v-slot:item.docs_por_corregir="{item}">
            {{ getDocumentosPorCorregir(item.solicitud_cliente) }}
          </template>


          <!-- BOTON PARA VER LOS DOCUMENTOS -->

          <template v-slot:item.ver="{item}">

            <a v-if="getDocumentosPorCorregir(item.solicitud_cliente) > 0" @click="corregir(item)">
              <v-icon v-if="item === caratula_seleccionada" color="info">mdi-text-box-search-outline</v-icon>
              <v-icon v-if="item !== caratula_seleccionada" color="success">mdi-text-box-search-outline</v-icon>
            </a>

            <v-icon v-if="getDocumentosPorCorregir(item.solicitud_cliente) == 0" color="success">mdi-check</v-icon>

          </template>

          <!-- TABLA SIN DATOS -->

          <template v-slot:no-data>
            No hay carátulas por corregir
          </template>

          <template #footer.page-text="props">
            {{ props.pageStart }} al {{ props.pageStop }} de {{ props.itemsLength }}
          </template>

        </v-data-table>

      </v-col>

    </v-row>

    <modal-corregir-documentos :ver-dialog="modal.corregirDocumento" @ocultarDialog="modal.corregirDocumento = false"
                               :caratula="caratula_seleccionada"></modal-corregir-documentos>
  </div>
</template>

<script>
import caratulaEntity, {ID_ESTADO_CORRECCION} from "@/model/caratula/caratula.entity";
import ModalCorregirDocumentos from "@/views/index/cliente/caratulas-por-corregir/ModalCorregirDocumentos";

export default {
  name: "CaratulasPorCorregirView",
  components: {ModalCorregirDocumentos},
  data() {
    return {
      load: {
        caratulasConDocsCorreccion: false,
      },
      caratulasPorCorregir: [],
      headers: [
        {text: 'Carátula', align: 'center', value: 'numero_caratula', sortable: false, width: "15%"},
        {text: 'Documentos por corregir', value: 'docs_por_corregir', align: 'center', sortable: false},
        {text: 'Último Estado', value: 'ult_estado', align: 'center', sortable: false},
        {text: 'Ver', value: 'ver', align: 'center', sortable: false},
      ],
      caratula_seleccionada: {},
      modal: {
        corregirDocumento: false
      },

    }
  },
  created() {
    this.load.caratulasConDocsCorreccion = true;
    caratulaEntity.getCaratulasConDocumentosPorCorregir(false).then(res => {
      this.load.caratulasConDocsCorreccion = false;
      this.caratulasPorCorregir = res;
      console.log("caratulas encontradas: ", res)
    });

  },


  methods: {
    getDocumentosPorCorregir(solicitud) {
      console.log(solicitud)
      return solicitud.documentosSolicitud.filter(d => {
        return d.por_corregir == ID_ESTADO_CORRECCION.POR_CORREGIR
      }).length
    },

    itemRowBack(item) {
      if (item.numero_caratula === this.caratula_seleccionada.numero_caratula) {
        return "blue lighten-5"
      }
    },
    corregir(item) {
      console.log(item)
      this.caratula_seleccionada = item;
      this.modal.corregirDocumento = true;
    },

  }
}
</script>

<style scoped>

</style>
