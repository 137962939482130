import {obtenerDatosDeUsuarioLogeadoAPI} from "@/model/usuario/usuario.repository";

const readUsuario = () => {
    return new Promise((resolve, reject) => {
        obtenerDatosDeUsuarioLogeadoAPI()
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data.obj);
                } else {
                    reject({response: response, mensaje: "No se pudo obtener los datos del usuario."})
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export default readUsuario;