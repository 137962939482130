<template>
  <div style="width: 100%">
    <v-btn type="button" block color="info" :disabled="!puedeValidarIndice || !habilitarBoton || validandoIndice" :loading="validandoIndice" @click="validarIndice">
      Buscar
    </v-btn>

    <modal-serie :ver-dialog-serie="dialogSerie" :serie="serie" @cerrarModalSerie="dialogSerie = false"
                 @recargarBusqueda="recargarModalSerie()"
                 @agregarDocumentos="corregirFoja($event)"></modal-serie>
  </div>

</template>

<script>
import registros, {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import ModalSerie from "@/components/SolicitudDocumentos/modals/ModalSerie";

export default {
  name: "ValidadorIndices",
  components: {ModalSerie},
  props: {
    puedeValidarIndice: {
      default: true
    },
    dataFormulario: {
      type: Object,
      default: () => {
      }
    },
    certificado: {
      type: Object,
      default: () => {
      }
    },
    habilitarBoton: {default: true}
  },
  data() {
    return {
      dialogSerie: false,
      fojaCorrecta: null,
      serie: null,
      validandoIndice: false
    }
  },
  methods: {
    validarIndice() {
      console.log("id_tipo_registro:  " , this.certificado.id_tipo_registro_FK)
      console.log("ID_TIPO_REGISTRO.COMERCIO: ", ID_TIPO_REGISTRO.COMERCIO)
      if(this.certificado.id_tipo_registro_FK != ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD &&  this.certificado.id_tipo_registro_FK != ID_TIPO_REGISTRO.COMERCIO){
        this.corregirFoja();
        return;
      }

      console.log("se ejecuto una busqueda")
      this.validandoIndice = true;
      registros.isSerie(this.dataFormulario.numero, this.dataFormulario.anho, this.certificado.id_tipo_registro_FK, this.certificado.id_tipo_certificado).then(res => {
        console.log("respuesta: ", res)
        this.validandoIndice = false;
        if (res) {

          this.serie = res;
          this.serie.fojaBuscada = this.dataFormulario.foja;
          this.fojaCorrecta = res.foja

          if (this.fojaCorrecta != this.serie.fojaBuscada) {
            this.dialogSerie = true;
          } else {
            this.corregirFoja();
          }
        } else {
          this.serie = null;
          this.dialogSerie = true;
          // this.corregirFoja();
          console.log("no se ha encontrado el índice")
        }
      })
    },
    recargarModalSerie() {
      if (this.dialogSerie) {
        this.validarIndice();
      }
    },
    corregirFoja() {
      this.dialogSerie = false;

      if(this.serie == null){
        this.serie = {
          foja: this.dataFormulario.foja,
          fojaBuscada: this.dataFormulario.foja,
          numero_carillas: 0,
          precio_carillas: 0
        }
      }

      console.log("se enviara la siguiente serie: " , this.serie)
      this.$emit("corregirFoja", this.serie)
    }
  }
}
</script>

<style scoped>

</style>