const getImagenCertificado = (idRegistro, idCertificado, thumbnail) => {
    let img;
    const _thumbnail = thumbnail ? "thumbnail" : "full";
    try {
        img = require(`@/assets/img/certificados/${idRegistro}_${idCertificado}-${_thumbnail}.png`);
    } catch (error) {
        console.error("Imagen no disponible para certificado con id: ", idCertificado)
        img = require('@/assets/img/certificados/cert-sample_thumbnail.jpg');
    }
    return img;
}

export default getImagenCertificado;