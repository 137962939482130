<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <div :class="{'fill-height' : maxHeight}">
        <v-card
            :min-height="minHeight ? minHeight : '100%'"
            v-if="!disabled"
            :to="to"
            :height="maxHeight ? '100%' : ''"
            :elevation="hover ? 4 : 2"
        >
          <div class="d-flex flex-column pa-4">
            <div
                class="d-flex align-center"
                :class="toLeft ? 'justify-start' : 'justify-center'"
            >
              <v-icon color="primary" size="50">{{ icono }}</v-icon>
              <p class="text-h5 text--primary ma-0 pl-3">{{ titulo }}</p>
            </div>
            <div v-if="hasBody">
              <v-card-text class="px-0">
                <div class="text--primary">
                  <p class="mb-0" style="text-align: justify;">
                    <slot/>
                  </p>
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card>
        <v-card
            style="cursor: default"
            v-if="disabled"
            :height="maxHeight ? '100%' : ''"
            :elevation="hover ? 4 : 2"
        >
          <div class="d-flex flex-column pa-4">
            <div
                class="d-flex align-center"
                :class="toLeft ? 'justify-start' : 'justify-center'"
            >
              <v-icon color="grey" size="50">{{ icono }}</v-icon>
              <p class="text-h5 text--primary ma-0 pl-3"><span style="color: grey">{{ titulo }}</span></p>
            </div>
            <div v-if="hasBody">
              <v-card-text class="px-0">
                <div class="text--primary">
                  <p class="mb-0 grey--text" style="text-align: justify;">
                    <slot/>
                  </p>
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </div>

    </template>
  </v-hover>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: "/"
    },
    icono: {
      type: String,
      default: "mdi-home"
    },
    titulo: {
      type: String,
      default: "Home"
    },
    toLeft: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Boolean,
      default: true
    },
    hasBody: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String,
      default: "100%"
    }
  }
};
</script>
