<template>
  <div>
    <BuscadorCopiasCertificados/>
    <RegistrosPrincipales/>
  </div>
</template>

<script>
import BuscadorCopiasCertificados from "@/components/BuscadorCopiasCertificados/BuscadorCopiasCertificados";
import RegistrosPrincipales from "@/components/Tramites/RegistrosPrincipales";

export default {
  name: "TramitesEnLineaViewMenu",
  components: {RegistrosPrincipales, BuscadorCopiasCertificados}
};
</script>