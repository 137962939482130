import {createSolicitudDevolucionApi} from "../caratula.repository";

const createSolicitudDevolucion = (dataSolicitud ) =>{

    return new Promise((resolve, reject)=>{
        createSolicitudDevolucionApi(dataSolicitud).then(response=>{
            if(response.data.status){
                resolve(response.data.obj)
            }else{
                reject(response.data)
            }
        }).catch(error=>{
            reject(error)
        })
    })


}

export default createSolicitudDevolucion;
