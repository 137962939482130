<template>
  <v-row>
    <v-col cols="12" class="pb-0"><h3 class="text-center font-weight-medium">Forma de envío de los documentos electrónicos</h3>
    </v-col>

    <v-col cols="12" v-if="!modificarCorreo">
      <p class="text-center mb-0">
        La documentación será enviada a <span class="font-weight-bold">{{ correo }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{
            on, attrs
          }">
            <a class="pl-1" v-bind="attrs"
               v-on="on" @click="modificarCorreo = true">
              <v-icon small color="info">mdi-square-edit-outline</v-icon>
            </a>
          </template>
          <span>Modificar Correo</span>
        </v-tooltip>

      </p>
    </v-col>

    <v-fade-transition>
      <v-col cols="12" class="pt-5" v-if="modificarCorreo">
        <v-text-field
            label="Correo electrónico para el envío"
            placeholder=" "
            outlined
            dense
            hide-details="auto"
            v-model="nuevoCorreo"
            :autofocus="!$vuetify.breakpoint.mobile"
        ></v-text-field>
      </v-col>
    </v-fade-transition>

  </v-row>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import {ID_TIPO_RETIRO} from "@/model/carro/carro.entity";

export default {
  name: "ModalPagoFormaRetiro",
  data() {
    return {
      modificarCorreo: false,
      correo: null,
      nuevoCorreo: ""
    }
  },
  watch: {
    correo() {
      this.emitCorreoValija();
    },
    nuevoCorreo() {
      this.emitCorreoValija();
    },
    modificarCorreo() {
      this.emitCorreoValija();
    }
  },
  created() {
    this.correo = usuarioEntity.getCorreoUsuario();
  },
  methods: {
    emitCorreoValija() {
      let obj = {}
      obj.id_tipo_retiro = ID_TIPO_RETIRO.VALIJA_ELECTRONICA;
      if (this.modificarCorreo) {
        if (this.nuevoCorreo.trim() == "") {
          obj = null
        } else {
          obj.correo = this.correo
        }
      } else {
        obj.correo = usuarioEntity.getCorreoUsuario();
      }
      this.$emit("updateCorreoValija", obj)
    }
  }
}
</script>

<style scoped>

</style>
