import {getCaratulaByRutAPI, getDocumentosByCaratulaAPI} from "@/model/caratula/caratula.repository";

const getInformacionByRut = (numeroCaratula, rutCliente) => {

    const p1 = new Promise((resolve, reject) => {
        getCaratulaByRutAPI(numeroCaratula, rutCliente).then((response) => {
            if (response.data.status) {
                if(response.data.obj.includes("NOT_CLIENTE"))
                {
                    reject({
                        response: response,
                        mensaje: `El cliente indicado no corresponde a la carátula consultada.`
                    });
                }
                else if(response.data.obj.includes("NOT_VALIJA"))
                {
                    reject({
                        response: response,
                        mensaje: `No se pueden observar los documentos de la carátula seleccionada ya que ésta no fue enviada por valija electrónica.`
                    });
                }
                else if(response.data.obj.includes("SIN_FINALIZAR"))
                {
                    reject({
                        response: response,
                        mensaje: `La carátula seleccionada aún no termina su proceso. Si desea saber el estado de ésta, <a href="/consultas/caratulas/${numeroCaratula}">haga clic aquí</a>`,
                    });
                }
                else if(response.data.obj.includes("NOT_CARATULA"))
                {
                    reject({
                        response: response,
                        mensaje: `La carátula seleccionada aún no es ingresada a nuestro sistema.`
                    });
                }
                else
                {
                    resolve(response.data.obj);
                }
            } else {
                reject({
                    response: response,
                    mensaje: `No se pudo obtener la información de la carátula, favor volver a intentar.`
                });
            }
        }).catch(function (error) {
            reject(error);
        });
    });

    const p2 = new Promise((resolve, reject) => {
        getDocumentosByCaratulaAPI(numeroCaratula).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            } else {
                reject({
                    response: response,
                    mensaje: `No se pudieron obtener los documentos de la carátula, favor volver a intentar.`
                });
            }
        }).catch(function (error) {
            reject(error);
        });
    });

    return new Promise((resolve, reject) => {
        Promise.all([p1, p2])
            .then((resolves) => {
                resolve(resolves);
            })
            .catch((error) => {
                reject(error);
            });
    })


}

export default getInformacionByRut;