<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-card-text>

<p class=" pt-4 text-center font-weight-medium info--text">
  Importante
</p>
        <p class="text-justify">
          Estimado cliente, le solicitamos verificar su documento en la sección <a href="/consultas">Entrega en línea</a> con el número de su documento
        </p>

        <div class="text-center">
          <v-btn @click="hideDialog"  color="info" small outlined><v-icon left small>mdi-check</v-icon>Entendido</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalAvisoCaratulaAntigua",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>