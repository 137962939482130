// eslint-disable-next-line no-unused-vars
import {agregarAlCarroApi, obtenerCarroActual, updateCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import getCarro from "@/model/carro/usecases/get-carro";
import usuarioEntity from "@/model/usuario/usuario.entity";
import isInCarro from "@/model/carro/usecases/verificar-restricciones";
import isLimiteExedido from "@/model/carro/usecases/vertificar-cantidad-elementos-grupo-carro";
import {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";

const agregarAlCarro = (items, dataFormulario, precio_carillas) => {

    let listaCertificados = JSON.parse(JSON.stringify(items));
    //precio_carillas se usa para el localstorage
    console.log("lista de certificados para agregar: ", listaCertificados)
    console.log("precio_carillas: " , precio_carillas);
    listaCertificados.map(cer => {
        cer.precio_carillas = precio_carillas;
        cer.precio = parseInt(cer.precio) + (cer.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(precio_carillas) : 0);
    })
    let listaCarro = [];
    let listaRepetidos = [];
    let listaCarroLimite = [];

    console.log("Certificados que se están agregando: " , listaCertificados)

    return new Promise((resolve, reject) => {

        listaCertificados.map(item => {
            let carroActual = obtenerCarroActual();
            if (item.numero_copias > 0) {

                item.id_tipo_certificado_FK = item.id_tipo_certificado;
                if (item.vigencia === 0 || item.vigencia == null) {
                    item.vigencia = -1;
                }
                let alzamiento = null;
                switch (dataFormulario.id_tipo_formulario) {
                    case ID_TIPO_FORMULARIO.FNA:
                        item.indice = `${dataFormulario.foja}-${dataFormulario.numero}-${dataFormulario.anho}`;
                        break;
                    case ID_TIPO_FORMULARIO.PLANOS:
                        item.indice = `${dataFormulario.foja}-${dataFormulario.numero}-${dataFormulario.anho}`;
                        break;
                    case ID_TIPO_FORMULARIO.ALZAMIENTO_Y_CANCELACION:
                        alzamiento = {
                            id_documento_electronico: dataFormulario.idDocumento,
                            repertorio: dataFormulario.repertorio,
                            partes: dataFormulario.partes,
                            notaria: dataFormulario.notario.nombre
                        }

                        item.indice = "No aplica";
                        item.alzamiento = alzamiento;
                        break;
                    case ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS:
                        item.indice = "No aplica";
                        console.log("ITEM: ", item);
                        console.log("dataFormulario: ", dataFormulario)
                        item.datos_adicionales = dataFormulario.datos_adicionales;
                        break;
                    case ID_TIPO_FORMULARIO.DOCUMENTO_AGREGADO:
                        item.indice = `${dataFormulario.foja}-${dataFormulario.numero}-${dataFormulario.anho}`;
                        break;
                    default:
                        reject({response: null, mensaje: "Formulario no soportado"})
                        return;
                }

                if (!isLimiteExedido(item)) {
                    console.log("puede agregar el item: ", item)
                    if (isInCarro(item)) {
                        listaRepetidos.push(item)
                    } else {
                        let busqueda = carroActual.filter(f => {
                            return f.indice === item.indice && f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK
                        })

                        if (busqueda.length > 0) {
                            console.log("encuentra el elemento en el carro")
                            carroActual.filter(f => {
                                return f.indice === item.indice && f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK
                            })[0].numero_copias += item.numero_copias;
                        } else {
                            carroActual.push(item)
                        }
                        listaCarro.push(item);
                        updateCarroActual(carroActual);
                        console.log("actualiza el carro actual con ", carroActual)
                    }
                } else {
                    listaCarroLimite.push(item);
                }
            }
        })


        usuarioEntity.isSessionActive().then(resp => {
            if (resp) {
                agregarAlCarroApi(listaCarro).then(response => {
                    if (response.data.status) {
                        getCarro().then();
                        resolve({
                            documentos_repetidos: listaRepetidos,
                            limite_carro: listaCarroLimite,
                            lista_agregados: listaCarro
                        });
                    } else {
                        reject({response: response, mensaje: "No se pudo agregar al carro"})
                    }
                })
            } else {
                resolve({
                    documentos_repetidos: listaRepetidos,
                    limite_carro: listaCarroLimite,
                    lista_agregados: listaCarro
                });
            }
        })


    })

}

export default agregarAlCarro;
