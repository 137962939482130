import {obtenerCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";


const isInCarro = (item) => {

    let carroActual = obtenerCarroActual();

    if (item.id_tipo_registro_FK === ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION) {
        let busqueda = carroActual.filter(f => {
            return f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK;
        })

        if(busqueda.length > 0){
            if( item.solo_valija_electronica){
                return true;
            }else{
                if(item.solo_anexo){
                    return true
                }
            }
        }

        return false;
    } else {
        let busqueda = carroActual.filter(f => {
            return f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK && f.indice === item.indice;
        })

        if (busqueda.length > 0) {
            return item.solo_valija_electronica;
        }
    }


    return false;
}

export default isInCarro;