import {getVideotutorialesFromApi} from "../videos.repository";

const getVideoTutoriales = (start) => {
    return new Promise((resolve, reject) => {
        getVideotutorialesFromApi(start)
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data.obj);
                } else {
                    reject({response: response, mensaje: "No se pudo obtener los videos."})
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export default getVideoTutoriales