const kebab = (text) => {

    let kebab = text.toLowerCase();

    kebab = kebab.replace(" ", '-');

    return kebab;

}

const kebabURL = (text) => {

    let kebab = text.toLowerCase();

    kebab = kebab.replaceAll(" ", "-");


    var from = "ãàáäâèéëêìíïîòóöôùúüûñÇç",
        to   = "aaaaaeeeeiiiioooouuuuncc";

    for(let i = 0; i < from.length; i++){
        kebab = kebab.replaceAll(from.charAt(i), to.charAt(i))
    }

    return kebab;
}

const snake = (text) => {

    let snake = text.toLowerCase();
    snake = snake.replaceAll(" ", "-");
    return snake;

}

const toCase = {
    snake: snake,
    kebab: kebab,
    kebabURL: kebabURL
}

export default toCase;