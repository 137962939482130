<template>
  <v-form>

    <v-row>
      <v-col cols="12" class="pb-1"><p class="font-weight-regular mb-0">Ingrese los datos de la escritura</p></v-col>
      <v-col cols="12" md="8">

        <v-text-field id="notarioEscritura" placeholder="Ej: Notaría Juan Carlos Maturana"
                      :disabled="!camposHabilitados" dense
                      outlined
                      hide-details="auto"
                      label="Notarías"
                      v-model="notaria_seleccionada"
                      :error-messages="errorNotario"
                      @input="delayTouch($v.notaria_seleccionada)"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field placeholder="Ej: 1234-2021" :disabled="!camposHabilitados" hide-details outlined dense
                      label="Repertorio"
                      v-model="repertorio"></v-text-field>
      </v-col>

      <v-col cols="12" md="8">
        <v-text-field id="contratantes" placeholder="Ej: Contratante 1, Contratante 2..." :disabled="!camposHabilitados"
                      dense outlined
                      hide-details
                      label="Contratantes"
                      v-model="contratantes"></v-text-field>
      </v-col>


      <v-col cols="12" md="4">
        <v-text-field placeholder="Fecha" :min="getEndDate" :max="nowDate" :disabled="!camposHabilitados" hide-details
                      outlined dense label="Fecha" type="date"
                      v-model="fecha"></v-text-field>
      </v-col>

    </v-row>


  </v-form>

</template>

<script>
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";
import {helpers, required} from "vuelidate/lib/validators";

const touchMap = new WeakMap();
const validadorFoja = helpers.regex('validadorFoja', /^[A-Za-z0-9 ]{1,220}$/);
export default {
  name: "FormularioEscrituraPublica",
  props: {
    camposHabilitados: {
      type: Boolean,
      default: false,
    },
  },

  validations: {
    notaria_seleccionada: {
      required,
      validadorFoja
    }
  },
  data() {
    return {
      notaria_seleccionada: "",
      repertorio: "",
      fecha: "",
      contratantes: "",
      nowDate: new Date().toISOString().slice(0, 10),
      fecha_minima: -883598400000
    }
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 800))
    },
  },
  computed: {
    getEndDate() {
      let endDate = new Date(this.fecha_minima);
      return endDate.toISOString().slice(0, 10)
    },

    errorNotario() {
      const errors = [];

      if (!this.$v.notaria_seleccionada.$dirty) return errors;

      !this.$v.notaria_seleccionada.required && errors.push("Debe ingresar la notaría");
      this.notaria_seleccionada.length > 220 && errors.push("El nombre de la notaría no debe exceder los 220 caracteres");
      !this.$v.notaria_seleccionada.validadorFoja && errors.push("Ingrese una notaría válida (Sólo números o letras, sin acentos, puntos, etc)");

      return errors;
    },
    validarFormulario() {
      //  console.log("validando fecha: ",new Date(this.fecha).getTime() + 10800000)

      let fecha_split = this.fecha.split("-");
      let fecha_parsed;

      if (fecha_split.length < 3) {
        fecha_parsed = -1
      } else {
        //   console.log("fecha_split", fecha_split);
        fecha_parsed = new Date(parseInt(fecha_split[0]), parseInt(fecha_split[1]) - 1, parseInt(fecha_split[2])).getTime()
        console.log("validando fecha 2:", fecha_parsed);
      }
      // console.log("fecha parsed: ", fecha_parsed);
      return this.errorNotario.length === 0
          && this.notaria_seleccionada != null
          && this.notaria_seleccionada.trim().length > 0
          && this.repertorio.trim().length > 0
          && this.fecha.trim().length > 0
          && this.contratantes != null
          && this.contratantes.trim().length > 0
          && fecha_parsed >= this.fecha_minima
          && fecha_parsed <= new Date().getTime();
    }
  },
  watch: {
    camposHabilitados(v) {
      if (v) {
        setTimeout(() => {
          document.getElementById("notarioEscritura").focus()
        }, 500)

      }

    },
    validarFormulario(e) {
      if (e) {
        let fecha_split = this.fecha.split("-");
        let formulario = {
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          datos_adicionales: [
            {
              nombre: "notaria",
              valor: this.notaria_seleccionada,
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            },
            {
              nombre: "repertorio",
              valor: this.repertorio,
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            },
            {
              nombre: "fecha",
              valor: new Date(parseInt(fecha_split[0]), parseInt(fecha_split[1]) - 1, parseInt(fecha_split[2])).getTime(),
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            },
            {
              nombre: "contratantes",
              valor: this.contratantes,
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            }
          ]
        }
        this.$emit("setFormulario", formulario)
      } else {
        this.$emit("setFormulario", null)
      }
    }
  }
}
</script>

<style scoped>

</style>